import React from "react";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../data/firebase"; // 👉 corect aici
import articles from "../data/articles.json";

const UploadArticles = () => {
  const handleUpload = async () => {
    try {
      for (const article of articles) {
        const docRef = doc(firestore, "articles", article.slug); // slug ca ID unic
        await setDoc(docRef, article);
        console.log(`✅ Adăugat: ${article.slug}`);
      }
      alert("Toate articolele au fost adăugate cu succes!");
    } catch (error) {
      console.error("Eroare la încărcare:", error);
      alert("Eroare! Vezi consola.");
    }
  };

  return (
    <div style={{ padding: "2rem" }}>
      <h2>📚 Încarcă articolele în Firestore</h2>
      <button
        onClick={handleUpload}
        style={{ padding: "1rem", fontSize: "1rem" }}>
        Încarcă articolele
      </button>
    </div>
  );
};

export default UploadArticles;
