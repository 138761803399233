import React, { useEffect, useState } from "react";
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import { firestore } from "../data/firebase";
import { Pie } from "react-chartjs-2";

const StatsDashboard = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      setLoading(true);
      try {
        const statsRef = collection(firestore, "dataStats");
        const statsQuery = query(
          statsRef,
          orderBy("timestamp", "desc"),
          limit(1)
        );
        const statsSnapshot = await getDocs(statsQuery);
        if (!statsSnapshot.empty) {
          const latestStats = statsSnapshot.docs[0].data();
          setStats(latestStats.stats);
        }
      } catch (error) {
        console.error("Error fetching stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) return <div>Loading stats...</div>;
  if (!stats) return <div>No statistics available.</div>;

  const pieData = {
    labels: Object.keys(stats.averagePricePerCategory),
    datasets: [
      {
        data: Object.values(stats.averagePricePerCategory),
        backgroundColor: [
          "#4ff800",
          "#ffa600",
          "#FFCE56",
          "#3498db",
          "#e74c3c",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="statistics-section stats-dashboard">
      <h2>Statistics Dashboard</h2>

      <section>
        <h3>Average Price per Category</h3>
        <div className="chart-wrapper">
          <Pie data={pieData} />
        </div>
      </section>

      <section>
        <h3>Overall Average Price per Task</h3>
        <p>{stats.overallAveragePrice.toFixed(2)}</p>
      </section>

      <section>
        <h3>Average Price per City</h3>
        <ul>
          {Object.entries(stats.averagePricePerCity).map(([city, price]) => (
            <li key={city}>
              <strong>{city}:</strong> {price.toFixed(2)}
            </li>
          ))}
        </ul>
      </section>

      <section>
        <h3>Best Paid Tasks</h3>
        <ul>
          {stats.bestPaidTasks.map((task, index) => (
            <li key={index}>
              <strong>{task.title}</strong> ({task.category}) - {task.price}
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default StatsDashboard;
