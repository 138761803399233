import React from "react";
import pressArticles from "../data/pressArticles.json";
import "../styling/pressPage.css";

const PressPage = () => {
  return (
    <div className="press-page-container">
      <div className="press-info-left">
        <h2>Despre Aidly</h2>
        <p>
          Aidly este o platformă care conectează persoanele care au nevoie de
          ajutor cu cele care au timp și pot oferi ajutor. Simplificăm procesul
          de găsire a unei persoane de încredere care să te ajute cu diverse
          sarcini de zi cu zi.
        </p>
        <p>
          Dacă dorești să vorbești despre Aidly, să ne inviți la un podcast sau
          să ne iei un interviu, te rugăm să ne scrii la{" "}
          <a href="mailto:contact@aidly.ro?subject=Press%3A">
            contact@aidly.ro
          </a>
          .
        </p>
        <p>
          De asemenea, dacă reprezinți o companie sau un proiect și dorești să
          ne propui un parteneriat, ne poți contacta la{" "}
          <a href="mailto:contact@aidly.ro?subject=Partnership%3A">
            contact@aidly.ro
          </a>
          .
        </p>
      </div>

      <div className="press-articles-right">
        <h2>Aidly în Presă</h2>
        <div className="articles-scrollable">
          {pressArticles.map((article) => (
            <div key={article.id} className="press-article">
              <img
                src={article.imageUrl}
                alt={article.title}
                className="article-image"
              />
              <div className="article-content">
                <h4>{article.title}</h4>
                <p>{article.preview}</p>
              </div>
              <a
                href={article.articleUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="read-more">
                →
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PressPage;
