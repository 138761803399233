import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import "../styling/EditAnnouncementModal.css"; // creează și acest fișier cu stilurile dorite

const EditAnnouncementModal = ({ announcement, db, onClose }) => {
  const [formData, setFormData] = useState({
    title: announcement.title || "",
    description: announcement.description || "",
    price: announcement.price || "",
    budgetType: announcement.budgetType || "daily",
    experienceLevel: announcement.experienceLevel || "",
    taskDuration: announcement.taskDuration || "",
    validity: announcement.validity || "",
    numberOfPeople: announcement.numberOfPeople || "",
    ageRange: announcement.ageRange || "",
    isUrgent: announcement.isUrgent || false,
    cvRequired: announcement.cvRequired || false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleNeededDateChange = (e) => {
    const value = e.target.value;
    handleChange(e);
    if (value) {
      const today = new Date();
      const needed = new Date(value);
      const diffDays = (needed - today) / (1000 * 60 * 60 * 24);
      setFormData((prev) => ({ ...prev, isUrgent: diffDays < 5 }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      const announcementRef = doc(db, "announcements", announcement.id);
      await updateDoc(announcementRef, {
        ...formData,
        updatedAt: new Date(),
      });
      onClose();
    } catch (err) {
      console.error("Error updating announcement:", err);
      setError("A apărut o eroare. Încearcă din nou.");
    }
    setLoading(false);
  };

  return (
    <div className="modal-overlay-edit-announcement">
      <div className="modal-content-edit-announcement">
        <button className="close-button" onClick={onClose}>
          x
        </button>
        <h2>Editează Anunțul</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Titlu</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Descriere</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Buget</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
            />
            <select
              name="budgetType"
              value={formData.budgetType}
              onChange={handleChange}
              required>
              <option value="daily">Plată zilnică</option>
              <option value="hourly">Plată orară</option>
            </select>
          </div>
          <div className="form-group">
            <label>Nivel de experiență</label>
            <select
              name="experienceLevel"
              value={formData.experienceLevel}
              onChange={handleChange}
              required>
              <option value="">Selectează nivelul</option>
              <option value="Entry">Începător</option>
              <option value="Intermediate">Intermediar</option>
              <option value="Expert">Expert</option>
            </select>
          </div>
          <div className="form-group">
            <label>Durata sarcinii</label>
            <select
              name="taskDuration"
              value={formData.taskDuration}
              onChange={handleChange}
              required>
              <option value="">Selectează durata</option>
              <option value="Short">Scurtă</option>
              <option value="Medium">Mediu</option>
              <option value="Long">Lungă</option>
            </select>
          </div>
          <div className="form-group">
            <label>Când este nevoie</label>
            <input
              type="date"
              name="validity"
              value={formData.validity}
              onChange={handleNeededDateChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Număr de persoane</label>
            <input
              type="number"
              name="numberOfPeople"
              value={formData.numberOfPeople}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Interval de vârstă</label>
            <select
              name="ageRange"
              value={formData.ageRange}
              onChange={handleChange}
              required>
              <option value="">Selectează intervalul</option>
              <option value="18-25">18-25</option>
              <option value="26-35">26-35</option>
              <option value="36-45">36-45</option>
              <option value="46-55">46-55</option>
              <option value="56+">56+</option>
            </select>
          </div>
          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="cvRequired"
                checked={formData.cvRequired}
                onChange={handleChange}
              />
              Este necesar un Curriculum Vitae?
            </label>
          </div>
          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="isUrgent"
                checked={formData.isUrgent}
                onChange={handleChange}
                disabled
              />
              Urgent
            </label>
          </div>
          {error && <p className="error">{error}</p>}
          <div className="modal-buttons">
            <button type="button" onClick={onClose} className="cancel-button">
              Anulează
            </button>
            <button type="submit" disabled={loading} className="submit-button">
              {loading ? "Se salvează..." : "Salvează modificările"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAnnouncementModal;
