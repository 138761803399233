import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { firestore } from "../data/firebase";
import "../styling/footer.css";
import Upvm from "../assets/upvm.webp";
import FriendsCarousel from "./FriendsCarousel";
import { useTranslation } from "react-i18next";
import PrivacyPolicyModal from "./PrivacyPolicyModal";
import CookiesPolicyModal from "./CookiesPolicyModal";
import { Link } from "react-router-dom";
import Terms from "./Terms";

const Footer = () => {
  const { t } = useTranslation();

  const [isTermsOpen, setTermsOpen] = useState(false);
  const [isPrivacyOpen, setPrivacyOpen] = useState(false);
  const [isCookiesOpen, setCookiesOpen] = useState(false);

  const [email, setEmail] = useState(""); // Store the email input
  const [successMessage, setSuccessMessage] = useState(""); // Feedback for users
  const [acceptMarketing, setAcceptMarketing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Error handling

  const submitHandler = async (e) => {
    e.preventDefault();

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      setErrorMessage(t("footer.invalidEmail"));
      setSuccessMessage("");
      return;
    }

    if (!acceptMarketing) {
      setErrorMessage(t("footer.acceptMarketingError"));
      setSuccessMessage("");
      return;
    }

    try {
      // Add the email to the 'newsletter' collection in Firestore
      await addDoc(collection(firestore, "newsletter"), {
        email: email,
        subscribedAt: new Date(),
      });

      setSuccessMessage(t("footer.subscriptionSuccess"));
      setEmail("");
      setAcceptMarketing(false);
      setErrorMessage("");
    } catch (err) {
      console.error("Error adding document: ", err);
      setErrorMessage(t("footer.subscriptionError"));
      setSuccessMessage("");
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <>
      <FriendsCarousel />
      <footer className="footer-container">
        {/* Top Line with Logo */}
        <div className="footer-top-line">
          <div className="footer-logo" onClick={() => scrollToSection("#home")}>
            <img
              src="https://tinypic.host/images/2025/01/18/Logo-Aidly---principal-logo-fullColor-rgb4x.webp"
              alt="Aidly.ro"
              className="footer-logo-img"
            />
          </div>

          {/* Long Section for Newsletter */}
          <div className="footer-newsletter-long">
            <h3>{t("footer.newsletter")}</h3>
            <p>{t("footer.newsletterInfo")}</p>
            <form className="newsletter-form" onSubmit={submitHandler}>
              <input
                type="email"
                placeholder={t("footer.emailPlaceholder")}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="newsletter-input"
              />
              <div className="marketing-consent">
                <input
                  type="checkbox"
                  id="accept-marketing"
                  checked={acceptMarketing}
                  onChange={(e) => setAcceptMarketing(e.target.checked)}
                />
                <label htmlFor="accept-marketing">
                  {t("footer.acceptMarketing")}
                </label>
              </div>
              <button type="submit" className="newsletter-btn">
                {t("footer.subscribe")}
              </button>
            </form>
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>
        {/* Bottom Section with Social Media, Navigation, and Policies */}
        <div className="footer-bottom-section">
          {/* Social Media Section */}
          <div className="footer-social">
            <h3>{t("footer.followUs")}</h3>
            <div className="footer-social-icons">
              <a
                className="social-icon"
                href="https://www.facebook.com/profile.php?id=61562032966234"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fab fa-facebook"></i>
              </a>
              <a
                className="social-icon"
                href="https://www.linkedin.com/company/aidly-ro"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fab fa-linkedin"></i>
              </a>
              <a
                className="social-icon"
                href="https://www.instagram.com/aidly.ro/"
                target="_blank"
                rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>

          {/* Navigation Section */}
          <div className="footer-navigation">
            <h3>Link</h3>
            <div className="buttons-navigation">
              <Link to="/home" className="link-footer">
                Home
              </Link>
              <Link to="/services" className="link-footer">
                Services
              </Link>
              <Link to="/contact" className="link-footer">
                Contact
              </Link>
              <Link to="/press" className="link-footer">
                Press
              </Link>
              <Link to="/blog" className="link-footer">
                Blog
              </Link>
            </div>
          </div>

          {/* Policies Section */}
          <div className="footer-policies">
            <h3>{t("footer.policies")}</h3>
            <button className="link-footer" onClick={() => setTermsOpen(true)}>
              {t("footer.terms")}
            </button>
            <button
              className="link-footer"
              onClick={() => setPrivacyOpen(true)}>
              {t("footer.privacy")}
            </button>
            <button
              className="link-footer"
              onClick={() => setCookiesOpen(true)}>
              {t("footer.cookies")}
            </button>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="footer-bottom">
          <p>Copyright &copy; Aidly.ro 2025</p>
          <p className="footer-credit">
            {t("footer.createdBy")}
            <a href="https://upvisionmedia.com">
              <img
                src={Upvm}
                alt="UpVisionMedia"
                className="footer-upvm-logo"
              />
            </a>
          </p>
        </div>

        {/* Modals */}
        <Terms showModal={isTermsOpen} closeModal={() => setTermsOpen(false)} />
        <PrivacyPolicyModal
          showModal={isPrivacyOpen}
          closeModal={() => setPrivacyOpen(false)}
        />
        <CookiesPolicyModal
          showModal={isCookiesOpen}
          closeModal={() => setCookiesOpen(false)}
        />
      </footer>
    </>
  );
};

export default Footer;
