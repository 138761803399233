import React from "react";
import "../styling/terms.css";

const TermsModal = ({ showModal, closeModal, onAccept, onReject }) => {
  if (!showModal) return null;

  return (
    <div
      className={`modal-overlay-terms ${showModal ? "show" : ""}`}
      onClick={closeModal}>
      <div
        className={`modal-content-terms ${showModal ? "show" : ""}`}
        onClick={(e) => e.stopPropagation()}>
        <div className="modal-body-terms">
          <h2>Termeni și Condiții pentru Aidly.ro</h2>
          <p>
            <strong>Ultima actualizare:</strong> 02 aprilie 2025
          </p>

          <h3>1. Introducere</h3>
          <p>
            Bine ați venit pe Aidly.ro („Platforma”, „noi” sau „nostru”).
            Platforma este deținută și administrată de către FIXMATE ORGANIC
            START-UP S.R.L.:
          </p>
          <ul>
            <li>
              <strong>Denumire societate:</strong> FIXMATE ORGANIC START-UP
              S.R.L.
            </li>
            <li>
              <strong>Sediu social:</strong> București, Sector 3, Strada Baraj
              Rovinari, Nr. 12
            </li>
            <li>
              <strong>CUI:</strong> 51037551
            </li>
            <li>
              <strong>Nr. Registrul Comerțului:</strong> J2024049141001
            </li>
            <li>
              <strong>EUID:</strong> ROONRC.J2024049141001
            </li>
          </ul>
          <p>
            Prin utilizarea platformei, sunteți de acord cu acești termeni. Dacă
            nu sunteți de acord, vă rugăm să nu folosiți platforma.
          </p>

          <h3>2. Definiții</h3>
          <ul>
            <li>
              <strong>Utilizator:</strong> Orice persoană fizică sau juridică ce
              accesează sau utilizează platforma.
            </li>
            <li>
              <strong>Solicitant:</strong> Utilizatorul care postează o sarcină
              sau solicită un serviciu.
            </li>
            <li>
              <strong>Ajutor:</strong> Utilizatorul care oferă sprijin pentru
              realizarea sarcinilor.
            </li>
            <li>
              <strong>Serviciu / Sarcină / Anunț:</strong> Conectarea dintre
              Solicitanți și Ajutori prin Aidly.ro.
            </li>
          </ul>

          <h3>3. Descrierea Platformei</h3>
          <p>
            Aidly.ro conectează persoane care au nevoie de ajutor cu persoane
            dispuse să ajute. Platforma este gratuită și nu percepe comisioane
            între utilizatori.
          </p>

          <h3>4. Înregistrarea și Securitatea Contului</h3>
          <ul>
            <li>Trebuie să aveți cel puțin 16 ani;</li>
            <li>Să folosiți informații reale la înregistrare;</li>
            <li>Să păstrați datele de autentificare în siguranță;</li>
            <li>Să anunțați orice acces neautorizat.</li>
          </ul>

          <h3>5. Obligațiile Utilizatorilor</h3>
          <ul>
            <li>Să furnizați informații corecte și actualizate;</li>
            <li>Să respectați legislația și drepturile altora;</li>
            <li>Să mențineți o comunicare respectuoasă.</li>
          </ul>

          <h3>6. Postarea și Executarea Sarcinilor</h3>
          <ul>
            <li>
              Solicitanții trebuie să descrie clar sarcina și să răspundă
              prompt;
            </li>
            <li>
              Ajutoarele trebuie să accepte doar sarcini pe care le pot realiza
              și să respecte înțelegerile.
            </li>
          </ul>

          <h3>7. Plata și Tarife</h3>
          <ul>
            <li>Platforma nu intermediază plăți între utilizatori;</li>
            <li>
              Anunțurile promovate pot avea costuri afișate clar înainte de
              achiziție;
            </li>
            <li>
              Utilizatorii stabilesc între ei termenele și metodele de plată.
            </li>
          </ul>

          <h3>8. Limitarea Răspunderii</h3>
          <p>
            Platforma acționează doar ca intermediar. Nu garantăm calitatea sau
            finalizarea sarcinilor și nu verificăm identitatea utilizatorilor.
          </p>

          <h3>9. Proprietate Intelectuală</h3>
          <p>
            Conținutul platformei este protejat de drepturi de autor. Utilizarea
            neautorizată este interzisă.
          </p>

          <h3>10. Confidențialitate și Date</h3>
          <p>
            Datele sunt prelucrate conform GDPR și Politicii de
            Confidențialitate.
          </p>

          <h3>11. Dispute</h3>
          <p>
            Disputele trebuie soluționate amiabil. Litigiile sunt supuse
            instanțelor din România.
          </p>

          <h3>12. Încetarea Accesului</h3>
          <p>
            Aidly.ro poate suspenda conturi în caz de abateri. Utilizatorii pot
            șterge contul oricând.
          </p>

          <h3>13. Modificarea Termenilor</h3>
          <p>
            Aidly.ro își rezervă dreptul de a modifica termenii cu notificare.
          </p>

          <h3>14. Contact</h3>
          <p>
            Pentru întrebări, ne puteți contacta la:
            <br />
            Email: <a href="mailto:contact@aidly.ro">contact@aidly.ro</a>
            <br />
            Facebook: Aidly.ro
            <br />
            Instagram: Aidly.ro
          </p>

          <h3>15. Clauză de separabilitate</h3>
          <p>
            Dacă o prevedere devine invalidă, restul termenilor rămân valabili.
          </p>

          <h3>16. Acordul Integral</h3>
          <p>
            Termenii reprezintă acordul complet dintre utilizator și Aidly.ro.
          </p>

          <div className="modal-footer-terms">
            <button className="action-btn reject" onClick={onReject}>
              Nu accept
            </button>
            <button className="action-btn accept" onClick={onAccept}>
              Da, sunt de acord!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
