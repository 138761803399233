import React from "react";
import "../styling/TermsStyle.css";

const Terms = ({ showModal, closeModal }) => {
  if (!showModal) return null;

  return (
    <div className="terms-modal-overlay" onClick={closeModal}>
      <div
        className="terms-modal-container"
        onClick={(e) => e.stopPropagation()}>
        <button className="terms-close-btn" onClick={closeModal}>
          ✖
        </button>
        <h1 className="terms-title">Termeni și Condiții</h1>
        <p>
          <strong>Ultima actualizare:</strong> 02 aprilie 2025
        </p>

        <section className="terms-section">
          <h2>1. Introducere</h2>
          <p>
            Bine ați venit pe Aidly.ro („Platforma”, „noi” sau „nostru”).
            Platforma Aidly.ro este deținută și administrată de către FIXMATE
            ORGANIC START-UP S.R.L.:
          </p>
          <ul>
            <li>Denumire societate: FIXMATE ORGANIC START-UP S.R.L.</li>
            <li>
              Sediu social: București, Sector 3, Strada Baraj Rovinari, Nr. 12
            </li>
            <li>Cod Unic de Înregistrare (CUI): 51037551</li>
            <li>Număr Registrul Comerțului: J2024049141001</li>
            <li>Identificator Unic European (EUID): ROONRC.J2024049141001</li>
          </ul>
          <p>
            FIXMATE ORGANIC START-UP S.R.L. este proprietarul și operatorul
            legal al platformei online Aidly.ro. Toate serviciile și
            funcționalitățile oferite prin intermediul platformei sunt
            gestionate și furnizate de către FIXMATE ORGANIC START-UP S.R.L.,
            care asigură respectarea cadrului legal și reglementărilor
            aplicabile.
          </p>
          <p>
            Prin accesarea sau utilizarea Aidly.ro și a serviciilor conexe,
            sunteți de acord cu prezenții Termeni și Condiții („Termeni”). În
            cazul în care nu sunteți de acord cu vreun punct al acestor Termeni,
            vă rugăm să nu utilizați platforma.
          </p>
        </section>

        <section className="terms-section">
          <h2>2. Definiții</h2>
          <ul>
            <li>
              <strong>Utilizator:</strong> Orice persoană fizică sau juridică ce
              accesează sau utilizează platforma.
            </li>
            <li>
              <strong>Solicitant:</strong> Utilizatorul care postează o sarcină
              sau solicită un serviciu pe platformă.
            </li>
            <li>
              <strong>Ajutor:</strong> Utilizatorul care oferă sprijin pentru
              realizarea sarcinilor postate pe platformă.
            </li>
            <li>
              <strong>Serviciu / Sarcină / Anunț:</strong> Serviciul oferit de
              Aidly.ro pentru conectarea Solicitanților cu Ajutoarele
              disponibile.
            </li>
          </ul>
        </section>

        <section className="terms-section">
          <h2>3. Descrierea Platformei</h2>
          <p>
            Aidly.ro facilitează conexiunile dintre persoanele care au nevoie de
            ajutor (Solicitanți) și cele dispuse să ofere ajutor (Ajutoare).
            Platforma operează în prezent ca serviciu gratuit, fără perceperea
            de comisioane între părți.
          </p>
        </section>

        <section className="terms-section">
          <h2>4. Înregistrarea Utilizatorilor și Securitatea Contului</h2>
          <ul>
            <li>Să aveți cel puțin 16 ani;</li>
            <li>Să vă înregistrați folosind informații valide;</li>
            <li>Să asigurați securitatea datelor de autentificare;</li>
            <li>
              Să ne notificați imediat despre orice utilizare neautorizată a
              contului.
            </li>
          </ul>
          <p>
            Utilizatorii sunt responsabili pentru activitatea desfășurată prin
            contul lor, confidențialitatea datelor de autentificare și
            actualizarea informațiilor din cont.
          </p>
        </section>

        <section className="terms-section">
          <h2>5. Obligațiile Utilizatorilor</h2>
          <ul>
            <li>Furnizarea de informații corecte și reale;</li>
            <li>Respectarea legislației și reglementărilor aplicabile;</li>
            <li>
              Respectarea drepturilor și confidențialității altor utilizatori;
            </li>
            <li>Comunicarea profesională și respectuoasă.</li>
          </ul>
          <p>
            Este interzis să folosiți platforma pentru activități ilegale,
            hărțuire, conținut fals sau pentru a ocoli funcționalitățile
            platformei.
          </p>
        </section>

        <section className="terms-section">
          <h2>6. Postarea și Executarea Sarcinilor</h2>
          <ul>
            <li>
              Solicitanții trebuie să ofere descrieri clare, cerințe și să
              răspundă prompt;
            </li>
            <li>
              Ajutoarele trebuie să ofere doar servicii pe care le pot realiza,
              să comunice clar despre abilități și să respecte angajamentele
              asumate.
            </li>
          </ul>
        </section>

        <section className="terms-section">
          <h2>7. Plata și Tarifele</h2>
          <ul>
            <li>Platforma nu percepe taxe pentru utilizarea de bază;</li>
            <li>
              Nu intermediază plăți și nu garantează înțelegerile între
              utilizatori;
            </li>
            <li>
              Funcționalitățile premium pot implica taxe clare și
              nerambursabile, cu notificare prealabilă.
            </li>
          </ul>
          <p>
            Utilizatorii stabilesc între ei termenii de plată și sunt
            responsabili de reglementările fiscale și soluționarea disputelor.
          </p>
        </section>

        <section className="terms-section">
          <h2>8. Limitarea Răspunderii</h2>
          <ul>
            <li>Platforma este doar intermediar între utilizatori;</li>
            <li>
              Nu garantează calitatea serviciilor sau identitatea
              utilizatorilor;
            </li>
            <li>
              Utilizatorii folosesc platforma pe propriul risc și trebuie să
              verifice informațiile celorlalți.
            </li>
          </ul>
        </section>

        <section className="terms-section">
          <h2>9. Proprietate Intelectuală</h2>
          <p>
            Conținutul platformei este protejat de drepturile de proprietate
            intelectuală. Utilizarea fără permisiune, modificarea, decompilarea
            sau copierea sunt interzise.
          </p>
        </section>

        <section className="terms-section">
          <h2>10. Confidențialitate și Protecția Datelor</h2>
          <p>
            Datele sunt colectate și prelucrate conform Politicii de
            Confidențialitate și legislației aplicabile, inclusiv GDPR.
          </p>
          <p>
            Prin utilizarea platformei, vă exprimați consimțământul pentru
            colectarea și prelucrarea datelor personale.
          </p>
        </section>

        <section className="terms-section">
          <h2>11. Rezolvarea Disputelor</h2>
          <p>
            Utilizatorii vor încerca să rezolve disputele direct, cu sprijinul
            platformei dacă este necesar. Litigiile sunt supuse instanțelor din
            România.
          </p>
        </section>

        <section className="terms-section">
          <h2>12. Încetarea Accesului</h2>
          <p>
            Platforma poate suspenda sau închide conturile care nu respectă
            termenii. Utilizatorii își pot șterge contul în orice moment.
          </p>
        </section>

        <section className="terms-section">
          <h2>13. Modificarea Termenilor</h2>
          <p>
            Aidly.ro își rezervă dreptul de a modifica acești termeni și va
            notifica utilizatorii prin platformă, e-mail sau site.
          </p>
        </section>

        <section className="terms-section">
          <h2>14. Contact</h2>
          <p>
            Pentru întrebări legate de Termeni și Condiții:
            <br />
            Email: contact@aidly.ro
            <br />
            Facebook: Aidly.ro
            <br />
            Instagram: Aidly.ro
          </p>
        </section>

        <section className="terms-section">
          <h2>15. Clauză de Separabilitate</h2>
          <p>
            Dacă o prevedere devine invalidă, restul termenilor rămân valabili.
          </p>
        </section>

        <section className="terms-section">
          <h2>16. Acord Integral</h2>
          <p>
            Acești Termeni reprezintă acordul integral între utilizatori și
            Aidly.ro.
          </p>
        </section>
      </div>
    </div>
  );
};

export default Terms;
