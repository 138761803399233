import React, { useEffect, useState } from "react";
import {
  handleEmailPasswordLogin,
  handlePasswordReset,
  handleCreateAccount,
  handleGoogleLogin,
} from "../utils/firestoreService"; // Funcții pentru autentificare
import "../styling/loginModal.css"; // Stilurile pentru modal
import { firestore } from "../data/firebase";
import { doc, getDoc } from "firebase/firestore";
import PhoneModal from "../utils/PhoneModal"; // Import PhoneModal

const LoginModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState(""); // Stocăm erorile de validare
  const [resetError, setResetError] = useState(""); // Stocăm erorile de resetare
  const [isCreatingAccount, setIsCreatingAccount] = useState(false); // Controlează dacă este mod de creare cont
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const [showResetMessage, setShowResetMessage] = useState(false);

  const handleRepeatPasswordChange = (e) => setRepeatPassword(e.target.value);
  const handleFullNameChange = (e) => setFullName(e.target.value);
  const handlePhoneChange = (e) => setPhone(e.target.value);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false); // Manage PhoneModal state
  const [currentUser, setCurrentUser] = useState(null); // Store logged-in user info

  const [modalHeight, setModalHeight] = useState(window.innerHeight);

  useEffect(() => {
    const lockViewportHeight = () => {
      if (isOpen) {
        const viewportHeight = window.innerHeight;
        document.body.style.setProperty("--vh", `${viewportHeight * 0.01}px`);
      }
    };

    window.addEventListener("resize", lockViewportHeight);
    lockViewportHeight();

    return () => {
      window.removeEventListener("resize", lockViewportHeight);
      document.body.style.removeProperty("--vh");
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  useEffect(() => {
    // Ascultăm mesajele de la fereastra pop-up de autentificare
    const handleAuthMessage = (event) => {
      if (event.origin === window.origin) {
        if (event.data.status === "authSuccess") {
          onClose(); // Închide modalul de autentificare după succes
        } else if (event.data.status === "authFailure") {
          console.error("Autentificare Google eșuată:", event.data.error);
          setError(`Eroare la autentificare Google: ${event.data.error}`);
        }
      }
    };

    window.addEventListener("message", handleAuthMessage);

    // Curățare la demontare pentru a preveni multiple ascultători
    return () => {
      window.removeEventListener("message", handleAuthMessage);
    };
  }, [onClose]);

  // Autentificare
  const handleEmailLogin = (e) => {
    e.preventDefault();
    handleEmailPasswordLogin(email, password)
      .then(() => {
        onClose(); // Închide modalul după autentificare reușită
        window.location.reload(); // Reîncarcă pagina
      })
      .catch((error) => {
        console.error("Eroare la autentificare cu email și parolă:", error);
        setError("Eroare la autentificare cu email și parolă.");
      });
  };

  // Helper to detect if we're in a webview (this is a basic heuristic)
  const isWebView = () => {
    const ua = navigator.userAgent || "";
    // Android webviews often include "wv"
    if (ua.indexOf("wv") > -1) return true;
    // iOS webviews usually omit "Safari" from the UA string
    if (/iPhone|iPod|iPad/.test(ua) && !ua.includes("Safari")) return true;
    return false;
  };

  const handleGoogleLoginClick = async () => {
    // Check if the app is running in a webview
    if (isWebView()) {
     setError(
       "Autentificarea Google nu este suportată în acest webview. Vă rugăm să deschideți aplicația într-un browser extern pentru a vă autentifica."
     );
      
      return;
    }
    try {
      const user = await handleGoogleLogin();

      if (user) {
        const adminEmails = process.env.REACT_APP_ADMIN_EMAILS
          ? process.env.REACT_APP_ADMIN_EMAILS.split(",")
          : [];

        const isAdmin = adminEmails.includes(user.email);

        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        const userData = userDoc.exists() ? userDoc.data() : null;

        if (!userData?.phoneNumber || userData.phoneNumber === "N/A") {
          setCurrentUser(user);
          setIsPhoneModalOpen(true);
        } else {
          onClose(); // Close the modal if everything is set
        }
      }
    } catch (error) {
      setError("Eroare la autentificare Google.");
      console.error("Google Login Error:", error);
    }
  };

  const handleFocus = (e) => {
    e.target.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.position = "fixed";
      document.body.style.overflow = "hidden";
      document.body.style.width = "100%";
    } else {
      document.body.style.position = "";
      document.body.style.overflow = "";
      document.body.style.width = "";
    }

    return () => {
      document.body.style.position = "";
      document.body.style.overflow = "";
      document.body.style.width = "";
    };
  }, [isOpen]);

  useEffect(() => {
    const adjustModalPosition = () => {
      const modal = document.querySelector(".modal-login");
      if (!modal) return;

      const isKeyboardOpen = window.innerHeight < window.outerHeight * 0.7; // Detect keyboard
      if (isKeyboardOpen) {
        modal.style.transform = "translate(-50%, -20%)"; // Move modal up slightly
      } else {
        modal.style.transform = "translate(-50%, -50%)"; // Reset to center
      }
    };

    window.addEventListener("resize", adjustModalPosition);
    return () => window.removeEventListener("resize", adjustModalPosition);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  // Creare cont
  const handleCreateAccountClick = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors

    // Validate inputs
    if (password !== repeatPassword) {
      setError("Passwords do not match!");
      return;
    }
    if (!fullName.trim()) {
      setError("Full name is required!");
      return;
    }
    if (!phone.trim()) {
      setError("Phone number is required!");
      return;
    }

    try {
      await handleCreateAccount(email, password, fullName, phone);
      onClose(); // Close the modal
    } catch (error) {
      setError(error.message || "An error occurred during account creation.");
    }
  };

  useEffect(() => {
    const lockViewportHeight = () => {
      const viewportHeight = window.innerHeight;
      document.body.style.setProperty("--vh", `${viewportHeight * 0.01}px`);
    };

    window.addEventListener("resize", lockViewportHeight);
    lockViewportHeight();

    return () => {
      window.removeEventListener("resize", lockViewportHeight);
      document.body.style.removeProperty("--vh");
    };
  }, []);

  // Resetare parolă
  const handlePasswordResetClick = async () => {
    if (!email) {
      setShowResetMessage(true); // Afișează mesajul dacă emailul nu este completat
      return;
    }

    try {
      await handlePasswordReset(email);
      alert(
        "Un email pentru resetarea parolei a fost trimis dacă adresa există în baza de date."
      );
      setShowResetMessage(false); // Ascunde mesajul după resetare
    } catch (error) {
      setResetError("Eroare la resetarea parolei. Verifică adresa de email.");
      console.error("Eroare la resetarea parolei:", error);
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose(); // Închide modalul dacă se face click în afara lui
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="modal-overlay-login"
      onClick={handleOverlayClick}
      style={{ height: `${modalHeight}px` }}>
      <div className="modal-login">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>

        <h2 className="title-modal-login">
          {isCreatingAccount ? "Creează cont" : "Autentificare"}
        </h2>
        <div className="auth-options">
          <button className="google-login-btn" onClick={handleGoogleLoginClick}>
            {isCreatingAccount
              ? "Creează cont cu Google"
              : "Autentifică-te cu Google"}
          </button>
          <div className="email-login">
            <h3 className="title-modal-login">
              {isCreatingAccount
                ? "Creează cont cu email"
                : "Autentifică-te cu email și parolă"}
            </h3>
            <form
              className="email-login"
              onSubmit={
                isCreatingAccount ? handleCreateAccountClick : handleEmailLogin
              }>
              {isCreatingAccount && (
                <>
                  <input
                    type="text"
                    value={fullName}
                    onChange={handleFullNameChange}
                    placeholder="Nume complet"
                    onFocus={handleFocus}
                    required
                  />
                  <input
                    type="tel"
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="Număr de telefon"
                    onFocus={handleFocus}
                    required
                  />
                </>
              )}
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Email"
                onFocus={handleFocus}
                required
              />
              <input
                type="password"
                value={password}
                onChange={handlePasswordChange}
                onFocus={handleFocus}
                autoComplete="off"
                placeholder="Parolă"
                required
              />
              {isCreatingAccount && (
                <input
                  type="password"
                  value={repeatPassword}
                  autoComplete="off"
                  onChange={handleRepeatPasswordChange}
                  onFocus={handleFocus}
                  placeholder="Repetă parola"
                  required
                />
              )}
              <button type="submit">
                {isCreatingAccount ? "Creează cont" : "Autentificare"}
              </button>
            </form>
            {!isCreatingAccount && (
              <>
                <button
                  className="reset-password-btn"
                  onClick={handlePasswordResetClick}>
                  Nu reușești să te loghezi?
                </button>
                {showResetMessage && (
                  <span className="instruction-message">
                    Introdu adresa de email în câmpul de mai sus și apoi apasă
                    pe butonul de resetare.
                  </span>
                )}
              </>
            )}

            {error && <p className="error-message">{error}</p>}
            {resetError && <p className="error-message">{resetError}</p>}
            <div className="buttons-down-login">
              {isCreatingAccount ? (
                <>
                  Ai deja un cont?{" "}
                  <button
                    className="toggle-auth-mode-btn"
                    onClick={() => setIsCreatingAccount(false)}>
                    Am cont, mă loghez
                  </button>
                </>
              ) : (
                <>
                  Nu ai cont?{" "}
                  <button
                    className="toggle-auth-mode-btn button-create "
                    onClick={() => setIsCreatingAccount(true)}>
                    Creează cont
                  </button>
                </>
              )}

              {isPhoneModalOpen && (
                <PhoneModal
                  isOpen={isPhoneModalOpen}
                  onClose={() => setIsPhoneModalOpen(false)}
                  user={currentUser} // Pass the logged-in user
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
