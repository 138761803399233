import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { firestore } from "../data/firebase";
import LoginModal from "../utils/LoginModal"; // Modalul de login din proiectul tău
import "../styling/TasksPage.css";
import Image1 from "../assets/image1.webp";
import Image2 from "../assets/image2.webp";
import Image3 from "../assets/image3.jpg";

const TasksPage = () => {
  const { city } = useParams();
  const navigate = useNavigate();
  const auth = getAuth();

  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);

  // State pentru autentificare și modalul de login
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribeAuth();
  }, [auth]);

  const goToProfile = () => {
    if (currentUser) {
      navigate(`/profile/${currentUser.uid}`);
    } else {
      setIsLoginModalOpen(true);
    }
  };

  useEffect(() => {
    const q = query(
      collection(firestore, "ads"),
      where("location", "==", city)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const tasksData = [];
      querySnapshot.forEach((doc) => {
        tasksData.push({ id: doc.id, ...doc.data() });
      });
      setTasks(tasksData);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [city]);

  return (
    <div className="tasks-page">
      <h1 className="tasks-title">
        Sarcini pentru {city.charAt(0).toUpperCase() + city.slice(1)}
      </h1>
      {loading ? (
        <p className="loading">Se încarcă...</p>
      ) : tasks.length ? (
        <ul className="tasks-list">
          {tasks.map((task) => (
            <li key={task.id} className="task-item">
              <h2 className="task-title">{task.title ?? "Untitled Task"}</h2>
              <p className="task-description">
                {task.description ?? "No description provided."}
              </p>
              {/* Afișează alte câmpuri din document dacă e nevoie */}
            </li>
          ))}
        </ul>
      ) : (
        <div className="no-tasks-container">
          <h2 className="no-tasks-title">
            Posteaza tu un anunt pentru orasul{" "}
            {city.charAt(0).toUpperCase() + city.slice(1)}, este gratis
          </h2>
          <div className="no-tasks-squares">
            <div className="square">
              <img
                src={Image1} /* Înlocuiește cu URL-ul imaginii tale */
                alt="Posteaza anuntul tau gratuit"
                className="square-image"
              />
              <p className="square-text">Posteaza anuntul tau gratuit</p>
            </div>
            <div className="square">
              <img
                src={Image2} /* Înlocuiește cu URL-ul imaginii tale */
                alt="Primeste ajutorul pe platforma"
                className="square-image"
              />
              <p className="square-text">Primeste ajutorul pe platforma</p>
            </div>
            <div className="square">
              <img
                src={Image3} /* Înlocuiește cu URL-ul imaginii tale */
                alt="Alege persoana cea mai potrivita si finalizeaza sarcina"
                className="square-image"
              />
              <p className="square-text">
                Alege persoana cea mai potrivita si finalizeaza sarcina
              </p>
            </div>
          </div>
          <button className="btn-post-ad" onClick={goToProfile}>
            Posteaza Anunt!
          </button>
        </div>
      )}
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </div>
  );
};

export default TasksPage;
