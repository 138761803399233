import { useEffect, useState } from "react";

const PwaInstallPrompt = () => {
  const [visible, setVisible] = useState(false);
  const [instructions, setInstructions] = useState("");

  useEffect(() => {
    const dismissed = localStorage.getItem("pwaPopupDismissed");
    if (dismissed) return;

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    let message = "";

    if (/android/i.test(userAgent)) {
      message =
        "Atinge butonul ⋮ din colțul din dreapta sus și alege „Adaugă pe ecranul principal”.";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      message =
        "Atinge pictograma de partajare ⬆️ din bara de jos și alege „Adaugă pe ecranul de pornire” Doar din Safari Browser.";
    }

    if (message) {
      setInstructions(message);
      setVisible(true);
    }
  }, []);

  const handleClose = () => {
    setVisible(false);
    localStorage.setItem("pwaPopupDismissed", "true");
  };

  if (!visible) return null;

  return (
    <div style={popupStyles.container}>
      <div style={popupStyles.content}>
        <span style={popupStyles.close} onClick={handleClose}>
          ×
        </span>
        <h3 style={popupStyles.title}>Adaugă Aidly pe ecranul principal 📲</h3>
        <p style={popupStyles.text}>{instructions}</p>
      </div>
    </div>
  );
};

const popupStyles = {
  container: {
    position: "fixed",
    bottom: 100,
    left: 20,
    right: 20,
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
  },
  content: {
    backgroundColor: "#fff",
    color: "#333",
    padding: "16px 20px",
    borderRadius: "12px",
    maxWidth: "380px",
    width: "100%",
    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)",
    fontFamily: "sans-serif",
    position: "relative",
  },
  close: {
    position: "absolute",
    top: 8,
    right: 12,
    cursor: "pointer",
    fontSize: "18px",
  },
  title: {
    margin: 0,
    fontSize: "17px",
    color: "#0978d3",
  },
  text: {
    marginTop: "8px",
    fontSize: "15px",
  },
};

export default PwaInstallPrompt;
