import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../data/firebase";
import "../../styling/Blog.css";

const Blog = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const snapshot = await getDocs(collection(firestore, "articles"));
        const fetchedArticles = snapshot.docs.map((doc) => doc.data());
        setArticles(fetchedArticles);
      } catch (error) {
        console.error("❌ Error fetching articles:", error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <div className="blog-container">
      {/* Hero Section with Video */}
      <div className="blog-hero">
        <video
          className="hero-video"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true">
          <source
            src="https://videos.pexels.com/video-files/855427/855427-hd_1920_1080_25fps.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <div className="hero-overlay">
          <h1 className="hero-title">Aidly Connect</h1>
          <p className="hero-subtitle">
            Resurse, inspirație și soluții pentru cei care caută să își atingă
            obiectivele – împreună.
          </p>
        </div>
      </div>

      {/* Articles Section */}
      <div className="blog-articles">
        <h2 className="section-title">Articole Recente</h2>
        <div className="articles-grid">
          {[...articles]
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map((article, index) => (
              <Link
                to={`/blog/${article.category}/${article.slug}`}
                key={index}
                className="article-card-modern"
                style={{
                  backgroundImage: `url(${
                    article.sections.find((sec) => sec.image)?.image ||
                    "https://source.unsplash.com/random/800x600?" +
                      article.category
                  })`,
                }}>
                <div className="overlay-content">
                  <span className="article-date">
                    {new Date(article.date).toLocaleDateString("ro-RO")}
                  </span>
                  <h3 className="article-title-modern">{article.title}</h3>
                  <p className="article-meta-modern">
                    {article.category.replace("-", " ")}
                  </p>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
