import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoginModal from "../utils/LoginModal";
import { Link, useNavigate } from "react-router-dom";
import "../styling/CitiesPage.css";
import Image1 from "../assets/image1.webp";
import Image2 from "../assets/image2.webp";
import Image3 from "../assets/image3.jpg";

// Lista cu principalele orașe din România
const cities = [
  { name: "Arad", slug: "Arad" },
  { name: "Baia Mare", slug: "Baia-Mare" },
  { name: "Bacău", slug: "Bacau" },
  { name: "Brașov", slug: "Brasov" },
  { name: "Brăila", slug: "Braila" },
  { name: "Botoșani", slug: "Botosani" },
  { name: "București", slug: "Bucuresti" },
  { name: "Buzău", slug: "Buzau" },
  { name: "Calarasi", slug: "Calarasi" },
  { name: "Cluj-Napoca", slug: "Cluj-Napoca" },
  { name: "Constanța", slug: "Constanta" },
  { name: "Craiova", slug: "Craiova" },
  { name: "Deva", slug: "Deva" },
  { name: "Focsani", slug: "Focsani" },
  { name: "Galați", slug: "Galati" },
  { name: "Iași", slug: "Iasi" },
  { name: "Oradea", slug: "Oradea" },
  { name: "Piatra Neamț", slug: "Piatra-Neamt" },
  { name: "Pitești", slug: "Pitesti" },
  { name: "Ploiești", slug: "Ploiesti" },
  { name: "Reșița", slug: "Resita" },
  { name: "Râmnicu Vâlcea", slug: "Ramnicu-Valcea" },
  { name: "Remote", slug: "Online" },
  { name: "Satu Mare", slug: "Satu-Mare" },
  { name: "Sibiu", slug: "Sibiu" },
  { name: "Slatina", slug: "Slatina" },
  { name: "Suceava", slug: "Suceava" },
  { name: "Timișoara", slug: "Timisoara" },
  { name: "Târgu Mureș", slug: "Targu-Mures" },
  { name: "Tulcea", slug: "Tulcea" },
];

const CitiesPage = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const [currentUser, setCurrentUser] = useState(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, [auth]);

  const goToProfile = () => {
    if (currentUser) {
      navigate(`/profile/${currentUser.uid}`);
    } else {
      setIsLoginModalOpen(true);
    }
  };

  return (
    <div className="container-cities" id="cities">
      <div className="no-tasks-container-cities">
        <h2 className="no-tasks-title-cities">
          Postează un anunț în orașul tău, este gratuit!
        </h2>
        <div className="no-tasks-squares-cities">
          <div className="square-cities">
            <img
              src={Image1}
              alt="Postează anunțul tău gratuit"
              className="square-image-cities"
            />
            <p className="square-text-cities">Postează anunțul tău gratuit</p>
          </div>
          <div className="square-cities">
            <img
              src={Image2}
              alt="Primește ajutorul pe platformă"
              className="square-image-cities"
            />
            <p className="square-text-cities">Primește ajutorul pe platformă</p>
          </div>
          <div className="square-cities">
            <img
              src={Image3}
              alt="Alege persoana potrivită și finalizează sarcina"
              className="square-image-cities"
            />
            <p className="square-text-cities">
              Alege persoana potrivită și finalizează sarcina
            </p>
          </div>
        </div>
        <button className="btn-post-ad-cities" onClick={goToProfile}>
          Postează Anunț GRATUIT!
        </button>
      </div>
      <div className="right">
        <ul className="city-list">
          {cities.map((city) => (
            <li className="city-element" key={city.slug}>
              <Link to={`/${city.slug}/sarcini`}>{city.name}</Link>
            </li>
          ))}
        </ul>
      </div>

      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </div>
  );
};

export default CitiesPage;
