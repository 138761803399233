import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import BusinessPage from "./components/BusinessPage.jsx";
import Profile from "./components/Profile";
import AdminPanel from "./components/AdminPanel";
import { Route, Routes, useLocation } from "react-router-dom";
import PwaInstallPrompt from "./components/PwaInstallPrompt.js";
import { ToastContainer } from "react-toastify";
import UserProfile from "./components/UserProfile";
import ErrorBoundary from "./utils/ErrorBoundery";
import CategoryServices from "./components/CategoryServices";
import NotFoundPage from "./pages/NotFoundPage";
import Blog from "./components/blog/Blog";
import Article from "./components/blog/Article";
import Category from "./components/blog/Category";
import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./data/firebase";
import Terms from "./components/Terms";
import AdPage from "./components/AdPage";
import BusinessProfile from "./components/BusinessProfile.jsx";
import TasksPage from "./components/TasksPage.jsx";
import CitiesPage from "./components/CitiesPage.jsx";
import PressPage from "./components/PressPage.jsx";
const App = () => {
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Scroll to the respective section when the route changes
    const sectionId = location.pathname.replace("/", ""); // Get the section name from the route
    if (sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    // Track page views on route change
    if (window.gtag) {
      window.gtag("config", "G-Q5X3VM49Q5", {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);
  return (
    <>
      <ErrorBoundary>
        <ToastContainer position="bottom-left" />
        <Navbar />
        <PwaInstallPrompt />
        <Routes>
          {/* Route for the main page */}
          <Route
            path="/"
            element={
              <>
                <div id="home">
                  <Home />
                </div>
                <div id="services">
                  <Services currentUserId={currentUser?.uid} />
                </div>
                <div id="contact">
                  <Contact />
                </div>
                <div id="cities">
                  <CitiesPage />
                </div>
                <Footer />
              </>
            }
          />

          {/* Routes for other pages */}
          <Route path="/home" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/services"
            element={<Services currentUserId={currentUser?.uid} />}
          />
          <Route path="/services/:category" element={<CategoryServices />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/adminPanel/:userId" element={<AdminPanel />} />
          <Route path="/profile/:uid" element={<Profile />} />
          <Route path="/user/:uid" element={<UserProfile />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/press" element={<PressPage />} />
          <Route path="/business/:uid" element={<BusinessPage />} />
          <Route path="/termeni-si-conditii" element={<Terms />} />
          <Route path="/businessProfile/:uid" element={<BusinessProfile />} />
          <Route path="/" element={<CitiesPage />} />
          <Route path="/:city/sarcini" element={<TasksPage />} />
          <Route path="/ad/:id" element={<AdPage />} />
          <Route path="/blog/:categoryName" element={<Category />} />
          <Route path="/blog/:categoryName/:title" element={<Article />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>

        {/* <Chatbot /> */}
      </ErrorBoundary>
    </>
  );
};

export default App;
