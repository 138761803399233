import React, { useCallback, useEffect, useState } from "react";
import { Timestamp } from "firebase/firestore";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Pie } from "react-chartjs-2"; // Import Pie chart
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  addDoc,
  setDoc,
  onSnapshot,
  query,
  orderBy,
  where,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { firestore } from "../data/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../styling/admin.css";
import StatsDashboard from "./StatsDashboard";
import UploadArticles from "./UploadArticles";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const AdminPanel = () => {
  // eslint-disable-next-line
  const [users, setUsers] = useState([]);
  // eslint-disable-next-line
  const [ads, setAds] = useState([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [unlocked, setUnlocked] = useState(false);

  const [adToDelete, setAdToDelete] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [showActionModal, setShowActionModal] = useState(false);
  const [dailyNewUsers, setDailyNewUsers] = useState({});
  const [newTaskers, setNewTaskers] = useState([]);
  const [inProgressAds, setInProgressAds] = useState([]);

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentChatMessage, setCurrentChatMessage] = useState(null);

  const [deletedAds, setDeletedAds] = useState([]);
  const [deletedSortOrder, setDeletedSortOrder] = useState("asc");
  const auth = getAuth();
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [adsList, setAdsList] = useState([]); // Lista completă de anunțuri
  const [filteredAds, setFilteredAds] = useState([]); // Lista de anunțuri filtrată
  const [searchTerm, setSearchTerm] = useState(""); // Termenul de căutare
  const [searchTermUsers, setSearchTermUsers] = useState(""); // Termen de căutare pentru utilizatori
  const [sortOrder, setSortOrder] = useState("asc"); // Stare pentru ordinea de sortare
  const [statistics, setStatistics] = useState({
    totalUsers: 0,
    totalAds: 0,
    completedAds: 0,
    activeAds: 0,
  });

  const [verifiedTaskers, setVerifiedTaskers] = useState([]);
  const [showTaskerModal, setShowTaskerModal] = useState(false);
  const [selectedTasker, setSelectedTasker] = useState(null);

  const fetchVerifiedTaskers = useCallback(async () => {
    try {
      const taskersRef = collection(firestore, "taskers");
      const q = query(
        taskersRef,
        where("verified2", "==", true),
        orderBy("createdAt", "desc")
      );
      const snapshot = await getDocs(q);

      const taskers = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setVerifiedTaskers(taskers);
    } catch (error) {
      console.error("Error fetching verified taskers:", error);
      toast.error("Eroare la preluarea Taskerilor verificați.");
    }
  }, []);

  useEffect(() => {
    fetchVerifiedTaskers();
  }, [fetchVerifiedTaskers]);

  const openTaskerModal = (tasker) => {
    setSelectedTasker(tasker);
    setShowTaskerModal(true);
  };

  const closeTaskerModal = () => {
    setSelectedTasker(null);
    setShowTaskerModal(false);
  };

  const fetchAllAds = useCallback(async () => {
    try {
      // Interogare Firestore pentru a prelua doar anunțurile cu status "posted" sau "inProgress"
      const adsCollection = collection(firestore, "ads");
      const adsQuery = query(
        adsCollection,
        where("status", "in", ["posted", "inProgress"])
      );
      const adsSnapshot = await getDocs(adsQuery);

      // Transformă datele anunțurilor
      const adsData = adsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Preia datele utilizatorilor
      const usersCollection = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersCollection);

      // Creează o mapare userId -> date utilizator
      const usersMap = usersSnapshot.docs.reduce((acc, userDoc) => {
        acc[userDoc.id] = userDoc.data();
        return acc;
      }, {});

      // Adaugă informații suplimentare la fiecare anunț
      const enrichedAds = adsData.map((ad) => {
        const user = usersMap[ad.uid] || {}; // Găsește utilizatorul pe baza `uid`
        return {
          ...ad,
          displayName: user.displayName || "Unknown User",
          city: ad.location || "Unknown City", // Utilizează `location` din anunț
          phoneNumber: user.phoneNumber || "N/A",
        };
      });

      setAdsList(enrichedAds); // Setează lista completă de anunțuri
      setFilteredAds(enrichedAds); // Setează lista filtrată
    } catch (error) {
      console.error("Error fetching ads with user data:", error);
      toast.error("Eroare la preluarea anunțurilor.");
    }
  }, []);

  const handleSortByAds = () => {
    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (sortOrder === "asc") {
        return a.totalAds - b.totalAds;
      } else {
        return b.totalAds - a.totalAds;
      }
    });

    setFilteredUsers(sortedUsers);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc"); // Inversează ordinea sortării
  };

  const normalizeText = (text) => {
    return text
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, ""); // Elimină diacriticele
  };

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term === "") {
      setFilteredAds(adsList);
    } else {
      const normalizedTerm = normalizeText(term);

      const filtered = adsList.filter((ad) => {
        const fieldsToSearch = [
          ad.title,
          ad.displayName,
          ad.city,
          ad.phoneNumber,
        ];

        return fieldsToSearch.some((field) =>
          field ? normalizeText(field).includes(normalizedTerm) : false
        );
      });

      setFilteredAds(filtered);
    }
  };

  const handleUserSearch = (e) => {
    const term = e.target.value;
    setSearchTermUsers(term);

    if (term === "") {
      setFilteredUsers(users); // Revine la lista completă dacă nu e niciun termen
    } else {
      const normalizedTerm = normalizeText(term);

      const filtered = users.filter((user) => {
        const fieldsToSearch = [user.displayName, user.email, user.phoneNumber];

        return fieldsToSearch.some((field) =>
          field ? normalizeText(field).includes(normalizedTerm) : false
        );
      });

      setFilteredUsers(filtered);
    }
  };

  const handleVerifyTasker = async () => {
    if (!selectedTasker) return;
    try {
      await updateDoc(doc(firestore, "taskers", selectedTasker.id), {
        verified2: true,
      });
      toast.success("Tasker marcat ca verificat!");
      fetchNewTaskers(); // Reîmprospătează lista
      closeTaskerModal();
    } catch (error) {
      toast.error("Eroare la marcarea ca verificat.");
    }
  };
  const handleDeleteTasker = async () => {
    if (!selectedTasker) return;

    const reason = prompt("Introdu motivul ștergerii acestui Tasker:");
    if (!reason) return;

    try {
      await deleteDoc(doc(firestore, "taskers", selectedTasker.id));
      toast.success("Tasker șters definitiv!");
      fetchNewTaskers(); // Actualizează lista imediat după ștergere
      closeTaskerModal(); // Închide modalul
    } catch (error) {
      toast.error("Eroare la ștergerea Taskerului.");
      console.error(error);
    }
  };

  // Fetch all users
  const fetchUsers = useCallback(async () => {
    try {
      // Fetch utilizatori
      const usersCollection = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersCollection);

      // Fetch anunțuri
      const adsCollection = collection(firestore, "ads");
      const adsSnapshot = await getDocs(adsCollection);

      // Construim un obiect de contorizare pe baza câmpului "uid"
      const userAdCounts = adsSnapshot.docs.reduce((acc, adDoc) => {
        const adData = adDoc.data();
        const userId = adData.uid; // Asigurăm utilizarea câmpului corect, `uid`

        if (userId) {
          acc[userId] = (acc[userId] || 0) + 1;
        }
        return acc;
      }, {});

      // Mapăm lista de utilizatori și adăugăm `totalAds` pentru fiecare
      const userList = usersSnapshot.docs.map((userDoc) => {
        const userId = userDoc.id;
        const userData = userDoc.data();
        const totalAds = userAdCounts[userId] || 0; // Numărul de anunțuri sau 0

        return {
          id: userId,
          ...userData,
          totalAds,
        };
      });

      setUsers(userList);
      setFilteredUsers(userList);
    } catch (error) {
      console.error("Error fetching users and ads:", error);
      toast.error("Eroare la preluarea datelor utilizator și anunț.");
    }
  }, []);

  // Fetch terminated or deleted ads
  const fetchAds = useCallback(async () => {
    const adsCollection = collection(firestore, "ads");
    const adsQuery = query(adsCollection, where("isDeleted", "==", true));
    const adsSnapshot = await getDocs(adsQuery);
    const adList = adsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setAds(adList);
    setFilteredAds(adList);
  }, []);

  const fetchDeletedAds = useCallback(async () => {
    try {
      const deletedAdsCollection = collection(firestore, "deletedAds");
      const snapshot = await getDocs(deletedAdsCollection);
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDeletedAds(data);
    } catch (error) {
      console.error("Error fetching deleted ads:", error);
      toast.error("Eroare la preluarea anunțurilor șterse.");
    }
  }, []);

  // Funcție de restaurare a unui ad din deletedAds
  const restoreDeletedAd = async (adId) => {
    try {
      const deletedAdRef = doc(firestore, "deletedAds", adId);
      const deletedAdSnap = await getDoc(deletedAdRef);
      if (!deletedAdSnap.exists()) {
        toast.error("Anunțul nu a fost găsit în deletedAds.");
        return;
      }
      const deletedAdData = deletedAdSnap.data();
      // Pregătește datele pentru restaurare:
      // eliminăm câmpurile specifice ștergerii și resetăm statusul
      const restoredAdData = {
        ...deletedAdData,
        status: "posted", // sau alt status potrivit pentru restaurare
        isDeleted: false,
      };
      delete restoredAdData.deletedAt; // opțional, eliminăm timestamp-ul de ștergere
      // Creează documentul în colecția "ads" folosind același ID
      await setDoc(doc(firestore, "ads", adId), restoredAdData);
      // Șterge documentul din colecția "deletedAds"
      await deleteDoc(deletedAdRef);
      toast.success("Anunț restaurat cu succes!");
      // Reîmprospătează lista de deleted ads
      fetchDeletedAds();
      // Opțional: reîmprospătează și lista de ads active (dacă este nevoie)
    } catch (error) {
      console.error("Error restoring deleted ad:", error);
      toast.error("Eroare la restaurarea anunțului.");
    }
  };

  // Funcție pentru comutarea ordinii de sortare a anunțurilor șterse
  const toggleDeletedSortOrder = () => {
    setDeletedSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  // Sortează anunțurile șterse după timestamp-ul deletedAt
  const sortedDeletedAds = [...deletedAds].sort((a, b) => {
    // Dacă deletedAt este un Timestamp, îl convertim la secunde
    const timeA = a.deletedAt?.seconds || 0;
    const timeB = b.deletedAt?.seconds || 0;
    return deletedSortOrder === "asc" ? timeA - timeB : timeB - timeA;
  });

  // Apelul de fetch pentru deletedAds, de exemplu la montarea componentului
  useEffect(() => {
    fetchDeletedAds();
  }, [fetchDeletedAds]);

  const confirmDeleteAd = (adId) => {
    const reason = prompt("Introduceți motivul ștergerii acestui anunț:");
    if (reason) {
      setAdToDelete(adId);
      handleConfirmDelete(adId, reason);
    }
  };

  // Define the additional actions for conversations
  const handleAdditionalAction = async (action, conv) => {
    try {
      const convRef = doc(firestore, "conversations", conv.id);

      switch (action) {
        case "delete":
          await deleteDoc(convRef);
          toast.success("Conversation deleted successfully!");
          setConversations((prev) => prev.filter((c) => c.id !== conv.id));
          break;

        case "pin":
          // Update Firestore to mark the conversation as pinned
          await updateDoc(convRef, { isPinned: true });
          toast.success("Conversation pinned to top!");
          // Move the pinned conversation to the top of the list
          setConversations((prev) => {
            const updatedConvs = prev.map((c) =>
              c.id === conv.id ? { ...c, isPinned: true } : c
            );
            return updatedConvs.sort((a, b) => b.isPinned - a.isPinned);
          });
          break;

        case "resolve":
          // Mark the conversation as resolved
          await updateDoc(convRef, { status: "resolved" });
          toast.success("Conversation marked as resolved!");
          setConversations((prev) =>
            prev.map((c) =>
              c.id === conv.id ? { ...c, status: "resolved" } : c
            )
          );
          break;

        default:
          toast.error("Unknown action!");
      }
    } catch (error) {
      console.error("Error handling action:", error);
      toast.error("Failed to perform action. Please try again.");
    }
  };

  const openActionModal = (conversation) => {
    setSelectedConversation(conversation);
    setShowActionModal(true);
  };

  const closeActionModal = () => {
    setSelectedConversation(null);
    setShowActionModal(false);
  };

  const handleConfirmDelete = async (adId, deleteReason) => {
    try {
      const adRef = doc(firestore, "ads", adId);
      const adSnap = await getDoc(adRef);

      if (!adSnap.exists()) {
        toast.error("Anunțul nu a fost găsit.");
        return;
      }

      const adData = adSnap.data();
      const adminUser = auth.currentUser; // Admin who deletes the ad
      const deletedTimestamp = Timestamp.now();

      // Construct deleted ad object
      const deletedAdData = {
        adId, // Store explicitly
        ...adData,
        status: "deleted",
        deletedAt: deletedTimestamp,
        deletedAtFormatted: new Date().toLocaleString(), // Human-readable timestamp
        deletedBy: adminUser?.uid || "Admin",
        deletedByEmail: adminUser?.email || "Unknown",
        reason: deleteReason,
        deletedFromIP: await getUserIP(), // Capture IP if possible
      };

      // Move ad to "deletedAds" collection
      await setDoc(doc(firestore, "deletedAds", adId), deletedAdData);

      // Store deletion log in an audit collection
      await addDoc(collection(firestore, "auditLogs"), {
        action: "delete_ad",
        adId,
        deletedBy: adminUser?.uid || "Admin",
        deletedByEmail: adminUser?.email || "Unknown",
        deletedAt: deletedTimestamp,
        reason: deleteReason,
        userId: adData.uid,
      });

      // Remove the ad from "ads" collection
      await deleteDoc(adRef);

      // ✅ Notify the user
      const notificationData = {
        recipientUid: adData.uid,
        message: `Anunțul tău "${adData.title}" a fost șters de un administrator. Motiv: ${deleteReason}. Dacă consideri că această ștergere a fost o eroare, te rugăm să ne contactezi la contact@aidly.ro`,
        timestamp: deletedTimestamp,
        isRead: false,
        status: "deleted",
        isDeleted: false,
      };

      await addDoc(collection(firestore, "notifications"), notificationData);

      toast.success("Anunțul a fost șters și utilizatorul notificat.");
      setFilteredAds(filteredAds.filter((ad) => ad.id !== adId));
    } catch (error) {
      toast.error("Eroare la ștergerea anunțului.");
      console.error(error);
    } finally {
      setShowDeleteConfirm(false);
      setAdToDelete(null);
    }
  };

  // Function to get the user's IP address
  const getUserIP = async () => {
    try {
      const response = await fetch("https://api64.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP:", error);
      return "Unknown";
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirm(false);
    setAdToDelete(null);
  };
  const fetchNewTaskers = useCallback(async () => {
    try {
      const taskersRef = collection(firestore, "taskers");
      const q = query(
        taskersRef,
        where("verified1", "==", false),
        orderBy("createdAt", "desc")
      );
      const snapshot = await getDocs(q);

      const taskers = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setNewTaskers(taskers);
    } catch (error) {
      console.error("Error fetching new taskers:", error);
      toast.error("Eroare la preluarea noilor Taskeri.");
    }
  }, []);

  useEffect(() => {
    fetchNewTaskers();
  }, [fetchNewTaskers]);

  // Activează utilizatorul
  const handleActivateUser = async (userId) => {
    try {
      const userRef = doc(firestore, "users", userId);
      await updateDoc(userRef, { isActive: true });
      toast.success("Utilizator activat!");
      closeModal();
    } catch (error) {
      toast.error("Eroare la activarea utilizatorului.");
    }
  };

  // Dezactivează utilizatorul
  const handleDeactivateUser = async (userId) => {
    try {
      const userRef = doc(firestore, "users", userId);
      await updateDoc(userRef, { isActive: false });
      toast.success("Utilizator dezactivat!");
      closeModal();
    } catch (error) {
      toast.error("Eroare la dezactivarea utilizatorului.");
    }
  };

  // Șterge utilizatorul
  const handleDeleteUser = async (userId) => {
    try {
      await deleteDoc(doc(firestore, "users", userId));
      toast.success("Utilizator șters!");
      closeModal();
    } catch (error) {
      toast.error("Eroare la ștergerea utilizatorului.");
    }
  };

  // Resetează parola utilizatorului (folosind Firebase Authentication)
  const handleResetPassword = async (userId) => {
    try {
      const auth = getAuth();
      const userRef = doc(firestore, "users", userId);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const email = userDoc.data().email;
        await sendPasswordResetEmail(auth, email);
        toast.success("Email pentru resetare parolă trimis!");
        closeModal();
      } else {
        toast.error("Utilizatorul nu există.");
      }
    } catch (error) {
      toast.error("Eroare la trimiterea email-ului de resetare parolă.");
    }
  };

  const handleManageUser = async (user) => {
    setSelectedUser(user);
    setShowUserModal(true);
    document.body.style.overflow = "hidden"; // Disable scrolling

    try {
      // Fetch ads for the selected user
      const adsCollection = collection(firestore, "ads");
      const q = query(adsCollection, where("uid", "==", user.id));
      const adsSnapshot = await getDocs(q);

      // Categorize ads by status
      const adsData = adsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const postedAds = adsData.filter((ad) => ad.status === "posted");
      const inProgressAds = adsData.filter((ad) => ad.status === "inProgress");
      const finalizedAds = adsData.filter((ad) => ad.status === "finalized");

      // Set ads to state
      setSelectedUser((prev) => ({
        ...prev,
        postedAds,
        inProgressAds,
        finalizedAds,
      }));
    } catch (error) {
      console.error("Error fetching user ads:", error);
      toast.error("Eroare la preluarea anunțurilor utilizatorului.");
    }
  };

  const closeModal = () => {
    setShowUserModal(false);
    setSelectedUser(null);
    document.body.style.overflow = "auto"; // Reactivază scroll-ul
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchUsers();
    fetchAds();
    fetchAllAds(); // Fetch all ads
  }, [fetchUsers, fetchAds, fetchAllAds]);

  const handleDeleteVerifiedTasker = async (taskerId) => {
    const confirmDelete = window.confirm(
      "Ești sigur că vrei să ștergi acest Tasker?"
    );
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(firestore, "taskers", taskerId));
      toast.success("Tasker șters definitiv.");
      fetchVerifiedTaskers(); // reîncarcă lista după ștergere
    } catch (error) {
      console.error("Eroare la ștergerea Taskerului:", error);
      toast.error("A apărut o eroare la ștergere.");
    }
  };

  // STATISTICI

  const pieData = {
    labels: [
      "Total Users",
      "New Users Today",
      "Total Ads",
      "Completed Ads",
      "Active Ads",
      "Deleted Ads",
      "Draft Ads",
    ],
    datasets: [
      {
        label: "Platform Statistics",
        data: [
          statistics.totalUsers,
          statistics.newUsersToday,
          statistics.totalAds,
          statistics.completedAds,
          statistics.activeAds,
          statistics.deletedAdsCount,
          statistics.draftsCount,
        ],
        backgroundColor: [
          "#4ff800",
          "#ffa600",
          "#3498db",
          "#FFCE56",
          "#e74c3c",
          "#9b59b6",
          "#95a5a6",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Marchează utilizatorul ca verificat
  const handleVerifyUser = async (userId) => {
    try {
      const userRef = doc(firestore, "users", userId);
      await updateDoc(userRef, { verifiedUser: true });
      toast.success("Utilizator marcat ca verificat!");
      closeModal();
    } catch (error) {
      toast.error("Eroare la marcarea utilizatorului ca verificat.");
    }
  };

  const getNextVerificationLevel = (user) => {
    if (!user.verified1) return "1";
    if (!user.verified2) return "2";
    if (!user.verified3) return "3";
    return "complet"; // deja la maxim
  };

  const fetchStatistics = useCallback(async () => {
    try {
      const usersSnapshot = await getDocs(collection(firestore, "users"));
      const adsSnapshot = await getDocs(collection(firestore, "ads"));
      const deletedAdsSnapshot = await getDocs(
        collection(firestore, "deletedAds")
      );

      const totalUsers = usersSnapshot.size;

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const newUsersToday = usersSnapshot.docs.filter((doc) => {
        const data = doc.data();
        if (data.createdAt) {
          return data.createdAt.toDate() >= today;
        }
        return false;
      }).length;

      const adsData = adsSnapshot.docs.map((doc) => doc.data());

      const totalAds = adsData.filter((ad) =>
        ["posted", "inProgress"].includes(ad.status)
      ).length;

      const completedAds = adsData.filter(
        (ad) => ad.status === "finalized"
      ).length;

      const activeAds = adsData.filter(
        (ad) => ad.status === "inProgress"
      ).length;

      const deletedAdsCount = deletedAdsSnapshot.size;

      const draftsCount = adsData.filter((ad) => ad.status === "draft").length;

      setStatistics({
        totalUsers,
        newUsersToday,
        totalAds,
        completedAds,
        activeAds,
        deletedAdsCount,
        draftsCount,
      });
    } catch (error) {
      console.error("Error fetching statistics:", error);
      toast.error("Eroare la preluarea statisticilor.");
    }
  }, []);

  useEffect(() => {
    const loadData = async () => {
      await fetchStatistics();
    };
    loadData();
  }, [fetchStatistics]);

  useEffect(() => {
    return () => {
      if (currentChatMessage?.unsubscribe) {
        currentChatMessage.unsubscribe();
      }
    };
  }, [currentChatMessage]);

  const getGreeting = () => {
    // Poți folosi și timeZone: 'Europe/Bucharest' dacă dorești să te asiguri că e timpul României:
    // const now = new Date().toLocaleString("en-US", { timeZone: "Europe/Bucharest" });
    // const currentHour = new Date(now).getHours();

    const currentHour = new Date().getHours();
    if (currentHour < 11) {
      return "Bună dimineața ☕";
    } else if (currentHour < 19) {
      return "Bună ziua 🍸";
    } else {
      return "Bună seara 🍷";
    }
  };
  const getFirstName = (fullName) => {
    if (!fullName) return "";
    return fullName.split(" ")[0];
  };

  // EZXPORT EXCEL

  const downloadAllUsersExcel = () => {
    if (filteredUsers.length === 0) {
      toast.error("Nu există utilizatori de descărcat!");
      return;
    }

    const userData = filteredUsers.map((user) => ({
      Nume: user.displayName || "Unknown",
      Email: user.email || "N/A",
      Telefon: user.phoneNumber || "N/A",
      "Total Anunțuri": user.totalAds || 0,
    }));

    const worksheet = XLSX.utils.json_to_sheet(userData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Utilizatori");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(data, `Utilizatori_${new Date().toISOString().slice(0, 10)}.xlsx`);
  };

  const handleVerifyNextLevel = async (userId) => {
    try {
      if (typeof userId !== "string") {
        console.error("ID invalid:", userId);
        return toast.error("ID-ul utilizatorului este invalid.");
      }

      const motivation = prompt(
        "Introdu motivul pentru marcarea ca verificat:"
      );
      if (!motivation) return toast.info("Verificarea a fost anulată.");

      const userRef = doc(firestore, "users", userId);
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) return toast.error("Utilizatorul nu există.");

      const userData = userSnap.data();

      let updatedFields = {
        verified1: false,
        verified2: false,
        verified3: false,
      };

      if (!userData.verified1) {
        updatedFields.verified1 = true;
      } else if (!userData.verified2) {
        updatedFields.verified2 = true;
      } else if (!userData.verified3) {
        updatedFields.verified3 = true;
      } else {
        return toast.info("Utilizatorul este deja verificat la nivel maxim.");
      }

      await updateDoc(userRef, updatedFields);

      await addDoc(collection(firestore, "auditLogs"), {
        action: "verify_user_level",
        userId,
        motivation,
        updatedTo: updatedFields,
        timestamp: Timestamp.now(),
      });

      toast.success(
        "Utilizatorul a fost verificat cu succes la următorul nivel."
      );
    } catch (error) {
      console.error("Eroare la verificare nivel utilizator:", error);
      toast.error("Eroare la verificarea utilizatorului.");
    }
  };

  const fetchInProgressAds = useCallback(async () => {
    try {
      const adsRef = collection(firestore, "ads");
      const adsQuery = query(adsRef, where("status", "==", "inProgress"));
      const adsSnapshot = await getDocs(adsQuery);
      const adsData = adsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Preluăm doar detaliile clientului din colecția users
      const usersCollection = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const usersMap = usersSnapshot.docs.reduce((acc, userDoc) => {
        acc[userDoc.id] = userDoc.data();
        return acc;
      }, {});

      const enrichedAds = adsData.map((ad) => ({
        ...ad,
        clientDetails: usersMap[ad.uid] || {
          displayName: "N/A",
          phoneNumber: "N/A",
        },
        taskerDetails: {
          displayName: ad.taskerName || "N/A",
          phoneNumber: ad.taskerPhone || "N/A",
        },
      }));

      setInProgressAds(enrichedAds);
    } catch (error) {
      console.error("Error fetching in-progress ads:", error);
      toast.error("Eroare la preluarea anunțurilor în curs.");
    }
  }, []);

  useEffect(() => {
    fetchInProgressAds();
  }, [fetchInProgressAds]);

  return (
    <div className="admin-panel">
      <ToastContainer position="bottom-left" />
      <h1>
        {getGreeting()},{" "}
        {getFirstName(auth.currentUser?.displayName || "Admin")}!
      </h1>

      <div className="admin-panel-container">
        {/* Users Section */}
        <section className="statistics-section section-admin-panel">
          <h2 className="statistics-title">Platform Statistics</h2>
          <div className="statistics-cards">
            <div className="statistics-card">
              <h3>Total Users</h3>
              <p>{statistics.totalUsers}</p>
            </div>
            <div className="statistics-card">
              <h3>New Users Today</h3>
              <p>{statistics.newUsersToday}</p>
            </div>
            <div className="statistics-card">
              <h3>Total Ads</h3>
              <p>{statistics.totalAds}</p>
            </div>
            <div className="statistics-card">
              <h3>Finalized Ads (all time)</h3>
              <p>{statistics.completedAds}</p>
            </div>
            <div className="statistics-card">
              <h3>In Progress Ads</h3>
              <p>{statistics.activeAds}</p>
            </div>
            <div className="statistics-card">
              <h3>Deleted Ads (all time)</h3>
              <p>{statistics.deletedAdsCount}</p>
            </div>
            <div className="statistics-card">
              <h3>Draft Ads</h3>
              <p>{statistics.draftsCount}</p>
            </div>
          </div>

          <div className="chart-container">
            <h3>Platform Statistics Distribution</h3>
            <Pie data={pieData} />
          </div>
        </section>

        <StatsDashboard />

        <section className="section-admin-panel">
          <h2>Anunțuri în Curs (cu detalii Client și Tasker)</h2>
          {inProgressAds.length === 0 ? (
            <p>Nu există anunțuri în curs momentan.</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Titlu</th>
                  <th>Client (Aider)</th>
                  <th>Telefon Client</th>
                  <th>Tasker</th>
                  <th>Telefon Tasker</th>
                  <th>Buget</th>
                  <th>Oraș</th>
                </tr>
              </thead>
              <tbody>
                {inProgressAds.map((ad) => (
                  <tr key={ad.id}>
                    <td>{ad.title}</td>
                    <td>{ad.clientDetails.displayName}</td>
                    <td>
                      <a
                        href={`tel:${ad.clientDetails.phoneNumber}`}
                        className="phone-link">
                        {ad.clientDetails.phoneNumber}
                      </a>
                    </td>
                    <td>{ad.taskerDetails.displayName}</td>
                    <td>
                      <a
                        href={`tel:${ad.taskerDetails.phoneNumber}`}
                        className="phone-link">
                        {ad.taskerDetails.phoneNumber}
                      </a>
                    </td>
                    <td>{ad.price || "N/A"}</td>
                    <td>{ad.location || "N/A"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </section>

        <section className="section-admin-panel">
          <h2>Taskeri Noi (neaprobați)</h2>
          {newTaskers.length === 0 ? (
            <p>Nu există Taskeri noi momentan.</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Nume</th>
                  <th>Oraș</th>
                  <th>Data Înregistrare</th>
                  <th>Telefon</th>
                  <th>Contact</th>
                </tr>
              </thead>
              <tbody>
                {newTaskers.map((tasker) => (
                  <tr key={tasker.id}>
                    <td>{tasker.name || "N/A"}</td>
                    <td>{tasker.location || "Nespecificat"}</td>
                    <td>
                      {tasker.createdAt?.toDate
                        ? tasker.createdAt.toDate().toLocaleString("ro-RO")
                        : "N/A"}
                    </td>
                    <td>{tasker.phone || "N/A"}</td>
                    <td>
                      <button
                        onClick={() => openTaskerModal(tasker)}
                        className="button-tasker-contact">
                        Acțiuni
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </section>

        <section className="section-admin-panel">
          <h2>Taskeri Verificați</h2>
          {verifiedTaskers.length === 0 ? (
            <p>Nu există Taskeri verificați momentan.</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Nume</th>
                  <th>Oraș</th>
                  <th>Telefon</th>
                  <th>Contact WhatsApp</th>
                  <th>Acțiuni</th>
                </tr>
              </thead>
              <tbody>
                {verifiedTaskers.map((tasker) => (
                  <tr key={tasker.id}>
                    <td>{tasker.name || "N/A"}</td>
                    <td>{tasker.location || "Nespecificat"}</td>
                    <td>{tasker.phone || "N/A"}</td>
                    <td>
                      <a
                        href={`https://wa.me/${tasker.phone.replace(
                          /\D/g,
                          ""
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button-tasker-contact">
                        WhatsApp
                      </a>
                    </td>
                    <td>
                      <button
                        onClick={() => handleDeleteVerifiedTasker(tasker.id)}
                        className="button-tasker-delete">
                        🗑️ Șterge
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </section>

        <section className="total-users section-admin-panel">
          <h2>All Users</h2>
          <input
            type="text"
            placeholder="Search by name, email, or phone"
            value={searchTermUsers}
            onChange={handleUserSearch}
            className="search-input"
          />
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>
                  Email{" "}
                  <button
                    onClick={downloadAllUsersExcel}
                    style={{
                      marginLeft: "10px",
                      padding: "5px",
                      fontSize: "12px",
                      cursor: "pointer",
                      border: "none",
                      background: "#007bff",
                      color: "white",
                      borderRadius: "5px",
                    }}>
                    📥
                  </button>
                </th>

                <th>Phone</th>
                <th onClick={handleSortByAds} style={{ cursor: "pointer" }}>
                  Total Ads {sortOrder === "asc" ? "⬆️" : "⬇️"}
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="tbody-scroll">
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td>
                    <span
                      className="user-name-link"
                      onClick={() => handleManageUser(user)}
                      style={{
                        cursor: "pointer",
                        color: "#007bff",
                        textDecoration: "underline",
                      }}>
                      {user.displayName || "Unknown"}
                    </span>
                  </td>

                  <td>{user.email}</td>
                  <td>{user.phoneNumber || "N/A"}</td>
                  <td>{user.totalAds || 0}</td>
                  <td>
                    <button
                      className="manage-users"
                      onClick={() => handleManageUser(user)}>
                      Gestionare
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <section className="all-ads section-admin-panel">
          <h2>All Ads</h2>
          <input
            type="text"
            placeholder="Search by title, user name, city, or phone"
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
          />
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>User Name</th>
                <th>City</th>
                <th>Phone</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredAds.map((ad) => (
                <tr key={ad.id}>
                  <td>{ad.title}</td>
                  <td>{ad.displayName || "Unknown"}</td>
                  <td>{ad.city || "Unknown"}</td>
                  <td>{ad.phoneNumber || "N/A"}</td>
                  <td>
                    <button
                      onClick={() => confirmDeleteAd(ad.id)}
                      className="button-ad-delete-admin">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <section className="deleted-ads section-admin-panel">
          <h2>Deleted Ads</h2>
          <button
            onClick={toggleDeletedSortOrder}
            style={{ marginBottom: "1rem" }}>
            Sort by Deleted At:{" "}
            {deletedSortOrder === "asc" ? "Ascending" : "Descending"}
          </button>
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>User Name</th>
                <th>Deleted At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedDeletedAds.map((ad) => (
                <tr key={ad.id}>
                  <td>{ad.title}</td>
                  <td>{ad.displayName || "Unknown"}</td>
                  <td>
                    {ad.deletedAt?.toDate
                      ? ad.deletedAt.toDate().toLocaleString()
                      : "N/A"}
                  </td>
                  <td>
                    <button onClick={() => restoreDeletedAd(ad.id)}>
                      Restore
                    </button>
                  </td>
                </tr>
              ))}
              {sortedDeletedAds.length === 0 && (
                <tr>
                  <td colSpan="4" style={{ textAlign: "center" }}>
                    Nu există anunțuri șterse.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </section>

        {/* Gestiune Blog Section */}
        <section className="section-admin-panel">
          <h2>📝 Gestiune Blog</h2>
          <div
            style={{
              background: "#f12f2f",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              border: "1px solid #ffbaba",
              padding: "1rem",
              borderRadius: "8px",
              marginTop: "1rem",
              color: "#ffffff",
            }}>
            <strong>⚠️ Pentru Mihnea:</strong> Nu apăsa niciun buton din această
            secțiune. Acțiunile de aici pot afecta direct conținutul din
            platformă.
          </div>
          <div style={{ marginTop: "1rem" }}>
            {!unlocked ? (
              <button
                onClick={() => {
                  const pin = prompt("Introdu PIN-ul de securitate:");
                  if (pin === "0009") {
                    setUnlocked(true);
                    toast.success("Secțiunea a fost deblocată!");
                  } else {
                    toast.error("PIN incorect.");
                  }
                }}
                style={{
                  padding: "0.8rem 1.5rem",
                  backgroundColor: "#ffe44d",
                  border: "none",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}>
                🔓 Deblochează cu PIN
              </button>
            ) : (
              <UploadArticles />
            )}
          </div>
        </section>
      </div>

      {showDeleteConfirm && (
        <div className="modal-overlay-delete" onClick={handleCancelDelete}>
          <div
            className="modal-content-delete"
            onClick={(e) => e.stopPropagation()} // Prevent closing on modal content click
          >
            <h2>Confirm Delete</h2>
            <p>
              Are you sure you want to delete this ad? This action cannot be
              undone.
            </p>
            <div className="modal-buttons">
              <button
                className="modal-button confirm"
                onClick={handleConfirmDelete}>
                Yes, Delete
              </button>
              <button
                className="modal-button cancel"
                onClick={handleCancelDelete}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showUserModal && (
        <div className="modal-overlay-manage-users" onClick={closeModal}>
          <div
            className="modal-content-manage-users"
            onClick={(e) => e.stopPropagation()} // Prevent modal close on click inside
          >
            <button
              className="close-modal-button-manage-users"
              onClick={closeModal}>
              &times;
            </button>
            <h2 className="modal-title">Profil Utilizator</h2>
            <div className="modal-user-details">
              <p>
                <strong>Nume:</strong>{" "}
                {selectedUser?.displayName || "Necunoscut"}
              </p>
              <p>
                <strong>Email:</strong> {selectedUser?.email}
              </p>
              <p>
                <strong>Telefon:</strong> {selectedUser?.phoneNumber || "N/A"}
              </p>
              <p>
                <strong>Număr anunțuri:</strong> {selectedUser?.totalAds || 0}
              </p>
            </div>

            {/* Posted Ads Section */}
            <div className="modal-ads-section">
              <h3>Anunțuri Postate</h3>
              <div className="card-modal-admin">
                {selectedUser?.postedAds?.length > 0 ? (
                  selectedUser.postedAds.map((ad) => (
                    <div key={ad.id} className="ad-card-admin">
                      <h4>{ad.title}</h4>
                      <p>{ad.description}</p>
                      <p>
                        <strong>Locație:</strong> {ad.location || "N/A"}
                      </p>
                      <p>
                        <strong>Buget:</strong> {ad.price || "N/A"}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>Nu există anunțuri postate.</p>
                )}
              </div>
            </div>

            {/* In-Progress Ads Section */}
            <div className="modal-ads-section">
              <h3>Anunțuri în Curs</h3>
              <div className="card-modal-admin">
                {selectedUser?.inProgressAds?.length > 0 ? (
                  selectedUser.inProgressAds.map((ad) => (
                    <div key={ad.id} className="ad-card-admin">
                      <h4>{ad.title}</h4>
                      <p>{ad.description}</p>
                      <p>
                        <strong>Locație:</strong> {ad.location || "N/A"}
                      </p>
                      <p>
                        <strong>Buget:</strong> {ad.price || "N/A"}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>Nu există anunțuri în curs.</p>
                )}
              </div>
            </div>

            {/* Finalized Ads Section */}
            <div className="modal-ads-section">
              <h3>Anunțuri Finalizate</h3>
              <div className="card-modal-admin">
                {selectedUser?.finalizedAds?.length > 0 ? (
                  selectedUser.finalizedAds.map((ad) => (
                    <div key={ad.id} className="ad-card-admin">
                      <h4>{ad.title}</h4>
                      <p>{ad.description}</p>
                      <p>
                        <strong>Locație:</strong> {ad.location || "N/A"}
                      </p>
                      <p>
                        <strong>Buget:</strong> {ad.price || "N/A"}
                      </p>
                    </div>
                  ))
                ) : (
                  <p>Nu există anunțuri finalizate.</p>
                )}
              </div>
            </div>

            <div className="modal-actions-manage-users">
              <button
                className="modal-button activate"
                onClick={() => handleActivateUser(selectedUser.id)}>
                Activează
              </button>
              <button
                className="modal-button deactivate"
                onClick={() => handleDeactivateUser(selectedUser.id)}>
                Dezactivează
              </button>
              <button
                className="modal-button delete"
                onClick={() => handleDeleteUser(selectedUser.id)}>
                Șterge
              </button>
              <button
                className="modal-button reset-password"
                onClick={() => handleResetPassword(selectedUser.id)}>
                Resetează Parola
              </button>
              {/* ➡️ Adaugă noul buton aici ⬇️ */}
              <button
                className="modal-button verify-user"
                onClick={() => handleVerifyUser(selectedUser.id)}>
                Marchează ca verificat
              </button>
              <button onClick={() => handleVerifyNextLevel(selectedUser.id)}>
                Marchează verificat - Nivel{" "}
                {getNextVerificationLevel(selectedUser)}
              </button>
            </div>
          </div>
        </div>
      )}

      {showTaskerModal && selectedTasker && (
        <div className="modal-overlay-delete" onClick={closeTaskerModal}>
          <div
            className="modal-content-confirm-tasker"
            onClick={(e) => e.stopPropagation()}>
            <h2>Tasker: {selectedTasker.name}</h2>
            <p>
              <strong>Telefon:</strong> {selectedTasker.phone}
            </p>
            <p>
              <strong>Locație:</strong> {selectedTasker.location || "N/A"}
            </p>
            <div className="modal-buttons-tasker">
              <a href={`tel:${selectedTasker.phone}`} className="call-tasker">
                Sună
              </a>
              <button className="activate-tasker" onClick={handleVerifyTasker}>
                Marchează ca verificat
              </button>
              <button
                className="modal-button verify-user"
                onClick={() => handleVerifyNextLevel(selectedUser.id)}>
                🔐 Marchează Verificat - Next Level
              </button>

              <button className="delete-tasker" onClick={handleDeleteTasker}>
                Șterge Tasker
              </button>
              <button className="cancel-tasker" onClick={closeTaskerModal}>
                Închide
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;
