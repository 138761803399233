// BecomeTaskerModal.jsx
import React, { useState, useEffect } from "react";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { firestore, auth } from "../data/firebase";
import categoriesData from "../data/categories.json";
import "../styling/BecomeTaskerModal.css";
import locationsData from "../data/locations.json";

const BecomeTaskerModal = ({ onClose }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [location, setLocation] = useState("");
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setCategories(categoriesData.categories);
  }, []);

  const toggleCategory = (categoryName) => {
    setSelectedCategories((prev) => {
      if (prev.includes(categoryName)) {
        return prev.filter((name) => name !== categoryName);
      } else if (prev.length < 5) {
        return [...prev, categoryName];
      } else {
        alert("Poți selecta maxim 5 categorii!");
        return prev;
      }
    });
  };

  useEffect(() => {
    setCategories(categoriesData.categories);
    setLocations(Object.keys(locationsData)); // extrage orașele
  }, []);

  const formatPhone = (rawPhone) => {
    const cleaned = rawPhone.replace(/\D/g, ""); // elimină spații, paranteze, etc.

    if (cleaned.startsWith("7") && cleaned.length === 9) {
      // Ex: 744497740 → România, fără prefix
      return "40" + cleaned;
    }

    if (cleaned.startsWith("0") && cleaned.length === 10) {
      // Ex: 0744497740
      return "4" + cleaned.slice(1);
    }

    if (cleaned.startsWith("40") || cleaned.startsWith("4")) {
      // Ex: 40744497740 sau 4744497740
      return cleaned;
    }

    if (cleaned.startsWith("00")) {
      return cleaned.slice(2); // transformă 0040... în 40...
    }

    if (rawPhone.startsWith("+")) {
      return cleaned; // deja e ok
    }

    // Altă țară sau format necunoscut → salvează cum a fost introdus
    return rawPhone;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const collectionData = {
      name,
      email,
      phone: formatPhone(phone),
      location,
      verified1: false,
      categories: selectedCategories,
      uid: auth.currentUser ? auth.currentUser.uid : null,
      badge: "Team Tasker",
      createdAt: serverTimestamp(),
    };

    try {
      await addDoc(collection(firestore, "taskers"), collectionData);
      alert(
        "Îți mulțumim că ai decis să intri în echipa de Taskeri! Te vom notifica pe email când vor apărea anunțuri pentru categoriile selectate."
      );
      handleCloseModal();
    } catch (error) {
      console.error("Error adding document:", error);
      alert("Ceva a mers greșit. Încearcă din nou.");
    }
  };

  const handleCloseModal = () => {
    setName("");
    setEmail("");
    setPhone("");
    setSelectedCategories([]);
    onClose();
  };

  return (
    <div className="modal-overlay-tasker">
      <div className="modal-content-tasker">
        <h2 className="modal-title-tasker">🛠️ Devino Tasker!</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nume și prenume"
            required
          />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Telefon"
            required
          />
          <select
            className="location-select"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required>
            <option value="">Selectează orașul tău</option>
            {locations.map((loc) => (
              <option key={loc} value={loc}>
                {loc}
              </option>
            ))}
          </select>

          <div className="categories-selection-tasker">
            <p>Selectează categorii (max 5):</p>
            <div className="categories-select">
              {categories.map((category) => (
                <div
                  key={category.name}
                  className={`category-item ${
                    selectedCategories.includes(category.name) ? "selected" : ""
                  }`}
                  onClick={() => toggleCategory(category.name)}>
                  {category.name}
                </div>
              ))}
            </div>
          </div>

          <button type="submit" className="submit-tasker-btn">
            Înregistrează-te acum
          </button>
        </form>
        <button className="close-modal-btn-tasker" onClick={handleCloseModal}>
          Închide
        </button>
      </div>
    </div>
  );
};

export default BecomeTaskerModal;
