import { addDoc, collection, Timestamp } from "firebase/firestore";

import { useState } from "react";
import "../styling/PostAnnouncementModal.css";
import categories from "../data/categories.json";

const PostAnnouncementModal = ({ uid, onClose, db }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    priceType: "Negociabil",
    pricePeriod: "ora", // valoare implicită
    experienceLevel: "",
    estimatedDuration: "",
    validity: "",
    numberOfPeople: "",
    ageRange: "",
    category: "",
    cvRequired: false,
    isUrgent: false,
    fullName: "",
    phone: "",
    email: "",
    location: "",
    status: "posted",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const locationOptions = [
    "Sector 1",
    "Sector 2",
    "Sector 3",
    "Sector 4",
    "Sector 5",
    "Sector 6",
    "Ilfov",
    "Remote",
  ];

  // Handles all form changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prev) => {
      let newData = {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };

      // ✅ Automatically set "isUrgent" if validity is within 3 days
      if (name === "validity") {
        const selectedDate = new Date(value);
        const today = new Date();
        const threeDaysFromNow = new Date();
        threeDaysFromNow.setDate(today.getDate() + 3);

        if (selectedDate < threeDaysFromNow) {
          newData.isUrgent = true;
        } else {
          newData.isUrgent = false;
        }
      }

      return newData;
    });
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      await addDoc(collection(db, "announcements"), {
        ...formData,
        validity: Timestamp.fromDate(new Date(formData.validity)),
        status: "posted", // ✅ Setăm statusul implicit
        businessId: uid,
        ownerUid: uid, // ✅ Adăugăm ownerUid
        ownerCompleted: false, // Adăugat
        taskerCompleted: false, // Adăugat
        createdAt: new Date(),
      });
      onClose();
    } catch (err) {
      console.error("Error posting announcement:", err);
      setError("A apărut o eroare. Încearcă din nou.");
    }

    setLoading(false);
  };

  return (
    <div className="modal-overlay-post-business">
      <div className="modal-content-post-business">
        <button className="close-button-modal" onClick={onClose}>
          x
        </button>
        <h2>Postează un anunț</h2>
        <form onSubmit={handleSubmit}>
          {/* Titlu */}
          <div className="form-group-modal-post-business">
            <label>Titlu</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </div>

          {/* Descriere */}
          <div className="form-group-modal-post-business">
            <label>Descriere</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </div>

          {/* Date de contact Business */}
          <div className="form-group-modal-post-business">
            <label>Nume complet</label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="inline-double">
            <div className="form-group-modal-post-business">
              <label>Număr de telefon</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group-modal-post-business">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Preț + Tip */}
          <div className="inline-double">
            {/* Buget */}
            <div className="form-group-modal-post-business">
              <label>Buget</label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
              />
            </div>

            {/* Tip Plată */}
            <div className="form-group-modal-post-business">
              <label>Tip Plată</label>
              <select
                name="priceType"
                value={formData.priceType}
                onChange={handleChange}
                required>
                <option value="Negociabil">Negociabil</option>
                <option value="Fixa">Fixa</option>
              </select>
            </div>
          </div>
          <div className="inline-double">
            <div className="form-group-modal-post-business">
              <label>Buget pe</label>
              <select
                name="pricePeriod"
                value={formData.pricePeriod}
                onChange={handleChange}
                required>
                <option value="ora">Ora</option>
                <option value="zi">Zi</option>
                <option value="saptamana">Săptămână</option>
                <option value="luna">Lună</option>
              </select>
            </div>

            {/* Categorie */}
            <div className="form-group-modal-post-business">
              <label>Categorie</label>
              <select
                name="category"
                value={formData.category}
                onChange={handleChange}
                required>
                <option value="">Selectează categoria</option>
                {categories.categories.map((cat) => (
                  <option key={cat.name} value={cat.name}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="inline-double">
            {/* Locație */}
            <div className="form-group-modal-post-business">
              <label>Locație</label>
              <select
                name="location"
                value={formData.location}
                onChange={handleChange}
                required>
                <option value="">Selectează locația</option>
                {locationOptions.map((loc) => (
                  <option key={loc} value={loc}>
                    {loc}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group-modal-post-business">
              <label>Interval de vârstă</label>
              <select
                name="ageRange"
                value={formData.ageRange}
                onChange={handleChange}
                required>
                <option value="">Selectează intervalul de vârstă</option>
                <option value="18-25">18-25</option>
                <option value="26-35">26-35</option>
                <option value="36-45">36-45</option>
                <option value="46-55">46-55</option>
                <option value="55+">55+</option>
              </select>
            </div>
          </div>
          {/* Nivel de experiență + Durata sarcinii */}
          <div className="inline-double">
            <div className="form-group-modal-post-business">
              <label>Nivel de experiență</label>
              <select
                name="experienceLevel"
                value={formData.experienceLevel}
                onChange={handleChange}
                required>
                <option value="">Selectează nivelul</option>
                <option value="Incepator">Începător</option>
                <option value="Intermediar">Intermediar</option>
                <option value="Avansat">Avansat</option>
              </select>
            </div>
            <div className="form-group-modal-post-business">
              <label>Durata sarcinii</label>
              <select
                name="estimatedDuration"
                value={formData.estimatedDuration}
                onChange={handleChange}
                required>
                <option value="">Selectează durata</option>
                <option value="Scurtă">Scurtă</option>
                <option value="Mediu">Mediu</option>
                <option value="Lungă">Lungă</option>
              </select>
            </div>
          </div>
          <div className="inline-double">
            <div className="form-group-modal-post-business">
              <label>Număr persoane căutate</label>
              <input
                type="number"
                name="numberOfPeople"
                value={formData.numberOfPeople}
                onChange={handleChange}
                required
              />
            </div>

            {/* Valabilitate */}
            <div className="form-group-modal-post-business">
              <label>Valabil până la</label>
              <input
                type="date"
                name="validity"
                value={formData.validity}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          {/* ✅ Urgent Checkbox */}
          <div className="form-group-modal-post-business label-urgent-business">
            <label>Este urgent?</label>{" "}
            <input
              type="checkbox"
              name="isUrgent"
              checked={formData.isUrgent}
              onChange={handleChange}
            />
          </div>

          {/* Afișăm eroarea (dacă există) */}
          {error && <p className="error">{error}</p>}

          <div className="modal-buttons-post-business">
            <button
              type="button"
              onClick={onClose}
              className="cancel-button-post-business">
              Anulează
            </button>
            <button
              type="submit"
              disabled={loading}
              className="submit-button-post-business">
              {loading ? "Se salvează..." : "Postează anunț"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostAnnouncementModal;
