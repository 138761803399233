import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../data/firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import "../styling/businessProfile.css"; // Ensure this file exists
import BusinessCover from "../assets/coverBusiness.png";
import BusinessLogo from "../assets/logoBusiness.png";
import Modal from "./Modal"; // ✅ Import Modal component

const BusinessProfile = () => {
  const { uid } = useParams();
  const [businessInfo, setBusinessInfo] = useState(null);
  const [businessAds, setBusinessAds] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [selectedAd, setSelectedAd] = useState(null); // ✅ State to manage modal

  useEffect(() => {
    const fetchBusinessInfo = async () => {
      if (!uid) return;

      try {
        const businessRef = doc(firestore, "business_users", uid);
        const businessSnap = await getDoc(businessRef);

        if (businessSnap.exists()) {
          setBusinessInfo(businessSnap.data());
        }
      } catch (error) {
        console.error("Error fetching business info:", error);
      }
    };

    const fetchBusinessAds = async () => {
      if (!uid) return;

      try {
        const adsRef = collection(firestore, "announcements");
        const q = query(
          adsRef,
          where("businessId", "==", uid),
          where("status", "==", "posted")
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const ads = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setBusinessAds(ads);
        }
      } catch (error) {
        console.error("Error fetching business ads:", error);
      }
    };

    fetchBusinessInfo();
    fetchBusinessAds();
  }, [uid]);

  return (
    <div className="business-profile-page">

      {/* Business Info */}
      <div className="business-info-container">
        <div className="profile-container">
          <img
            src={businessInfo?.logoURL || BusinessLogo}
            alt="Business Logo"
            className="business-logo"
          />
        </div>
        <div className="business-details">
          <h2 className="business-name">
            {businessInfo?.companyName || "Business Name"}
          </h2>
          <p className="business-cui">
            <strong>CUI: </strong> {businessInfo?.cui || "N/A"}
          </p>
          {businessInfo?.website && (
            <p className="business-website">
              <strong>Website: </strong>
              <a
                href={businessInfo.website}
                target="_blank"
                rel="noopener noreferrer">
                {businessInfo.website}
              </a>
            </p>
          )}
          <div className="business-rating">
            <strong>Rating:</strong> {averageRating} / 5{" "}
            {"★".repeat(Math.round(averageRating))}{" "}
            {"☆".repeat(5 - Math.round(averageRating))}
          </div>
        </div>
      </div>

      {/* Business Announcements */}
      <div className="business-ads">
        <h3>Business Ads</h3>
        {businessAds.length > 0 ? (
          <div className="ads-list">
            {businessAds.map((ad) => (
              <div key={ad.id} className="ad-card">
                <div className="ad-header">
                  <h4 className="ad-title">{ad.title}</h4>
                </div>
                <div className="ad-body">
                  <p className="ad-description">{ad.description}</p>
                  <p>
                    <strong>Budget:</strong> {ad.budget} {ad.budgetType}
                  </p>
                  <p>
                    <strong>Category:</strong> {ad.category}
                  </p>
                  <p>
                    <strong>Needed By:</strong>{" "}
                    {ad.validity
                      ? new Date(ad.validity.seconds * 1000).toLocaleDateString(
                          "en-GB"
                        )
                      : "N/A"}
                  </p>
                </div>
                <div className="ad-footer">
                  <button
                    className="ad-button"
                    onClick={() => setSelectedAd(ad)}>
                    View Details
                  </button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No active ads for this business.</p>
        )}
      </div>

      {/* ✅ Show Modal when an ad is selected */}
      {selectedAd && (
        <Modal service={selectedAd} onClose={() => setSelectedAd(null)} />
      )}
    </div>
  );
};

export default BusinessProfile;
