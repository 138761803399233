import React, { useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import ModalDetails from "../components/Modal";
import VerifiedBadge from "../assets/teamTasker2.png";
import emailjs from "@emailjs/browser";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaInfoCircle } from "react-icons/fa"; // Icon pentru buton info
import "../styling/profile.css";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import forbiddenWordsData from "../data/forbiddenWords.json";

// import { setLogLevel } from "firebase/app";
// setLogLevel("debug");

import {
  deleteDoc,
  collection,
  doc,
  addDoc,
  setDoc,
  updateDoc,
  orderBy,
  serverTimestamp,
  query,
  where,
  getDocs,
  getDoc,
  Timestamp,
} from "firebase/firestore";

import { firestore, auth } from "../data/firebase";
import categoriesData from "../data/categories.json"; // încă folosit pentru sortare
import ProfileImage1 from "../assets/mascota.webp";
import Sad from "../assets/sad.png";
import locationsData from "../data/locations.json";
// ---------- importuri helper-e -----------
import {
  detectCategory,
  generateSuggestions,
} from "../helpers/categoryHelpers";
import BecomeTaskerModal from "./BecomeTaskerModal";

// Setăm root-ul pentru Modal
Modal.setAppElement("#root");

const Profile = () => {
  const { uid } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // --------------------------------------------------
  // State
  // --------------------------------------------------
  const [user, setUser] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(ProfileImage1);

  // State extrase din codul original (Profile)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isDraftsModalOpen, setIsDraftsModalOpen] = useState(false);
  const [draftAds, setDraftAds] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState(false);
  const [isTasker, setIsTasker] = useState(false);
  const [isTaskerModalOpen, setIsTaskerModalOpen] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(null);
  const [currentAd, setCurrentAd] = useState(null);
  const [isDoneModalOpen, setIsDoneModalOpen] = useState(false);
  const [isUndoneModalOpen, setIsUndoneModalOpen] = useState(false);
  // eslint-disable-next-line
  const [forbiddenWordsList, setForbiddenWordsList] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [verificationLevel, setVerificationLevel] = useState(null);

  // Recenzie / undone reason
  const [reviewData, setReviewData] = useState({
    rating: 0,
    review: "",
  });
  const [undoneReason, setUndoneReason] = useState("");
  const [currentRole, setCurrentRole] = useState("");

  const [ads, setAds] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [categories, setCategories] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [undoneOption, setUndoneOption] = useState("");
  // Folosim denumirile noi pentru a evita conflictele:
  const [isTaskUndoneModalOpen, setIsTaskUndoneModalOpen] = useState(false);
  const [currentTaskAnnouncement, setCurrentTaskAnnouncement] = useState(null);
  const [taskUndoneReason, setTaskUndoneReason] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  // Filtre / search
  const [searchTitle, setSearchTitle] = useState("");
  const [searchCategory, setSearchCategory] = useState("");

  // notificații
  // eslint-disable-next-line
  const [currentNotification, setCurrentNotification] = useState(null);

  // taburi
  const [inProgressAds, setInProgressAds] = useState([]);
  const [completedAds, setCompletedAds] = useState([]);
  // const [showCompletedAds, setShowCompletedAds] = useState(false);
  const [isPostPopupOpen, setIsPostPopupOpen] = useState(false);

  // Favorite ads
  const [favoriteAds, setFavoriteAds] = useState([]);
  const [selectedAd, setSelectedAd] = useState(null);
  // const [showFavoriteAds, setShowFavoriteAds] = useState(false);

  const [infoPopup, setInfoPopup] = useState(null); // Starea pop-up-urilor

  const togglePopup = (field) => {
    setInfoPopup(infoPopup === field ? null : field);
  };

  // formData anunț
  const [formData, setFormData] = useState({
    title: "",
    location: "",
    price: 50,
    sector: "", // câmp nou pentru sector (doar pentru București)
    uid: "",
    validity: null, // va fi calculat automat
    taskDueDate: null, // noul câmp: data pentru realizarea sarcinii
    category: "",
    description: "",
    taskDueDate: null, // este ok null pentru start
    adId: null,
    estimatedDuration: "", // Adăugăm câmpul nou
    paymentType: "", // Adăugăm câmpul nou
    experienceLevel: "", // Adăugăm câmpul nou
    isUrgent: false, // Adăugăm câmpul nou
  });

  useEffect(() => {
    try {
      setForbiddenWordsList(forbiddenWordsData.forbiddenWords || []);
    } catch (error) {
      console.error("Error loading forbidden words:", error);
    }
  }, []);

  // --------------------------------------------------
  // Auth: onAuthStateChanged
  // --------------------------------------------------
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        try {
          // Preluăm documentul de utilizator din Firestore (dacă există)
          const userDocRef = doc(firestore, "users", currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          let uData = {};
          if (userDoc.exists()) {
            uData = userDoc.data();
          }

          // Verificare badge-uri
          if (uData.verified3) {
            setVerificationLevel(3);
          } else if (uData.verified2) {
            setVerificationLevel(2);
          } else if (uData.verified1) {
            setVerificationLevel(1);
          } else {
            setVerificationLevel(null);
          }
          // Preluăm și setăm câmpul verifiedUser
          setIsVerified(uData.verifiedUser === true);

          // Stabilim numărul de telefon: preferăm cel din Firestore, altfel cel din currentUser (sau șir gol)
          const phone = uData.phoneNumber || currentUser.phoneNumber || "";
          // Stabilim displayName și photoURL, folosind datele din Firestore dacă există
          const displayName =
            uData.displayName || currentUser.displayName || "User";
          const photoURL =
            uData.photoURL || currentUser.photoURL || "default-avatar-url";

          // Actualizează formData și profilePhoto
          setFormData((prev) => ({
            ...prev,
            uid: currentUser.uid,
            displayName,
            phoneNumber: phone,
            photoURL,
          }));
          setProfilePhoto(photoURL);

          // Dacă numărul de telefon NU este valid, deschidem modalul pentru actualizare;
          // dacă este valid, modalul rămâne închis.
          if (!isPhoneNumberValid(phone)) {
            setIsEditingName(true);
          } else {
            setIsEditingName(false);
          }
        } catch (error) {
          console.error("Error fetching user data from Firestore:", error);
        }
      } else {
        console.warn("No authenticated user.");
        setUser(null);
        setFormData((prev) => ({
          ...prev,
          uid: "",
          displayName: "User",
          phoneNumber: "",
          photoURL: "default-avatar-url",
        }));
        setProfilePhoto("default-avatar-url");
      }
    });

    return () => unsubscribe();
  }, []);
  // --------------------------------------------------
  // Check if user has phone
  // --------------------------------------------------

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (!currentUser) {
        setIsModalOpen(true); // Deschide modalul dacă utilizatorul nu este autentificat
      }
    });

    return () => unsubscribe(); // Cleanup listener
  }, []);

  // --------------------------------------------------
  // Forbidden Words Prevent
  // --------------------------------------------------

  const normalizeString = (str) =>
    str
      ?.normalize("NFD") // Normalize to decompose diacritic characters
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .toLowerCase();

  const containsForbiddenWords = (text, forbiddenWordsList) => {
    if (!forbiddenWordsList || forbiddenWordsList.length === 0) {
      console.error("Lista de cuvinte interzise este goală.");
      return false;
    }

    const normalizedText = normalizeString(text || "");
    const forbiddenWords = forbiddenWordsList.map(normalizeString);

    return forbiddenWords.some((word) => {
      const regex = new RegExp(`\\b${word}\\b`, "gi"); // Whole word match
      return regex.test(normalizedText);
    });
  };

  const fetchInProgressAnnouncements = useCallback(async () => {
    if (!uid) return;
    try {
      const announcementsRef = collection(firestore, "announcements");

      // Prima interogare: anunțurile cu status "inProgress" și unde uid-ul se regăsește în participants
      const q1 = query(
        announcementsRef,
        where("status", "==", "inProgress"),
        where("participants", "array-contains", uid)
      );

      // A doua interogare: anunțurile cu status "inProgress" și unde taskerUid este egal cu uid
      const q2 = query(
        announcementsRef,
        where("status", "==", "inProgress"),
        where("taskerUid", "==", uid)
      );

      const [snapshot1, snapshot2] = await Promise.all([
        getDocs(q1),
        getDocs(q2),
      ]);

      const announcements1 = snapshot1.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));
      const announcements2 = snapshot2.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));

      // Combinăm rezultatele, eliminând duplicatele
      const combined = [
        ...announcements1,
        ...announcements2.filter(
          (a2) => !announcements1.some((a1) => a1.id === a2.id)
        ),
      ];

      setAnnouncements(combined);
    } catch (error) {
      console.error("Error fetching announcements:", error);
    }
  }, [uid]);

  // --------------------------------------------------
  // handleSubmit
  // --------------------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();

    const forbiddenWordsList = forbiddenWordsData?.forbiddenWords || [];

    // Generate or reuse adId
    const adRef = formData.adId
      ? doc(firestore, "ads", formData.adId)
      : doc(collection(firestore, "ads"));
    const adId = formData.adId || adRef.id;

    // Validation checks …
    if (!formData.title.trim()) {
      toast.error("Titlul este obligatoriu!");
      return;
    }
    if (!formData.location.trim()) {
      toast.error("Locația este obligatorie!");
      return;
    }
    if (!formData.price || formData.price <= 0) {
      toast.error("Bugetul trebuie să fie minim 1!");
      return;
    }
    if (!formData.taskDueDate) {
      toast.error("Te rog să selectezi data pentru realizarea sarcinii.");
      return;
    }
    if (!formData.category) {
      toast.error("Vă rugăm să selectați o categorie!");
      return;
    }
    if (containsForbiddenWords(formData.title, forbiddenWordsList)) {
      toast.error("Titlul conține cuvinte interzise.");
      return;
    }
    if (containsForbiddenWords(formData.description, forbiddenWordsList)) {
      toast.error("Descrierea conține cuvinte interzise.");
      return;
    }
    if (!user?.uid) {
      toast.error(
        "Utilizator neautentificat. Încercați să vă conectați din nou."
      );
      return;
    }

    // Calculate validity date (30 days from now)
    const now = new Date();
    const validityDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);

    // --- New logic for location ---
    // Define the known Bucharest sectors.
    const bucharestSectors = [
      "Sector 1",
      "Sector 2",
      "Sector 3",
      "Sector 4",
      "Sector 5",
      "Sector 6",
    ];
    // Check if the selected location is one of the Bucharest sectors.
    const isBucharestSector = bucharestSectors.includes(formData.location);

    // Calculate if the ad should be flagged as urgent.
    // Here we compare the taskDueDate with the posting date (today)
    const taskDue = new Date(formData.taskDueDate);
    const diffDays = (taskDue - now) / (1000 * 60 * 60 * 24);
    const urgentFlag = diffDays <= 3; // If taskDueDate is 3 or fewer days away

    // Final ad object: if the location is a Bucharest sector,
    // set location to "București" and sector to the selected value.
    const finalAd = {
      ...formData,
      uid: user?.uid || formData.uid,
      adId,
      status: "posted",
      timestamp: Timestamp.now(),
      ownerCompleted: false,
      taskerCompleted: false,
      validity: Timestamp.fromDate(validityDate),
      taskDueDate: Timestamp.fromDate(formData.taskDueDate),
      estimatedDuration: formData.estimatedDuration,
      paymentType: formData.paymentType,
      experienceLevel: formData.experienceLevel,
      location: isBucharestSector ? "Bucuresti" : formData.location,
      sector: formData.location === "Bucuresti" ? formData.sector : "",
      isUrgent: urgentFlag,
    };

    // (Optional) Validate finalAd fields (logs warnings for null/undefined fields)
    Object.entries(finalAd).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        console.warn(`Field "${key}" is null or undefined.`);
      }
    });

    try {
      // Save finalAd to Firestore
      await setDoc(adRef, finalAd);
      setIsPostPopupOpen(true);
      setTimeout(() => {
        setIsPostPopupOpen(false);
      }, 5000);
      toast.success("Anunț creat cu succes!");

      // Reset formData after submission
      setFormData({
        title: "",
        location: "",
        uid: user?.uid || "",
        price: 50,
        validity: null,
        taskDueDate: null,
        category: "",
        description: "",
        adId: null,
        estimatedDuration: "",
        paymentType: "",
        experienceLevel: "",
        isUrgent: false,
      });
      setIsAddModalOpen(false);
      await fetchUserAds();
    } catch (error) {
      console.error("Eroare la crearea anunțului:", error);
      toast.error("Eroare la crearea anunțului.");
    }
  };

  // --------------------------------------------------
  // handleChange
  // --------------------------------------------------
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let updatedFormData = {
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    };

    if (name === "title") {
      // Detectăm ce categorie iese:
      const detectedCat = detectCategory(value);
      // Dacă e "Altele", lăsăm category = "" => user e forțat să aleagă manual
      if (detectedCat === "Altele") {
        updatedFormData.category = "";
        updatedFormData.description = "";
      } else {
        updatedFormData.category = detectedCat;
        updatedFormData.description = generateSuggestions(detectedCat);
      }
    }
    if (name === "price") {
      updatedFormData.price = value === "" ? "" : Number(value);
    }
    setFormData(updatedFormData);
  };

  // --------------------------------------------------
  // Preluare anunțuri postate ale userului
  // --------------------------------------------------
  const fetchUserAds = useCallback(async () => {
    if (!uid) return;
    try {
      const adsRef = collection(firestore, "ads");
      const q = query(
        adsRef,
        where("uid", "==", uid),
        where("status", "==", "posted")
      );
      const snapshot = await getDocs(q);
      setAds(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    } catch (error) {
      console.error("Error fetching ads:", error);
    }
  }, [uid]);

  // --------------------------------------------------
  // Preluare anunțuri inProgress
  // --------------------------------------------------
  const fetchInProgressAds = useCallback(async () => {
    if (!uid) {
      console.error("UID is undefined. Cannot fetch in-progress ads.");
      return;
    }
    try {
      const adsRef = collection(firestore, "ads");
      const q = query(
        adsRef,
        where("status", "==", "inProgress"),
        where("participants", "array-contains", uid)
      );
      const snapshot = await getDocs(q);

      const adsWithDetails = await Promise.all(
        snapshot.docs.map(async (docSnap) => {
          const adData = { id: docSnap.id, ...docSnap.data() };

          // Fetch owner details
          if (adData.uid) {
            const ownerDoc = await getDoc(doc(firestore, "users", adData.uid));
            if (ownerDoc.exists()) {
              const ownerData = ownerDoc.data();
              adData.ownerName = ownerData.displayName || "Unknown";
              adData.ownerPhone = ownerData.phoneNumber || "Not provided";
              adData.ownerEmail = ownerData.email || "Not provided";
            } else {
              console.warn(`Owner details not found for UID: ${adData.uid}`);
              adData.ownerName = "Unknown";
              adData.ownerPhone = "Not provided";
              adData.ownerEmail = "Not provided";
            }
          }

          // Fetch tasker details
          if (adData.taskerUid) {
            const taskerDoc = await getDoc(
              doc(firestore, "users", adData.taskerUid)
            );
            if (taskerDoc.exists()) {
              const taskerData = taskerDoc.data();
              adData.taskerName = taskerData.displayName || "Unknown";
              adData.taskerPhone = taskerData.phoneNumber || "Not provided";
              adData.taskerEmail = taskerData.email || "Not provided";
            } else {
              console.warn(
                `Tasker details not found for UID: ${adData.taskerUid}`
              );
              adData.taskerName = "Unknown";
              adData.taskerPhone = "Not provided";
              adData.taskerEmail = "Not provided";
            }
          }

          return adData;
        })
      );
      setInProgressAds(adsWithDetails);
    } catch (error) {
      console.error("Error fetching in-progress ads:", error);
    }
  }, [uid]);

  // TESTING

  useEffect(() => {
    const cachedAds = localStorage.getItem("inProgressAds");
    if (cachedAds) {
      setInProgressAds(JSON.parse(cachedAds));
    }
    fetchInProgressAds();
  }, [fetchInProgressAds]);

  useEffect(() => {
    if (inProgressAds.length > 0) {
      localStorage.setItem("inProgressAds", JSON.stringify(inProgressAds));
    }
  }, [inProgressAds]);

  // --------------------------------------------------
  // Notificări
  // --------------------------------------------------
  const fetchNotificationsAndFavorites = useCallback(async () => {
    if (!uid) return;

    try {
      // notificari standard
      const notificationsSnapshot = await getDocs(
        query(
          collection(firestore, "notifications"),
          where("adOwnerId", "==", uid),
          orderBy("createdAt", "desc")
        )
      );

      const notifications = notificationsSnapshot.docs.map((doc) => ({
        id: doc.id,
        notificationType: "standard",
        ...doc.data(),
      }));

      // favorite notifications (fără field `type`)
      const favNotifSnapshot = await getDocs(
        query(
          collection(firestore, "favoritesNotifications"),
          where("adOwnerId", "==", uid),
          orderBy("createdAt", "desc")
        )
      );

      const favoriteNotifications = favNotifSnapshot.docs.map((doc) => ({
        id: doc.id,
        notificationType: "favorite", // definim tipul explicit aici
        ...doc.data(),
      }));

      // combinare
      const combinedNotifications = [
        ...notifications,
        ...favoriteNotifications,
      ].sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());

      setNotifications(combinedNotifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }, [uid]);

  useEffect(() => {
    fetchNotificationsAndFavorites();
  }, [fetchNotificationsAndFavorites]);

  // Preluăm ads user + setăm categorii sortate
  useEffect(() => {
    fetchUserAds();
    const sortedCategories = [...categoriesData.categories].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setCategories(sortedCategories);
  }, [fetchUserAds]);

  // --------------------------------------------------
  // handleOpenAddModal
  // --------------------------------------------------
  const handleOpenAddModal = () => {
    const adRef = doc(collection(firestore, "ads")); // creates a ref with a unique ID
    setFormData({
      title: "",
      location: "",
      price: 50,
      validity: null,
      category: "",
      description: "",
      sector: "",
      adId: adRef.id, // set adId here
      uid: user?.uid || "",
    });

    setIsAddModalOpen(true);
    setIsDraftsModalOpen(false); // close drafts modal if open
  };

  // --------------------------------------------------
  // favorite ads
  // --------------------------------------------------
  const fetchFavoriteAds = useCallback(async () => {
    if (!uid) return;
    try {
      const favoritesRef = collection(firestore, "favorites");
      const qFav = query(favoritesRef, where("userId", "==", uid));
      const snapshot = await getDocs(qFav);

      const adsPromises = snapshot.docs.map(async (favDoc) => {
        const adRef = doc(firestore, "ads", favDoc.data().adId);
        const adSnapshot = await getDoc(adRef);
        if (!adSnapshot.exists()) return null;
        return { id: adSnapshot.id, ...adSnapshot.data() };
      });

      const foundAds = (await Promise.all(adsPromises)).filter(
        (ad) => ad !== null
      );
      setFavoriteAds(foundAds);
    } catch (error) {
      console.error("Error fetching favorite ads:", error);
    }
  }, [uid]);

  useEffect(() => {
    if (uid) {
      fetchFavoriteAds();
    }
  }, [uid, fetchFavoriteAds]);

  const handleDeleteFavorite = async (adId) => {
    try {
      const favoriteRef = doc(firestore, "favorites", `${uid}_${adId}`);
      await deleteDoc(favoriteRef);
      setFavoriteAds((prev) => prev.filter((ad) => ad.id !== adId));
      toast.success("Anunțul a fost șters din favorite!");
    } catch (error) {
      console.error("Error deleting favorite:", error);
      toast.error("Eroare la ștergerea anunțului din favorite.");
    }
  };

  const handleOpenModal = (ad) => {
    setSelectedAd(ad);
    setIsModalDetailsOpen(true);
  };
  // --------------------------------------------------
  // handleNameEdit + handleSaveUserInfo
  // --------------------------------------------------
  const handleNameEdit = async () => {
    const effectiveUid = uid || user?.uid;
    if (!effectiveUid) {
      toast.error("User ID is missing. Cannot fetch data.");
      return;
    }
    try {
      const userRef = doc(firestore, "users", effectiveUid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setFormData({
          displayName: userData.displayName || "",
          email: userData.email || "",
          phone: userData.phoneNumber || "", // Ensure this is included
        });
        setIsEditingName(true);
      } else {
        toast.error("User data not found.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data.");
    }
  };

  const handleSaveUserInfo = async () => {
    if (!formData.phone || formData.phone.length < 9) {
      toast.error("Numărul de telefon trebuie să fie valid!");
      return;
    }

    try {
      const userRef = doc(firestore, "users", user.uid);
      await updateDoc(userRef, { phoneNumber: formData.phone });
      toast.success("Numărul de telefon a fost salvat!");
      setIsEditingName(false);
    } catch (error) {
      console.error("Eroare la salvarea numărului de telefon:", error);
      toast.error("Nu am reușit să salvăm numărul de telefon.");
    }
  };

  const handleConfirmSave = () => {
    const userConfirmed = window.confirm(
      "Ești sigur că vrei să salvezi modificările?"
    );
    if (userConfirmed) {
      handleSaveUserInfo();
    }
  };

  // --------------------------------------------------
  // handleDeleteAd
  // --------------------------------------------------
  const [adToDelete, setAdToDelete] = useState(null);
  const confirmDeleteAd = (ad) => {
    setAdToDelete(ad);
    setIsDeleteModalOpen(true);
  };
  const closeDeleteModal = () => setIsDeleteModalOpen(false);

  const handleDeleteAd = async () => {
    if (!adToDelete) return;
    try {
      // Creează un obiect care include datele anunțului și un timestamp pentru ștergere
      const deletedAdData = {
        ...adToDelete,
        status: "deleted", // Actualizează status-ul la "deleted"
        deletedAt: Timestamp.now(),
      };

      // Mută anunțul în colecția "deletedAds"
      await setDoc(doc(firestore, "deletedAds", adToDelete.id), deletedAdData);

      // Șterge anunțul din colecția originală "ads"
      await deleteDoc(doc(firestore, "ads", adToDelete.id));

      toast.success(
        "Anunțul a fost mutat în deletedAds și va fi șters definitiv după 30 de zile."
      );
      setAds((prev) => prev.filter((x) => x.id !== adToDelete.id));
      setIsDeleteModalOpen(false);
    } catch (error) {
      toast.error("Eroare la mutarea anunțului în deletedAds.");
      console.error(error);
    }
  };

  // --------------------------------------------------
  // handleEdit
  // --------------------------------------------------
  const setCurrentAdId = (id) => {
    const adToEdit = ads.find((ad) => ad.id === id);
    if (adToEdit) {
      setFormData({
        ...adToEdit,
        sector: adToEdit.sector || "", // Asigură-te că există proprietatea sector
        isUrgent: adToEdit.isUrgent ?? false, // Ensure default value
      });
      setIsEditModalOpen(true);
    }
  };

  // --------------------------------------------------
  // In-progress logic
  // --------------------------------------------------
  const handleMarkAsCompleted = async (ad, role) => {
    try {
      const adRef = doc(firestore, "ads", ad.id);
      const updates =
        role === "owner" ? { ownerCompleted: true } : { taskerCompleted: true };
      await updateDoc(adRef, updates);

      const updatedAdSnap = await getDoc(adRef);
      const updatedAd = updatedAdSnap.data();

      setCurrentAd(ad);
      setCurrentRole(role);
      setReviewData({
        rating: 0,
        review: "",
      });
      setIsDoneModalOpen(true);

      if (updatedAd.ownerCompleted && updatedAd.taskerCompleted) {
        await updateDoc(adRef, { status: "finalized" });
        toast.success("Task-ul a fost finalizat complet!");
      } else {
        toast.success(
          `Task-ul a fost marcat ca finalizat de ${
            role === "owner" ? "Owner" : "Tasker"
          }.`
        );
      }
      fetchInProgressAds();
    } catch (error) {
      console.error("Eroare la marcarea ca finalizat:", error);
      toast.error("Eroare la marcarea task-ului ca finalizat.");
    }
  };

  // BUSINESS

  const handleMarkAnnouncementAsCompleted = async (announcement) => {
    try {
      const announcementRef = doc(firestore, "announcements", announcement.id);
      // Determinăm rolul curent: dacă businessId este egal cu uid,
      // atunci utilizatorul este owner, altfel este tasker.
      let updates = {};
      if (announcement.businessId === uid) {
        updates.ownerCompleted = true;
      } else {
        updates.taskerCompleted = true;
      }

      await updateDoc(announcementRef, updates);

      // Preluăm documentul actualizat
      const updatedSnap = await getDoc(announcementRef);
      const updatedData = updatedSnap.data();

      setCurrentAd(announcement);
      setReviewData({ rating: 0, review: "" });
      setIsDoneModalOpen(true);

      // Dacă ambele părți au confirmat finalizarea, actualizăm status‑ul
      if (updatedData.ownerCompleted && updatedData.taskerCompleted) {
        await updateDoc(announcementRef, { status: "finalized" });
        alert("Task-ul a fost finalizat complet!");
      } else {
        alert("Task-ul a fost marcat ca finalizat de o parte.");
      }
    } catch (error) {
      console.error("Eroare la finalizarea anunțului:", error);
    }
  };

  // BUSINESS UNDONE

  // Funcție pentru deschiderea modalului de task neterminat
  const handleOpenTaskUndoneModal = (announcement) => {
    setCurrentTaskAnnouncement(announcement);
    setTaskUndoneReason("");
    setIsTaskUndoneModalOpen(true);
  };

  // Funcție pentru trimiterea motivului de task neterminat pentru anunțuri
  const handleSubmitTaskUndone = async () => {
    if (!taskUndoneReason.trim()) {
      toast.error("Te rog să introduci un motiv pentru task-ul neterminat.");
      return;
    }
    try {
      const announcementRef = doc(
        firestore,
        "announcements",
        currentTaskAnnouncement.id
      );
      // Get current participants array (or an empty array if missing)
      const participants = currentTaskAnnouncement.participants || [];
      // Assume the owner's UID is stored in announcement.ownerUid
      const ownerUid = currentTaskAnnouncement.ownerUid;

      // Remove the current user's UID from participants
      const newParticipants = participants.filter((uid) => uid !== user.uid);

      // Check if there are any non-owner participants left
      const nonOwnerParticipants = newParticipants.filter(
        (uid) => uid !== ownerUid
      );

      if (nonOwnerParticipants.length === 0) {
        // If no non-owner participants remain, update status to "posted"
        await updateDoc(announcementRef, {
          status: "posted",
          undoneReason: taskUndoneReason,
          // Save the updated participants list
          participants: newParticipants,
          // If current user was assigned as tasker, clear it
          taskerUid:
            currentTaskAnnouncement.taskerUid === user.uid
              ? null
              : currentTaskAnnouncement.taskerUid,
        });
        toast.success(
          "Anunțul a fost readus la 'posted' deoarece nu mai există taskeri activi."
        );
      } else {
        // Otherwise, update only the undoneReason and remove the current user from participants
        await updateDoc(announcementRef, {
          undoneReason: taskUndoneReason,
          participants: newParticipants,
          taskerUid:
            currentTaskAnnouncement.taskerUid === user.uid
              ? null
              : currentTaskAnnouncement.taskerUid,
        });
        toast.success(
          "Motivul a fost salvat și ai fost eliminat din participanți."
        );
      }
      setIsTaskUndoneModalOpen(false);
      fetchInProgressAds();
    } catch (error) {
      console.error("Eroare la salvarea motivului:", error);
      toast.error("Eroare la salvarea motivului!");
    }
  };
  // --------------------------------------------------
  // handleSubmitReview
  // --------------------------------------------------
  const handleSubmitReview = async (ad, role) => {
    const { rating, review } = reviewData;

    if (!rating || !review || review.trim() === "") {
      toast.error("Toate câmpurile sunt obligatorii!");
      return;
    }

    try {
      let forName = role === "owner" ? ad.taskerName : ad.ownerName;
      let forUid = role === "owner" ? ad.taskerUid : ad.uid;

      // Dacă `forName` sau `forUid` sunt undefined, încearcă să le obții din Firestore
      if (!forName || !forUid) {
        const userUid = role === "owner" ? ad.taskerUid : ad.uid;
        const userDoc = await getDoc(doc(firestore, "users", userUid));

        if (userDoc.exists()) {
          const userData = userDoc.data();
          forName = forName || userData.displayName || "Anonim";
          forUid = forUid || userUid;
        } else {
          console.warn(`User document not found for UID: ${userUid}`);
          forName = forName || "Anonim";
          forUid = forUid || "unknown";
        }
      }

      const reviewDataToSave = {
        adId: ad.id,
        reviewerUid: user?.uid,
        reviewerName: user?.displayName || "Anonim",
        forUid,
        forName, // Folosește valoarea obținută sau fallback-ul
        role,
        rating,
        review,
        timestamp: Timestamp.now(),
      };

      // Verificare de siguranță:
      if (!reviewDataToSave.forUid || !reviewDataToSave.forName) {
        console.error(
          "Lipsesc datele necesare pentru recenzie:",
          reviewDataToSave
        );
        toast.error("Nu se pot trimite recenziile fără date valide.");
        return;
      }

      await addDoc(collection(firestore, "reviews"), reviewDataToSave);
      toast.success("Recenzie trimisă cu succes!");
      fetchCompletedAds();
      handleCloseModals();
    } catch (error) {
      console.error("Eroare la trimiterea recenziei:", error);
      toast.error("Eroare la trimiterea recenziei!");
    }
  };

  const hasPartialReview = () => {
    return reviewData.rating !== null || reviewData.review.trim() !== "";
  };

  // --------------------------------------------------
  // handleSubmitUndone
  // --------------------------------------------------
  const handleSubmitUndone = async (ad) => {
    if (!undoneOption) {
      toast.error("Selectează o opțiune din dropdown!");
      return;
    }
    if (!undoneReason.trim()) {
      toast.error("Trebuie să completezi motivul!");
      return;
    }
    try {
      const adRef = doc(firestore, "ads", ad.id);
      if (undoneOption === "notWanted") {
        // Dacă nu se mai dorește, readucem ad-ul la posted
        await updateDoc(adRef, {
          status: "posted",
          undoneReason, // păstrăm motivul pentru referință
        });
        toast.success("Ad-ul a fost readus la posted!");
      } else if (undoneOption === "notCompleted") {
        // Întrebăm direct dacă dorește readucerea sau ștergerea ad-ului
        const readuceOnline = window.confirm(
          "Dorești să readuci ad-ul online?\n\nApasă OK pentru readucere la posted, sau Anulează pentru a șterge ad-ul."
        );
        if (readuceOnline) {
          await updateDoc(adRef, {
            status: "posted",
            undoneReason,
          });
          toast.success("Ad-ul a fost readus la posted!");
        } else {
          await deleteDoc(adRef);
          toast.success("Ad-ul a fost șters!");
        }
      }
      // Închidem modalul de undone
      handleCloseModals();
      // Actualizăm lista de ad-uri în curs
      fetchInProgressAds();
    } catch (error) {
      console.error("Eroare la trimiterea motivului:", error);
      toast.error("Eroare la trimiterea motivului!");
    }
  };

  // --------------------------------------------------
  // handleOpenDoneModal & Undone
  // --------------------------------------------------
  // eslint-disable-next-line
  const handleOpenDoneModal = (ad) => {
    setCurrentAd(ad);
    setIsDoneModalOpen(true);
  };
  const handleOpenUndoneModal = (ad) => {
    setCurrentAd(ad);
    setIsUndoneModalOpen(true);
  };
  const handleCloseModals = () => {
    // Dacă e deschis modalul pentru review și s-a completat parțial:
    if (
      isDoneModalOpen &&
      (reviewData.rating !== null || reviewData.review.trim() !== "")
    ) {
      const confirmClose = window.confirm(
        "Ești sigur că vrei să închizi? Recenzia nu va fi salvată."
      );
      if (!confirmClose) return;
    }

    setIsDoneModalOpen(false);
    setIsUndoneModalOpen(false);

    // Resetăm recenzia DOAR dacă era vorba de modalul de Done
    if (isDoneModalOpen) {
      setReviewData({ rating: null, review: "" });
    }
  };

  // --------------------------------------------------
  // Notifications
  // --------------------------------------------------

  const openNotificationModal = () => {
    setIsNotificationModalOpen(true);
    fetchNotificationsAndFavorites();
    markAllNotificationsAsRead();
  };
  const markAllNotificationsAsRead = async () => {
    try {
      if (!uid) return;
      const notificationsRef = collection(firestore, "notifications");
      const snapshot = await getDocs(
        query(
          notificationsRef,
          where("recipientUid", "==", uid),
          where("isRead", "==", false)
        )
      );
      const updatePromises = snapshot.docs.map((docu) =>
        updateDoc(docu.ref, { isRead: true })
      );
      await Promise.all(updatePromises);
      toast.success("Toate notificările au fost marcate ca citite!");
    } catch (error) {
      console.error("Eroare la marcarea notificărilor:", error);
      toast.error("Eroare la marcarea notificărilor.");
    }
  };

  // --------------------------------------------------
  // handleNotificationAction (finalAccept, decline, etc.)
  // --------------------------------------------------
  // const handleNotificationAction = async (notification, action) => {
  //   try {
  //     const notificationRef = doc(firestore, "notifications", notification.id);
  //     const adRef = doc(firestore, "ads", notification.adId);

  //     if (action === "decline") {
  //       // Logica pentru respingerea notificării
  //       await updateDoc(notificationRef, {
  //         status: "declined",
  //         isDeleted: true,
  //       });
  //       await updateDoc(adRef, { status: "posted" });
  //       toast.success("Notificarea a fost respinsă și anunțul a fost resetat!");
  //     } else if (action === "finalize") {
  //       // Logica pentru finalizarea anunțului
  //       await updateDoc(adRef, { status: "finalized" });
  //       toast.success("Anunțul a fost marcat ca finalizat cu succes!");
  //     } else if (action === "notFinalize") {
  //       // Logica pentru marcarea anunțului ca neterminat
  //       await updateDoc(adRef, { status: "notFinalized" });
  //       toast.success("Anunțul a fost marcat ca neterminat cu succes!");
  //     }
  //   } catch (error) {
  //     console.error("Eroare la procesarea notificării:", error);
  //     toast.error("Eroare la procesarea notificării.");
  //   }
  // };

  // --------------------------------------------------
  // Completed Ads
  // --------------------------------------------------
  const fetchReviewsForAd = useCallback(async (adId) => {
    try {
      const reviewsRef = collection(firestore, "reviews");
      const qReviews = query(reviewsRef, where("adId", "==", adId));
      const snapshot = await getDocs(qReviews);

      const reviews = snapshot.docs.map((doc) => doc.data());
      return reviews;
    } catch (error) {
      console.error("Eroare la aducerea recenziilor:", error);
      return [];
    }
  }, []);

  const fetchCompletedAds = useCallback(async () => {
    if (!uid) return;
    try {
      const adsRef = collection(firestore, "ads");
      const qCompleted = query(
        adsRef,
        where("status", "in", ["finalized", "failed"]),
        where("participants", "array-contains", uid)
      );
      const snapshot = await getDocs(qCompleted);

      const adsWithReviews = await Promise.all(
        snapshot.docs.map(async (docSnap) => {
          const adData = { id: docSnap.id, ...docSnap.data() };
          const reviews = await fetchReviewsForAd(docSnap.id);
          return { ...adData, reviews };
        })
      );
      setCompletedAds(adsWithReviews);
    } catch (error) {
      console.error("Error fetching completed ads:", error);
    }
  }, [uid, fetchReviewsForAd]);

  useEffect(() => {
    fetchCompletedAds();
  }, [fetchCompletedAds]);

  // --------------------------------------------------
  // Helpers UI
  // --------------------------------------------------
  const getFormattedDate = (timestamp) => {
    if (!timestamp) return "Data indisponibilă";
    const date = new Date(
      timestamp.seconds ? timestamp.seconds * 1000 : timestamp
    );
    return date.toLocaleDateString("ro-RO", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };

  // Filtru
  const filteredAds = ads.filter((ad) => {
    const matchesTitle = ad.title
      .toLowerCase()
      .includes(searchTitle.toLowerCase());
    const matchesCategory = !searchCategory || ad.category === searchCategory;
    return matchesTitle && matchesCategory;
  });

  // SALUTATION

  // Mesaje de întâmpinare aleatorii
  const greetings = [
    "Sper să ai o zi productivă! 🚀",
    "Azi e o zi excelentă pentru noi oportunități! 💡",
    "Ce planuri ai pentru azi? 😊",
    "Succes în tot ce faci astăzi! 🌟",
    "Fiecare zi e o șansă nouă! Profită de ea! 💪",
    "Bine ai revenit! Hai să facem lucruri grozave azi! 🔥",
    "Să ai o zi plină de energie și reușite! 💙",
    "Hai să transformăm task-urile în succese! 🏆",
    "Fă primul pas către realizarea obiectivelor tale azi! 🚀",
    "Organizează-ți ziua și abordează fiecare sarcină cu încredere! 📋",
    "Fiecare task te aduce mai aproape de succes! Să începem! 🎯",
    "Bun venit în ziua în care îți atingi toate obiectivele! 💼",
    "Răbdarea și determinarea sunt cheia unei zile de productivitate! 🔑",
    "În fiecare task ascundem oportunități noi de a crește! 🌱",
    // Mesaje suplimentare, mai specifice:
    "Bine ai venit! Astăzi e momentul să îți atingi potențialul maxim! 💪",
    "Fiecare pas mic te apropie de un mare succes. Să începem! 🌟",
    "Începe ziua cu un scop clar și o inimă plină de pasiune! ❤️",
    "Fiecare sarcină este o oportunitate de a învăța ceva nou! 📚",
    "Ai tot ce îți trebuie pentru a reuși. Să facem astăzi ceva remarcabil! 🚀",
    "Concentrează-te pe obiectivele tale și transformă-le în realitate! 🎯",
    "Astăzi este ziua ta de glorie – demonstrează-ți puterea! ⚡",
    "Cu fiecare task îți clădești viitorul. Hai să construim succesul împreună! 🏗️",
    "Fiecare idee are potențialul de a schimba lumea. Să începi cu astăzi! 💡",
    "Fii inspirat, fii motivat, fii învingător! Astăzi e ziua ta! 🏆",
  ];

  // Alegem un mesaj aleatoriu
  const randomGreeting = useRef(
    greetings[Math.floor(Math.random() * greetings.length)]
  ).current;

  // EMAIL TO TASKER FINAL APPROVAL

  // const sendTaskAcceptedEmail = (taskerEmail, taskDetails) => {
  //   if (!taskerEmail) {
  //     console.error("Email-ul tasker-ului este indisponibil.");
  //     return;
  //   }

  //   const templateParams = {
  //     tasker_email: taskerEmail,
  //     task_title: taskDetails.title,
  //     task_location: taskDetails.location,
  //     owner_name: taskDetails.ownerName,
  //     owner_phone: taskDetails.ownerPhone || "Indisponibil",
  //     owner_email: taskDetails.ownerEmail || "Indisponibil",
  //     task_url: `https://aidly.ro/task/${taskDetails.id}`, // Link către task
  //   };

  //   emailjs
  //     .send(
  //       "service_exj3u5d", // ID-ul serviciului EmailJS
  //       "template_mailtotasker", // ID-ul template-ului EmailJS
  //       templateParams,
  //       "8P20l58Dg2sQRxHiK" // User ID-ul EmailJS
  //     )
  //     .then((response) => {
  //       console.log("Email trimis cu succes!", response);
  //     })
  //     .catch((error) => {
  //       console.error("Eroare la trimiterea email-ului:", error);
  //     });
  // };

  const getFormattedDateTime = (timestamp) => {
    if (!timestamp || !timestamp.seconds) return "Data indisponibilă";

    const date = new Date(timestamp.seconds * 1000); // Convertim Firestore timestamp în milisecunde

    return date.toLocaleString("ro-RO", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  // PHONE CHECK

  const isPhoneNumberValid = (phone) => {
    if (!phone) return false;
    const invalidValues = ["not provided", "n/a", "null"];
    // Comparăm în mod case-insensitive
    if (invalidValues.includes(phone.toLowerCase())) return false;
    // Verificăm dacă numărul conține cel puțin 9 cifre (ignorând caracterele non-cifre)
    if (phone.replace(/\D/g, "").length < 9) return false;
    return true;
  };

  const standardizeWhatsAppNumber = (phoneNumber) => {
    if (!phoneNumber) return "";

    // Remove all non-numeric characters
    const cleanedNumber = phoneNumber.replace(/\D/g, "");

    // Ensure the number starts with a '+' and has the correct length
    if (cleanedNumber.startsWith("0")) {
      return `+4${cleanedNumber}`; // Assuming Romanian numbers start with +40
    } else if (cleanedNumber.startsWith("40")) {
      return `+${cleanedNumber}`;
    } else if (cleanedNumber.startsWith("+40")) {
      return cleanedNumber;
    } else {
      return `+40${cleanedNumber}`; // Default to Romanian numbers
    }
  };

  useEffect(() => {
    if (uid) {
      fetchUserAds();
      fetchInProgressAds();
      fetchInProgressAnnouncements();
    }
  }, [uid, fetchUserAds, fetchInProgressAds, fetchInProgressAnnouncements]);

  // Calculăm validitatea: 30 de zile de la momentul actual
  const now = new Date();
  const validityDate = new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);

  const handleOpenTaskerModal = () => {
    setIsTaskerModalOpen(true);
  };

  const handleCloseTaskerModal = () => {
    setIsTaskerModalOpen(false);
  };

  useEffect(() => {
    const checkIfUserIsTasker = async () => {
      if (user?.uid) {
        const q = query(
          collection(firestore, "taskers"),
          where("uid", "==", user.uid)
        );
        const snapshot = await getDocs(q);
        setIsTasker(!snapshot.empty);
      }
    };

    checkIfUserIsTasker();
  }, [user]); // important: dependință user.uid

  // DRAFT MANAGEMENT

  const requiredFields = [
    "title",
    "description",
    "category",
    "location",
    "price",
    "validity",
  ]; // câmpuri obligatorii

  const handlePostDraft = async (draft) => {
    const emptyFields = requiredFields.filter((field) => !draft[field]);

    if (emptyFields.length > 0) {
      toast.warn("Completează toate câmpurile înainte de publicare.");
      setFormData(draft); // presupunând că formData controlează conținutul modalului
      setIsDraftsModalOpen(false); // închide modalul de draft-uri
      setIsAddModalOpen(true); // deschide modalul pentru editarea anunțului
      return;
    }

    try {
      await updateDoc(doc(firestore, "ads", draft.id), {
        status: "posted",
        createdAt: serverTimestamp(),
      });
      toast.success("Anunțul a fost publicat!");
      setIsDraftsModalOpen(false);
    } catch (error) {
      console.error("Eroare la publicarea anunțului:", error);
      toast.error("Eroare la publicarea anunțului.");
    }
  };

  const handleSaveDraft = async () => {
    if (!formData.adId) {
      console.warn("No adId set for draft. Cannot save.");
      return;
    }

    const adRef = doc(firestore, "ads", formData.adId);

    try {
      await setDoc(adRef, {
        ...formData,
        status: "draft",
        uid: user.uid,
        createdAt: serverTimestamp(),
      });
      toast.success("Anunțul a fost salvat ca schiță!");
    } catch (error) {
      console.error("Eroare la salvarea schiței:", error);
      toast.error("Eroare la salvarea schiței.");
    }
  };

  const handleEditDraft = (draft) => {
    // Închide modalul cu schițele
    setIsDraftsModalOpen(false);
    setFormData({
      ...draft,
      adId: draft.id, // sau draft.adId, în funcție de cum l-ai salvat
      taskDueDate: draft.taskDueDate?.toDate?.() || null, // dacă e timestamp
    });
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    const hasUnsavedData = Object.values(formData).some((val) => val);
    if (hasUnsavedData) {
      const confirmSave = window.confirm(
        "Vrei să salvezi anunțul ca schiță înainte de a închide?"
      );
      if (confirmSave) {
        handleSaveDraft(); // saves with status: 'draft'
      }
    }
    setIsAddModalOpen(false);
  };

  useEffect(() => {
    const fetchDraftAds = async () => {
      if (!uid) return;

      try {
        const q = query(
          collection(firestore, "ads"),
          where("uid", "==", uid),
          where("status", "==", "draft")
        );

        const snapshot = await getDocs(q);
        const drafts = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDraftAds(drafts);
      } catch (error) {
        console.error("Eroare la preluarea drafturilor:", error);
      }
    };

    fetchDraftAds();
  }, [uid]);

  const handleDeleteDraft = async (draftId) => {
    try {
      await deleteDoc(doc(firestore, "ads", draftId));
      toast.success("Schița a fost ștearsă!");
      setDraftAds((prev) => prev.filter((draft) => draft.id !== draftId));
    } catch (error) {
      console.error("Eroare la ștergerea schiței:", error);
      toast.error("Eroare la ștergerea schiței.");
    }
  };

  return (
    <div className="dashboard-container">
      {/* Sidebar */}
      <div className="sidebar">
        <div className="content-sidebar">
          {" "}
          <div className="image-name-section">
            <img
              src={profilePhoto}
              alt="User Profile"
              onError={(e) => (e.target.src = ProfileImage1)}
              className="profile-image"
            />

            {isTasker && (
              <span className="badge-team-tasker">
                <img
                  src="https://i.imgur.com/3gyHRkI.png"
                  className="icon-badge"
                  alt="team tasker aidly.ro badge"
                />
              </span>
            )}

            {/* {isVerified && (
              <span className="badge-verified-profile">
                <img
                  src={VerifiedBadge}
                  className="icon-badge"
                  alt="Utilizator verificat"
                  title="Utilizator verificat"
                />
              </span>
            )} */}

            <div className="profile-image-container">
              <img
                src={profilePhoto}
                alt="User Profile"
                onError={(e) => (e.target.src = ProfileImage1)}
                className="profile-image"
              />
            </div>
          </div>
          <div className="buttons-navbar">
            {" "}
            <button onClick={handleNameEdit} className="notifications-button">
              ✏️ Editează profil
            </button>
            <button
              onClick={() => {
                openNotificationModal();
              }}
              className={`notifications-button ${
                unreadCount > 0 ? "pulse-effect" : ""
              }`}>
              🔔 Notificări {unreadCount > 0 && `(${unreadCount})`}
            </button>
            <button
              onClick={() => setIsDraftsModalOpen(true)}
              className="notifications-button"
              disabled={draftAds.length === 0}
              style={{
                opacity: draftAds.length === 0 ? 0.5 : 1,
                cursor: draftAds.length === 0 ? "not-allowed" : "pointer",
              }}>
              📝 Schițele Mele {draftAds.length > 0 && `(${draftAds.length})`}
            </button>
            {verificationLevel === 1 && (
              <div className="badge-verificare badge-nivel1">
                ✅ Verificat - Nivel 1 Securitate
              </div>
            )}
            {verificationLevel === 2 && (
              <div className="badge-verificare badge-nivel2">
                🛡️ Verificat - Nivel 2 Identitate
              </div>
            )}
            {verificationLevel === 3 && (
              <div className="badge-verificare badge-nivel3">
                🔒 Verificat - Nivel 3 Documente
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="main-content-profile">
        <div className="salutation-container">
          <h2 className="salutation-text">
            Salut, {user?.displayName || "Bun venit"}! {randomGreeting}
          </h2>
        </div>

        {/* Modal Notificări */}
        <Modal
          isOpen={isNotificationModalOpen}
          onRequestClose={() => setIsNotificationModalOpen(false)}
          className="react-modal-content"
          overlayClassName="react-modal-overlay">
          <h2>Notificările Tale</h2>
          {notifications?.length > 0 ? (
            <>
              <ul className="notification-list">
                {notifications.map((notification) => (
                  <li key={notification.id} className="notification-item">
                    <div className="notification-content">
                      {notification.type === "favorite" ? (
                        <>
                          <p>
                            ❤️{" "}
                            <strong>{notification.favoritedByUserName}</strong>{" "}
                            a adăugat la favorite anunțul tău{" "}
                            <strong>{notification.adTitle}</strong>.
                          </p>
                          <p className="notification-date">
                            {getFormattedDate(notification.createdAt)}
                          </p>
                        </>
                      ) : notification.message ? (
                        <>
                          <p>{notification.message}</p>
                          <p className="notification-date">
                            {getFormattedDate(notification.timestamp)}
                          </p>
                        </>
                      ) : (
                        <>
                          <p>🔔 Ai o notificare nouă.</p>
                          <p className="notification-date">
                            {getFormattedDate(
                              notification.createdAt || notification.timestamp
                            )}
                          </p>
                        </>
                      )}
                    </div>

                    <div className="notification-actions">
                      {notification.status === "deleted" && (
                        <div className="deleted-ad-notification">
                          <button
                            className="contact-support-button"
                            onClick={() =>
                              (window.location.href =
                                "mailto:contact@aidly.ro?subject=Întrebare%20despre%20anunț%20șters")
                            }>
                            📩 Contact Suport
                          </button>
                          <button
                            className="delete-notification-button"
                            onClick={async () => {
                              try {
                                const collectionName =
                                  notification.type === "favoriteNotification"
                                    ? "favoritesNotifications"
                                    : "notifications";

                                await updateDoc(
                                  doc(
                                    firestore,
                                    collectionName,
                                    notification.id
                                  ),
                                  {
                                    isDeleted: true,
                                    isRead: true,
                                  }
                                );

                                setNotifications((prev) =>
                                  prev.map((n) =>
                                    n.id === notification.id
                                      ? { ...n, isDeleted: true, isRead: true }
                                      : n
                                  )
                                );

                                toast.success(
                                  "Notificarea a fost marcată ca ștearsă!"
                                );
                              } catch (error) {
                                console.error(
                                  "Eroare la marcarea notificării ca ștearsă:",
                                  error
                                );
                                toast.error(
                                  "Eroare la marcarea notificării ca ștearsă."
                                );
                              }
                            }}>
                            🗑️ Șterge Notificare
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>

              <button
                className="delete-all-button"
                onClick={async () => {
                  try {
                    const updatePromises = notifications.map((n) => {
                      const collectionName =
                        n.type === "favoriteNotification"
                          ? "favoritesNotifications"
                          : "notifications";
                      return updateDoc(doc(firestore, collectionName, n.id), {
                        isDeleted: true,
                        isRead: true,
                      });
                    });

                    await Promise.all(updatePromises);

                    setNotifications((prev) =>
                      prev.map((n) => ({ ...n, isDeleted: true, isRead: true }))
                    );

                    toast.success(
                      "Toate notificările au fost marcate ca șterse!"
                    );
                  } catch (error) {
                    console.error(
                      "Eroare la marcarea notificărilor ca șterse:",
                      error
                    );
                    toast.error("Eroare la marcarea notificărilor ca șterse.");
                  }
                }}>
                Șterge toate notificările
              </button>
            </>
          ) : (
            <p>Nu ai notificări.</p>
          )}
        </Modal>

        {/* Ads Section */}
        <section id="ads" className="ads-section-profile">
          <div className="top-line-profile-buttons">
            <button
              type="button"
              className="add-ad-btn"
              onClick={handleOpenAddModal}>
              Postează în mai puțin de 1 minut!
            </button>
            {!isTasker && (
              <button
                type="button"
                className="tasker-button"
                onClick={handleOpenTaskerModal}>
                🛠️ Devino Tasker!
              </button>
            )}
          </div>
          {/* Modal Adăugare Anunț */}
          <Modal
            isOpen={isAddModalOpen}
            onRequestClose={handleCloseAddModal}
            className="modal-add-ad-container"
            overlayClassName="modal-overlay">
            <h2 className="modal-title">Adaugă Anunț</h2>
            <form className="modal-add-ad-form" onSubmit={handleSubmit}>
              {/* Titlu anunț */}
              <div className="form-group">
                <label htmlFor="title" className="form-label">
                  Titlu anunț
                  <FaInfoCircle
                    className="info-icon"
                    onClick={() => togglePopup("title")}
                  />
                </label>
                {infoPopup === "title" && (
                  <div className="info-popup">
                    Alege un titlu clar și relevant pentru anunțul tău.
                  </div>
                )}
                <input
                  type="text"
                  id="title"
                  className="form-input"
                  name="title"
                  placeholder="Introdu titlul anunțului"
                  value={formData.title || ""}
                  onChange={handleChange}
                />
              </div>

              {/* Descriere */}
              <div className="form-group">
                <label htmlFor="description" className="form-label">
                  Descriere
                  <FaInfoCircle
                    className="info-icon"
                    onClick={() => togglePopup("description")}
                  />
                </label>
                {infoPopup === "description" && (
                  <div className="info-popup">
                    Descrie detaliat ce ai nevoie, astfel încât să atragi
                    ajutorul potrivit.
                  </div>
                )}
                <textarea
                  id="description"
                  name="description"
                  className="form-input"
                  placeholder="Detaliază cerințele..."
                  value={formData.description || ""}
                  onChange={handleChange}
                  rows="4"></textarea>
              </div>

              {/* Locație */}
              <div className="inline-double">
                {/* Locație */}
                <div className="form-group">
                  <label htmlFor="location" className="form-label">
                    Locație
                    <FaInfoCircle onClick={() => togglePopup("location")} />
                  </label>
                  {infoPopup === "location" && (
                    <div className="info-popup">
                      Selectează locația unde ai nevoie de ajutor.
                    </div>
                  )}
                  <select
                    id="location"
                    name="location"
                    className="form-input"
                    value={formData.location || ""}
                    onChange={(e) => {
                      const newLocation = e.target.value;
                      // If the selected location is NOT "București", clear the sector
                      const newSector =
                        newLocation === "Bucuresti" ? formData.sector : "";
                      setFormData((prev) => ({
                        ...prev,
                        location: newLocation,
                        sector: newSector,
                      }));
                    }}
                    required>
                    <option value="">Selectează locația *</option>
                    {Object.entries(locationsData).map(([city, areas]) => {
                      if (city === "Bucuresti") {
                        // For Bucharest, show one option
                        return (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        );
                      } else if (areas.length === 1 && areas[0] === city) {
                        // For cities without subdivisions, show one option
                        return (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        );
                      } else {
                        // If you need to show subdivisions for other cities, you can adjust this accordingly.
                        return (
                          <optgroup key={city} label={city}>
                            {areas.map((area) => (
                              <option key={area} value={area}>
                                {area}
                              </option>
                            ))}
                          </optgroup>
                        );
                      }
                    })}
                  </select>
                </div>

                {/* Dropdown suplimentar pentru sector, dacă s-a ales București */}
                {formData.location === "Bucuresti" && (
                  <div className="form-group">
                    <label htmlFor="sector" className="form-label">
                      Selectează sectorul
                    </label>
                    <select
                      id="sector"
                      name="sector"
                      className="form-input"
                      value={formData.sector}
                      onChange={handleChange}
                      required>
                      <option value="">Selectează sectorul *</option>
                      {[
                        "Sector 1",
                        "Sector 2",
                        "Sector 3",
                        "Sector 4",
                        "Sector 5",
                        "Sector 6",
                      ].map((sector) => (
                        <option key={sector} value={sector}>
                          {sector}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Buget */}
                <div className="form-group">
                  <label htmlFor="price" className="form-label">
                    Care este bugetul tău?
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("price")}
                    />
                  </label>
                  {infoPopup === "price" && (
                    <div className="info-popup">
                      Introdu suma pe care ești dispus să o plătești. Poți
                      negocia ulterior.
                    </div>
                  )}
                  <input
                    type="number"
                    id="price"
                    name="price"
                    className="form-input"
                    placeholder="Bugetul este negociabil"
                    value={formData.price}
                    onChange={handleChange}
                    min="1"
                    step="0.01"
                  />
                </div>
              </div>
              <div className="inline-double">
                {/* Valabilitate */}
                <div className="form-group">
                  <label htmlFor="taskDueDate" className="form-label">
                    Când dorești să îți fie realizată sarcina?
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("taskDueDate")}
                    />
                  </label>
                  {infoPopup === "taskDueDate" && (
                    <div className="info-popup">
                      Alege data până la care dorești ca sarcina să fie
                      finalizată.
                    </div>
                  )}
                  <DatePicker
                    selected={
                      formData.taskDueDate
                        ? formData.taskDueDate instanceof Date
                          ? formData.taskDueDate
                          : new Date(
                              formData.taskDueDate.seconds
                                ? formData.taskDueDate.seconds * 1000
                                : formData.taskDueDate
                            )
                        : null
                    }
                    onChange={(date) =>
                      setFormData((prev) => ({ ...prev, taskDueDate: date }))
                    }
                    dateFormat="dd/MM/yyyy"
                    className="form-input"
                    placeholderText="Selectează data"
                    autoComplete="off"
                  />
                </div>

                {/* Categorie */}
                <div className="form-group">
                  <label htmlFor="category" className="form-label">
                    Alege o categorie
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("category")}
                    />
                  </label>
                  {infoPopup === "category" && (
                    <div className="info-popup">
                      Selectează categoria care descrie cel mai bine sarcina ta.
                    </div>
                  )}
                  <select
                    id="category"
                    name="category"
                    className="form-input"
                    value={formData.category || ""}
                    onChange={handleChange}>
                    <option value="">Selectează categorie *</option>
                    {categories.map((cat) => (
                      <option key={cat.name} value={cat.name}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="inline-double">
                <div className="form-group">
                  <label htmlFor="estimatedDuration" className="form-label">
                    Durata estimată
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("estimatedDuration")}
                    />
                  </label>
                  {infoPopup === "estimatedDuration" && (
                    <div className="info-popup">
                      Selectează durata estimată pentru finalizarea task-ului.
                    </div>
                  )}
                  <select
                    id="estimatedDuration"
                    name="estimatedDuration"
                    className="form-input"
                    value={formData.estimatedDuration || ""}
                    onChange={handleChange}
                    required>
                    <option value="">Selectează durata *</option>
                    <option value="rapid">Rapid (sub 1 oră)</option>
                    <option value="mediu">Mediu (1-3 ore)</option>
                    <option value="lung">Lung (peste 3 ore)</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="paymentType" className="form-label">
                    Tip plată
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("paymentType")}
                    />
                  </label>
                  {infoPopup === "paymentType" && (
                    <div className="info-popup">
                      Selectează tipul de plată pentru acest task.
                    </div>
                  )}
                  <select
                    id="paymentType"
                    name="paymentType"
                    className="form-input"
                    value={formData.paymentType || ""}
                    onChange={handleChange}
                    required>
                    <option value="">Selectează tipul de plată *</option>
                    <option value="negociabil">Negociabil</option>
                    <option value="fix">Fix</option>
                  </select>
                </div>
              </div>
              <div className="inline-double">
                <div className="form-group">
                  <label htmlFor="experienceLevel" className="form-label">
                    Nivel de experiență necesar
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("experienceLevel")}
                    />
                  </label>
                  {infoPopup === "experienceLevel" && (
                    <div className="info-popup">
                      Selectează nivelul de experiență necesar pentru acest
                      task.
                    </div>
                  )}
                  <select
                    id="experienceLevel"
                    name="experienceLevel"
                    className="form-input"
                    value={formData.experienceLevel || ""}
                    onChange={handleChange}
                    required>
                    <option value="">Selectează nivelul de experiență *</option>
                    <option value="incepator">Începător</option>
                    <option value="intermediar">Intermediar</option>
                    <option value="avansat">Avansat</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="isUrgent" className="form-label">
                    Este urgent?
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("isUrgent")}
                    />
                  </label>
                  {infoPopup === "isUrgent" && (
                    <div className="info-popup">
                      Marchează acest task ca urgent dacă ai nevoie de el
                      finalizat în următoarele 3 zile.
                    </div>
                  )}
                  <input
                    type="checkbox"
                    id="isUrgent"
                    name="isUrgent"
                    checked={formData.isUrgent || false}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <button className="btn-submit-ad" type="submit">
                {isEditing ? "Editează Anunț" : "Adaugă Anunț"}
              </button>
            </form>
          </Modal>

          <Modal
            isOpen={isEditModalOpen}
            onRequestClose={() => setIsEditModalOpen(false)}
            className="modal-add-ad-container"
            overlayClassName="modal-overlay">
            <h2 className="modal-title">Editează Anunț</h2>
            <form className="modal-add-ad-form" onSubmit={handleSubmit}>
              {/* Titlu anunț */}
              <div className="form-group">
                <label htmlFor="title" className="form-label">
                  Titlu anunț
                  <FaInfoCircle
                    className="info-icon"
                    onClick={() => togglePopup("title")}
                  />
                </label>
                {infoPopup === "title" && (
                  <div className="info-popup">
                    Alege un titlu clar și relevant.
                  </div>
                )}
                <input
                  type="text"
                  id="title"
                  className="form-input"
                  name="title"
                  placeholder="Introdu titlul anunțului"
                  value={formData.title || ""}
                  onChange={handleChange}
                />
              </div>

              {/* Descriere */}
              <div className="form-group">
                <label htmlFor="description" className="form-label">
                  Descriere
                  <FaInfoCircle
                    className="info-icon"
                    onClick={() => togglePopup("description")}
                  />
                </label>
                {infoPopup === "description" && (
                  <div className="info-popup">
                    Descrie detaliat ce ai nevoie.
                  </div>
                )}
                <textarea
                  id="description"
                  name="description"
                  className="form-input"
                  placeholder="Detaliază cerințele..."
                  value={formData.description || ""}
                  onChange={handleChange}
                  rows="4"></textarea>
              </div>

              <div className="inline-double">
                {/* Locație */}
                <div className="form-group">
                  <label htmlFor="location" className="form-label">
                    Locație
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("location")}
                    />
                  </label>
                  {infoPopup === "location" && (
                    <div className="info-popup">Selectează locația.</div>
                  )}
                  <select
                    id="location"
                    name="location"
                    className="form-input"
                    value={formData.location || ""}
                    onChange={handleChange}
                    required>
                    <option value="">Selectează locația *</option>
                    {Object.entries(locationsData).map(([region, areas]) => (
                      <optgroup key={region} label={region}>
                        {areas.map((area) => (
                          <option key={area} value={area}>
                            {area}
                          </option>
                        ))}
                      </optgroup>
                    ))}
                  </select>
                </div>

                {/* Buget */}
                <div className="form-group">
                  <label htmlFor="price" className="form-label">
                    Care este bugetul tău?
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("price")}
                    />
                  </label>
                  {infoPopup === "price" && (
                    <div className="info-popup">Introdu suma dorită.</div>
                  )}
                  <input
                    type="number"
                    id="price"
                    name="price"
                    className="form-input"
                    placeholder="Bugetul este negociabil"
                    value={formData.price || ""}
                    onChange={handleChange}
                    min="1"
                    step="0.01"
                  />
                </div>
              </div>

              <div className="inline-double">
                {/* Valabilitate */}
                <div className="form-group">
                  <label htmlFor="taskDueDate" className="form-label">
                    Când dorești să îți fie realizată sarcina?
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("taskDueDate")}
                    />
                  </label>
                  {infoPopup === "taskDueDate" && (
                    <div className="info-popup">
                      Alege data până la care dorești ca sarcina să fie
                      finalizată.
                    </div>
                  )}
                  <DatePicker
                    selected={
                      formData.taskDueDate instanceof Date
                        ? formData.taskDueDate
                        : formData.taskDueDate?.seconds
                        ? new Date(formData.taskDueDate.seconds * 1000)
                        : null
                    }
                    onChange={(date) =>
                      setFormData((prev) => ({ ...prev, taskDueDate: date }))
                    }
                    dateFormat="dd/MM/yyyy"
                    className="form-input"
                    placeholderText="Selectează data"
                    autoComplete="off"
                  />
                </div>

                {/* Categorie */}
                <div className="form-group">
                  <label htmlFor="category" className="form-label">
                    Alege o categorie
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("category")}
                    />
                  </label>
                  {infoPopup === "category" && (
                    <div className="info-popup">
                      Selectează categoria potrivită.
                    </div>
                  )}
                  <select
                    id="category"
                    name="category"
                    className="form-input"
                    value={formData.category || ""}
                    onChange={handleChange}>
                    <option value="">Selectează categorie *</option>
                    {categories.map((cat) => (
                      <option key={cat.name} value={cat.name}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="inline-double">
                {/* Durata estimată */}
                <div className="form-group">
                  <label htmlFor="estimatedDuration" className="form-label">
                    Durata estimată
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("estimatedDuration")}
                    />
                  </label>
                  {infoPopup === "estimatedDuration" && (
                    <div className="info-popup">
                      Selectează durata estimată.
                    </div>
                  )}
                  <select
                    id="estimatedDuration"
                    name="estimatedDuration"
                    className="form-input"
                    value={formData.estimatedDuration || ""}
                    onChange={handleChange}>
                    <option value="">Selectează durata *</option>
                    <option value="rapid">Rapid (sub 1 oră)</option>
                    <option value="mediu">Mediu (1-3 ore)</option>
                    <option value="lung">Lung (peste 3 ore)</option>
                  </select>
                </div>

                {/* Tip plată */}
                <div className="form-group">
                  <label htmlFor="paymentType" className="form-label">
                    Tip plată
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("paymentType")}
                    />
                  </label>
                  {infoPopup === "paymentType" && (
                    <div className="info-popup">Selectează tipul de plată.</div>
                  )}
                  <select
                    id="paymentType"
                    name="paymentType"
                    className="form-input"
                    value={formData.paymentType || ""}
                    onChange={handleChange}>
                    <option value="">Selectează tipul de plată *</option>
                    <option value="negociabil">Negociabil</option>
                    <option value="fix">Fix</option>
                  </select>
                </div>
              </div>

              {/* Este urgent? */}
              <div className="inline-double">
                <div className="form-group">
                  <label htmlFor="experienceLevel" className="form-label">
                    Nivel de experiență necesar
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("experienceLevel")}
                    />
                  </label>
                  <select
                    id="experienceLevel"
                    name="experienceLevel"
                    className="form-input"
                    value={formData.experienceLevel || ""}
                    onChange={handleChange}>
                    <option value="">Selectează nivelul de experiență *</option>
                    <option value="incepator">Începător</option>
                    <option value="intermediar">Intermediar</option>
                    <option value="avansat">Avansat</option>
                  </select>
                </div>

                <div className="form-group urgent-container">
                  <label htmlFor="isUrgent" className="form-label">
                    Este urgent?
                    <FaInfoCircle
                      className="info-icon"
                      onClick={() => togglePopup("isUrgent")}
                    />
                  </label>
                  {infoPopup === "isUrgent" && (
                    <div className="info-popup">
                      Marchează acest task ca urgent.
                    </div>
                  )}
                  <input
                    type="checkbox"
                    id="isUrgent"
                    name="isUrgent"
                    checked={formData.isUrgent || false}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Buton Salvare */}
              <button className="btn-submit-ad" type="submit">
                Salvează modificările
              </button>
            </form>
          </Modal>

          {/* My ADS */}
          {filteredAds.length > 0 && (
            <section id="ads" className="ads-section-ads">
              <h2>Anunțurile Tale</h2>

              <div className="search-container">
                <input
                  type="text"
                  placeholder="Caută după titlu"
                  value={searchTitle}
                  onChange={(e) => setSearchTitle(e.target.value)}
                  className="input-search-profile"
                />
                <select
                  value={searchCategory}
                  onChange={(e) => setSearchCategory(e.target.value)}
                  className="input-search-profile">
                  <option value="">Toate categoriile</option>
                  {categoriesData.categories?.map((cat) => (
                    <option key={cat.name} value={cat.name}>
                      {cat.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="ads-list-all">
                {filteredAds.length > 0 ? (
                  filteredAds.map((ad) => (
                    <div className="ad-card-posted" key={ad.id}>
                      <div className="ad-details">
                        {" "}
                        {ad.uid === uid && (
                          <span className="owner-badge-profile">Owner</span>
                        )}
                        <h3 className="title-ad-posted">{ad.title}</h3>
                        <p>Buget: {ad.price} RON</p>
                        <p>Categorie: {ad.category}</p>
                      </div>
                      <div className="ad-actions-mine">
                        <button
                          className="edit-ad-btn"
                          onClick={() => {
                            setIsEditing(true);
                            setCurrentAdId(ad.id);
                          }}>
                          <FaEdit /> Editează
                        </button>
                        <button
                          className="delete-ad-btn"
                          onClick={() => confirmDeleteAd(ad)}>
                          <FaTrashAlt /> Șterge
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Niciun anunț găsit.</p>
                )}
              </div>
            </section>
          )}
          {favoriteAds.length > 0 && (
            <section id="favorite-ads" className="ads-section-favorites">
              <h2>Anunțurile salvate</h2>
              <div className="ads-list">
                {favoriteAds.map((ad) => (
                  <div className="ad-card-favorite-list" key={ad.id}>
                    <div className="ad-details">
                      <h3 className="title-favorite-card">{ad.title}</h3>
                      <p>Buget: {ad.price} RON</p>
                      <p>Categorie: {ad.category || "Nespecificată"}</p>
                      <p>Locație: {ad.location}</p>
                      <p>Descriere: {ad.description}</p>
                    </div>
                    <div className="ad-actions">
                      <button
                        className="delete-favorite-btn-card"
                        onClick={() => handleDeleteFavorite(ad.id)}>
                        <FaTrashAlt /> Șterge
                      </button>
                      <button
                        className="details-btn-card"
                        onClick={() => handleOpenModal(ad)}>
                        <FaInfoCircle /> Detalii
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          )}
        </section>

        {announcements.length > 0 && (
          <section id="announcements" className="ads-section-progress">
            <h2>Anunțuri business la care ai aplicat.</h2>
            {announcements.length > 0 ? (
              announcements.map((announcement) => (
                <div className="announcement-card" key={announcement.id}>
                  <div className="announcement-details">
                    <h3>{announcement.title}</h3>
                    <p>
                      <span className="label">Buget:</span> {announcement.price}
                    </p>
                    <p>
                      <span className="label">Descriere:</span>{" "}
                      {announcement.description}
                    </p>
                    <p>
                      <span className="label">Categorie:</span>{" "}
                      {announcement.category}
                    </p>
                    <p>
                      <span className="label">Locatie:</span>{" "}
                      {announcement.location}
                    </p>
                    {/* Afișare date de contact proprietar */}
                    <div className="announcement-owner">
                      <p>
                        <span className="label">Nume Proprietar:</span>{" "}
                        {announcement.fullName}
                      </p>
                      <p>
                        <span className="label">Telefon:</span>{" "}
                        <a
                          href={`https://wa.me/${announcement.phone}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          {announcement.phone}
                        </a>
                      </p>
                      <p>
                        <span className="label">Email:</span>{" "}
                        <a href={`mailto:${announcement.email}`}>
                          {announcement.email}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="announcement-actions">
                    <button
                      className="btn-undone"
                      onClick={() => handleOpenTaskUndoneModal(announcement)}>
                      Neterminat
                    </button>{" "}
                    <button
                      className="btn-finalize"
                      onClick={() =>
                        handleMarkAnnouncementAsCompleted(announcement)
                      }>
                      Finalizat
                    </button>
                    {announcement.status === "finalized" && (
                      <div className="finalized-actions">
                        <button className="btn-disabled" disabled>
                          Finalizat
                        </button>
                        <button
                          className="btn-review"
                          onClick={() => openReviewModal(announcement)}>
                          Lasă Review
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>Nu ai aplicat la alte anunțuri.</p>
            )}
          </section>
        )}
        {/* Anunturi in curs */}
        {inProgressAds.length > 0 && (
          <section id="in-progress-ads" className="ads-section-progress">
            <h2>Anunțuri În Curs</h2>
            <div className="ads-list-inProgress">
              {inProgressAds.length > 0 ? (
                inProgressAds.map((ad) => (
                  <div className="ad-card-curs" key={ad.id}>
                    <div className="ad-details">
                      <h3 className="title-ad-inProgress">{ad.title}</h3>
                      <p>
                        <strong>Buget:</strong> {ad.price} RON
                      </p>
                      <p>
                        <strong>Categorie:</strong> {ad.category}
                      </p>

                      {/* Seeker-ul vede detaliile Tasker-ului */}
                      {ad.uid === uid && (
                        <>
                          <p>
                            <strong>Tasker:</strong> {ad.taskerName || "N/A"}
                          </p>
                          <span className="owner-badge-profile">
                            Anunțul tău!
                          </span>
                        </>
                      )}

                      {/* Tasker-ul vede detaliile Seeker-ului */}
                      {ad.taskerUid === uid && (
                        <>
                          <p>
                            <strong>Seeker:</strong> {ad.ownerName || "N/A"}
                          </p>
                          <span className="owner-badge-profile">
                            Ai aplicat!
                          </span>
                        </>
                      )}
                    </div>

                    <div className="ad-actions-inProgress">
                      {/* Butonul Contact este vizibil pentru ambele roluri */}
                      <button
                        className="contact-button"
                        onClick={() =>
                          setShowContactInfo((prev) =>
                            prev === ad.id ? null : ad.id
                          )
                        }>
                        Contactează persoana
                      </button>

                      {/* Afișare detalii de contact DOAR dacă butonul a fost apăsat */}
                      {showContactInfo === ad.id && (
                        <div className="contact-info-inProgress">
                          {/* Dacă owner-ul vede detaliile tasker-ului */}
                          {ad.uid === uid && (
                            <>
                              <a
                                className="action-btn whatsapp"
                                href={`https://wa.me/${standardizeWhatsAppNumber(
                                  ad.taskerPhone
                                ).slice(1)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => {
                                  try {
                                    if (!ad.taskerPhone) {
                                      e.preventDefault(); // Previne deschiderea unui link gol
                                      alert("Număr de telefon indisponibil!");
                                    }
                                  } catch (error) {
                                    e.preventDefault(); // Previne deschiderea link-ului în caz de eroare
                                    alert(
                                      "Format invalid al numărului de telefon."
                                    );
                                  }
                                }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width="30"
                                  height="30"
                                  viewBox="0 0 48 48">
                                  <path
                                    fill="#fff"
                                    d="M4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5c5.1,0,9.8,2,13.4,5.6	C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19c0,0,0,0,0,0h0c-3.2,0-6.3-0.8-9.1-2.3L4.9,43.3z"></path>
                                  <path
                                    fill="#fff"
                                    d="M4.9,43.8c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.1-0.5L7,33.5c-1.6-2.9-2.5-6.2-2.5-9.6	C4.5,13.2,13.3,4.5,24,4.5c5.2,0,10.1,2,13.8,5.7c3.7,3.7,5.7,8.6,5.7,13.8c0,10.7-8.7,19.5-19.5,19.5c-3.2,0-6.3-0.8-9.1-2.3	L5,43.8C5,43.8,4.9,43.8,4.9,43.8z"></path>
                                  <path
                                    fill="#cfd8dc"
                                    d="M24,5c5.1,0,9.8,2,13.4,5.6C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19h0c-3.2,0-6.3-0.8-9.1-2.3	L4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5 M24,43L24,43L24,43 M24,43L24,43L24,43 M24,4L24,4C13,4,4,13,4,24	c0,3.4,0.8,6.7,2.5,9.6L3.9,43c-0.1,0.3,0,0.7,0.3,1c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0l9.7-2.5c2.8,1.5,6,2.2,9.2,2.2	c11,0,20-9,20-20c0-5.3-2.1-10.4-5.8-14.1C34.4,6.1,29.4,4,24,4L24,4z"></path>
                                  <path
                                    fill="#40c351"
                                    d="M35.2,12.8c-3-3-6.9-4.6-11.2-4.6C15.3,8.2,8.2,15.3,8.2,24c0,3,0.8,5.9,2.4,8.4L11,33l-1.6,5.8	l6-1.6l0.6,0.3c2.4,1.4,5.2,2.2,8,2.2h0c8.7,0,15.8-7.1,15.8-15.8C39.8,19.8,38.2,15.8,35.2,12.8z"></path>
                                  <path
                                    fill="#fff"
                                    fill-rule="evenodd"
                                    d="M19.3,16c-0.4-0.8-0.7-0.8-1.1-0.8c-0.3,0-0.6,0-0.9,0	s-0.8,0.1-1.3,0.6c-0.4,0.5-1.7,1.6-1.7,4s1.7,4.6,1.9,4.9s3.3,5.3,8.1,7.2c4,1.6,4.8,1.3,5.7,1.2c0.9-0.1,2.8-1.1,3.2-2.3	c0.4-1.1,0.4-2.1,0.3-2.3c-0.1-0.2-0.4-0.3-0.9-0.6s-2.8-1.4-3.2-1.5c-0.4-0.2-0.8-0.2-1.1,0.2c-0.3,0.5-1.2,1.5-1.5,1.9	c-0.3,0.3-0.6,0.4-1,0.1c-0.5-0.2-2-0.7-3.8-2.4c-1.4-1.3-2.4-2.8-2.6-3.3c-0.3-0.5,0-0.7,0.2-1c0.2-0.2,0.5-0.6,0.7-0.8	c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.1-0.6,0-0.8C20.6,19.3,19.7,17,19.3,16z"
                                    clip-rule="evenodd"></path>
                                </svg>{" "}
                                {ad.taskerPhone || "Indisponibil"}
                              </a>

                              <a
                                className="action-btn email"
                                href={`mailto:${
                                  ad.taskerEmail
                                }?subject=${encodeURIComponent(
                                  "Interes pentru anunțul " + ad.title
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                📧 {ad.taskerEmail || "Indisponibil"}
                              </a>
                            </>
                          )}

                          {/* Dacă tasker-ul vede detaliile owner-ului */}
                          {ad.taskerUid === uid && (
                            <>
                              <a
                                className="action-btn whatsapp"
                                href={`https://wa.me/${standardizeWhatsAppNumber(
                                  ad.ownerPhone
                                ).slice(1)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => {
                                  try {
                                    if (!ad.ownerPhone) {
                                      e.preventDefault(); // Previne deschiderea unui link gol
                                      alert("Număr de telefon indisponibil!");
                                    }
                                  } catch (error) {
                                    e.preventDefault(); // Previne deschiderea link-ului în caz de eroare
                                    alert(
                                      "Format invalid al numărului de telefon."
                                    );
                                  }
                                }}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  x="0px"
                                  y="0px"
                                  width="30"
                                  height="30"
                                  viewBox="0 0 48 48">
                                  <path
                                    fill="#fff"
                                    d="M4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5c5.1,0,9.8,2,13.4,5.6	C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19c0,0,0,0,0,0h0c-3.2,0-6.3-0.8-9.1-2.3L4.9,43.3z"></path>
                                  <path
                                    fill="#fff"
                                    d="M4.9,43.8c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.3-0.1-0.5L7,33.5c-1.6-2.9-2.5-6.2-2.5-9.6	C4.5,13.2,13.3,4.5,24,4.5c5.2,0,10.1,2,13.8,5.7c3.7,3.7,5.7,8.6,5.7,13.8c0,10.7-8.7,19.5-19.5,19.5c-3.2,0-6.3-0.8-9.1-2.3	L5,43.8C5,43.8,4.9,43.8,4.9,43.8z"></path>
                                  <path
                                    fill="#cfd8dc"
                                    d="M24,5c5.1,0,9.8,2,13.4,5.6C41,14.2,43,18.9,43,24c0,10.5-8.5,19-19,19h0c-3.2,0-6.3-0.8-9.1-2.3	L4.9,43.3l2.7-9.8C5.9,30.6,5,27.3,5,24C5,13.5,13.5,5,24,5 M24,43L24,43L24,43 M24,43L24,43L24,43 M24,4L24,4C13,4,4,13,4,24	c0,3.4,0.8,6.7,2.5,9.6L3.9,43c-0.1,0.3,0,0.7,0.3,1c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.2,0,0.3,0l9.7-2.5c2.8,1.5,6,2.2,9.2,2.2	c11,0,20-9,20-20c0-5.3-2.1-10.4-5.8-14.1C34.4,6.1,29.4,4,24,4L24,4z"></path>
                                  <path
                                    fill="#40c351"
                                    d="M35.2,12.8c-3-3-6.9-4.6-11.2-4.6C15.3,8.2,8.2,15.3,8.2,24c0,3,0.8,5.9,2.4,8.4L11,33l-1.6,5.8	l6-1.6l0.6,0.3c2.4,1.4,5.2,2.2,8,2.2h0c8.7,0,15.8-7.1,15.8-15.8C39.8,19.8,38.2,15.8,35.2,12.8z"></path>
                                  <path
                                    fill="#fff"
                                    fill-rule="evenodd"
                                    d="M19.3,16c-0.4-0.8-0.7-0.8-1.1-0.8c-0.3,0-0.6,0-0.9,0	s-0.8,0.1-1.3,0.6c-0.4,0.5-1.7,1.6-1.7,4s1.7,4.6,1.9,4.9s3.3,5.3,8.1,7.2c4,1.6,4.8,1.3,5.7,1.2c0.9-0.1,2.8-1.1,3.2-2.3	c0.4-1.1,0.4-2.1,0.3-2.3c-0.1-0.2-0.4-0.3-0.9-0.6s-2.8-1.4-3.2-1.5c-0.4-0.2-0.8-0.2-1.1,0.2c-0.3,0.5-1.2,1.5-1.5,1.9	c-0.3,0.3-0.6,0.4-1,0.1c-0.5-0.2-2-0.7-3.8-2.4c-1.4-1.3-2.4-2.8-2.6-3.3c-0.3-0.5,0-0.7,0.2-1c0.2-0.2,0.5-0.6,0.7-0.8	c0.2-0.3,0.3-0.5,0.5-0.8c0.2-0.3,0.1-0.6,0-0.8C20.6,19.3,19.7,17,19.3,16z"
                                    clip-rule="evenodd"></path>
                                </svg>{" "}
                                {ad.ownerPhone || "Indisponibil"}
                              </a>

                              <a
                                className="action-btn email"
                                href={`mailto:${
                                  ad.ownerEmail
                                }?subject=${encodeURIComponent(
                                  "Interes pentru anunțul " + ad.title
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                📧 {ad.ownerEmail || "Indisponibil"}
                              </a>
                            </>
                          )}
                        </div>
                      )}

                      {/* Butoane pentru finalizare */}
                      <div className="buttons-finalize">
                        {(ad.uid === uid || ad.taskerUid === uid) && (
                          <button
                            className="undone-button"
                            onClick={() => handleOpenUndoneModal(ad)}>
                            Neterminat
                          </button>
                        )}
                        {ad.uid === uid && (
                          <button
                            className="done-button"
                            onClick={() => handleMarkAsCompleted(ad, "owner")}>
                            Finalizat
                          </button>
                        )}
                        {ad.taskerUid === uid && (
                          <button
                            className="done-button"
                            onClick={() => handleMarkAsCompleted(ad, "tasker")}>
                            Task finalizat
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>Nu ai anunțuri în curs.</p>
              )}
            </div>
          </section>
        )}
        {/* Completed ADS */}
        <section id="completed-ads" className="completed-ads-section">
          <h2 className="completed-ads-title">Anunțuri Finalizate</h2>
          <div className="completed-ads-list">
            {completedAds.length > 0 ? (
              completedAds.map((ad) => (
                <div
                  key={ad.id}
                  className={`completed-ad-card ${
                    ad.status === "failed"
                      ? "completed-ad-failed"
                      : "completed-ad-finalized"
                  }`}>
                  <div className="completed-ad-details">
                    <h3 className="completed-ad-title">{ad.title}</h3>
                    <p className="completed-ad-price">
                      <strong>💰</strong> {ad.price} RON
                    </p>
                    <p className="completed-ad-category">
                      <strong>📌</strong> {ad.category}
                    </p>
                    {ad.uid === uid && (
                      <span className="completed-owner-badge">👤 Owner</span>
                    )}

                    {ad.status === "failed" && (
                      <p className="completed-ad-failed-reason">
                        ❌ <strong>Motiv:</strong>{" "}
                        {ad.undoneReason || "Nespecificat"}
                      </p>
                    )}

                    <h4 className="completed-ad-reviews-title">⭐ Recenzii:</h4>
                    {ad.reviews?.length > 0 ? (
                      ad.reviews.map((review, index) => (
                        <div key={index} className="completed-review-card">
                          <p className="completed-review-author">
                            <strong>👤</strong> {review.reviewerName}
                          </p>
                          <p className="completed-review-for">
                            <strong>🎯 Pentru:</strong> {review.forName}
                          </p>
                          <div className="completed-review-rating">
                            {[...Array(5)].map((_, i) => (
                              <svg
                                key={i}
                                xmlns="http://www.w3.org/2000/svg"
                                height="1rem"
                                viewBox="0 0 576 512"
                                className={
                                  i < review.rating
                                    ? "completed-star filled"
                                    : "completed-star"
                                }>
                                <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
                              </svg>
                            ))}
                          </div>
                          <p className="completed-review-text">
                            <strong>📝 Review:</strong> {review.review}
                          </p>
                        </div>
                      ))
                    ) : (
                      <p className="completed-no-reviews">🔹 Nicio recenzie.</p>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p className="completed-no-ads">📭 Nu ai anunțuri finalizate.</p>
            )}
          </div>
        </section>
      </div>

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        className="react-modal-content"
        overlayClassName="react-modal-overlay">
        <h2>Confirmare Ștergere</h2>
        <p>Ești sigur că vrei să ștergi acest anunț?</p>
        <button onClick={handleDeleteAd} className="confirm-delete-btn">
          Confirmă
        </button>
        <button onClick={closeDeleteModal} className="cancel-delete-btn">
          Anulează
        </button>
      </Modal>

      {/* Done Modal */}
      <Modal
        isOpen={isDoneModalOpen}
        onRequestClose={handleCloseModals}
        className="react-modal-content"
        overlayClassName="react-modal-overlay">
        <h2>
          Ne-ar prinde tare bine o recenzie de la tine pentru această sarcină!
          📝✨ Mulțumim că ne ajuți să devenim tot mai buni! 💪😊
        </h2>
        <div className="content-modal-done">
          <div className="rating">
            {[5, 4, 3, 2, 1].map((starValue) => (
              <label key={starValue}>
                <input
                  type="radio"
                  id={`star${starValue}`}
                  name="rate"
                  value={starValue}
                  checked={reviewData.rating === starValue}
                  onChange={() =>
                    setReviewData((prev) => ({ ...prev, rating: starValue }))
                  }
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="3rem"
                  viewBox="0 0 576 512"
                  className={reviewData.rating >= starValue ? "filled" : ""}>
                  <path
                    d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 
                    1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 
                    12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 
                    5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 
                    225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 
                    150.3 316.9 18z"
                  />
                </svg>
              </label>
            ))}
          </div>
          <textarea
            className="modern-textarea"
            placeholder="Review"
            value={reviewData.review}
            required
            onChange={(e) =>
              setReviewData((prev) => ({ ...prev, review: e.target.value }))
            }></textarea>
        </div>
        <div className="buttons-modal-done">
          <button onClick={handleCloseModals} className="modern-close-button">
            Închide
          </button>
          <button
            onClick={() => handleSubmitReview(currentAd, currentRole)}
            className="modern-submit-button">
            Trimite
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isPostPopupOpen}
        onRequestClose={() => setIsPostPopupOpen(false)}
        className="post-popup-modal"
        overlayClassName="post-popup-overlay">
        <div className="post-popup-content">
          <h2>📩 Anunțul a fost postat!</h2>
          <p>
            Acum ne vom ocupa să îți găsim un Aider. Tu relaxează-te și așteaptă
            un email de la Aidly. 🚀
          </p>
        </div>
      </Modal>

      {/* Undone Modal */}
      <Modal
        isOpen={isUndoneModalOpen}
        onRequestClose={handleCloseModals}
        className="react-modal-content"
        overlayClassName="react-modal-overlay">
        <h2>Task Nefinalizat</h2>
        <div className="content-modal-done">
          <img className="sad-emoji" src={Sad} alt="sad emoji review" />
          <label htmlFor="undoneOption">De ce nu a fost finalizat?</label>
          <select
            id="undoneOption"
            value={undoneOption}
            onChange={(e) => setUndoneOption(e.target.value)}
            required>
            <option value="">Selectează opțiunea...</option>
            <option value="notWanted">Nu se mai dorește</option>
            <option value="notCompleted">Nu a fost făcut dintr-un motiv</option>
          </select>
          <textarea
            className="modern-textarea"
            placeholder="Explică motivul"
            value={undoneReason}
            onChange={(e) => setUndoneReason(e.target.value)}
            required></textarea>
        </div>
        <div className="buttons-modal-done">
          <button onClick={handleCloseModals} className="modern-close-button">
            Închide
          </button>
          <button
            onClick={() => handleSubmitUndone(currentAd)}
            className="modern-submit-button">
            Trimite
          </button>
        </div>
      </Modal>

      {/* Notification Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="react-modal-content"
        overlayClassName="react-modal-overlay">
        <h2>Detalii Notificare</h2>
        {currentNotification && (
          <>
            <p>
              <strong>Mesaj:</strong> {currentNotification.message}
            </p>
            <p>
              <strong>Răspuns:</strong>{" "}
              {currentNotification.reply || "Niciun răspuns încă"}
            </p>
            <p>
              <strong>Data:</strong>{" "}
              {getFormattedDate(currentNotification.timestamp)}
            </p>
          </>
        )}
        <button className="close-button" onClick={() => setIsModalOpen(false)}>
          Închide
        </button>
      </Modal>

      {/* Edit Name/Email/Phone Modal */}
      {isEditingName && (
        <Modal
          isOpen={isEditingName}
          onRequestClose={() => setIsEditingName(false)}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          className="react-modal-content"
          overlayClassName="react-modal-overlay">
          <h2>Modifică informațiile de profil</h2>
          <p
            className="modal-info-text"
            style={{
              backgroundColor: "#0978d3",
              padding: "10px 15px",
              borderRadius: "8px",
              color: "#ffffff",
              fontSize: "14px",
              lineHeight: "1.5",
              marginBottom: "15px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}>
            Pentru a putea continua, avem nevoie de numărul dumneavoastră de
            telefon. Acesta va fi utilizat exclusiv pentru comunicare legată de
            anunțuri, <b> NU pentru marketing.</b>
          </p>
          <div className="edit-field">
            <input
              type="text"
              value={formData.displayName || ""}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  displayName: e.target.value,
                }))
              }
              placeholder="Nume"
              className="input-design"
            />
          </div>
          <div className="edit-field">
            <input
              disabled
              type="email"
              value={formData.email || ""}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, email: e.target.value }))
              }
              placeholder="Email"
              className="input-design"
            />
          </div>
          <div className="edit-field">
            <input
              type="text"
              value={formData.phone || ""}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  phone: e.target.value.replace(/\D/g, ""), // Permite doar cifre
                }))
              }
              placeholder="Număr de telefon"
              className="input-design"
            />
          </div>

          <button onClick={handleConfirmSave} className="save-profile-btn">
            Salvează
          </button>
        </Modal>
      )}

      {isTaskUndoneModalOpen && (
        <>
          {/* Overlay pentru modal */}
          <div
            className="task-undone-modal-overlay"
            onClick={() => setIsTaskUndoneModalOpen(false)}></div>
          <div className="task-undone-modal-container">
            <h2>Task Nefinalizat</h2>
            <textarea
              className="task-undone-modal-textarea"
              placeholder="Explică motivul..."
              value={taskUndoneReason}
              onChange={(e) => setTaskUndoneReason(e.target.value)}></textarea>
            <div className="task-undone-modal-buttons">
              <button
                className="task-undone-modal-button"
                onClick={handleSubmitTaskUndone}>
                Trimite Motiv
              </button>
              <button
                className="task-undone-modal-button"
                onClick={() => setIsTaskUndoneModalOpen(false)}>
                Închide
              </button>
            </div>
          </div>
        </>
      )}

      <Modal
        isOpen={isDraftsModalOpen}
        onRequestClose={() => setIsDraftsModalOpen(false)}
        className="react-modal-content"
        overlayClassName="react-modal-overlay">
        <h2>Schițele Tale</h2>
        {draftAds.length > 0 ? (
          draftAds.map((draft) => (
            <div key={draft.id} className="draft-card">
              <h4 className="title-drafts-modal">{draft.title}</h4>
              <div className="draft-actions">
                <button
                  className="button-draft-modal edit-button-draft"
                  onClick={() => handleEditDraft(draft)}>
                  Editează
                </button>
                <button
                  className="button-draft-modal post-button-draft"
                  onClick={() => handlePostDraft(draft)}>
                  Postează
                </button>
                <button
                  className="button-draft-modal delete-button-draft"
                  onClick={() => handleDeleteDraft(draft.id)}>
                  Șterge
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>Nu ai schițe salvate.</p>
        )}
      </Modal>

      {isModalDetailsOpen && selectedAd && (
        <ModalDetails
          service={selectedAd}
          onClose={() => setIsModalDetailsOpen(false)}
        />
      )}
      {isTaskerModalOpen && (
        <BecomeTaskerModal onClose={() => setIsTaskerModalOpen(false)} />
      )}
    </div>
  );
};

export default Profile;
