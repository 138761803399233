// Modal.jsx
import React, { useEffect, useState } from "react";
import "../styling/modal.css";
import { Link } from "react-router-dom";

import { firestore } from "../data/firebase";
import emailjs from "@emailjs/browser";
import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  Timestamp,
  onSnapshot,
  increment,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import TermsModal from "./TermsModal";

// Helper Function: Create Notification
const createNotification = async ({
  recipientUid,
  senderUid,
  adId,
  message,
  status,
}) => {
  if (!recipientUid || !adId || !status || !message) {
    console.error("Missing required notification fields.");
    return;
  }
  try {
    await addDoc(collection(firestore, "notifications"), {
      recipientUid,
      senderUid,
      adId,
      message,
      status,
      timestamp: Timestamp.now(),
      isRead: false,
      isDeleted: false,
    });
  } catch (error) {
    console.error("Error creating notification:", error);
  }
};

const Modal = ({ service, onClose, currentUserId }) => {
  const [ownerName, setOwnerName] = useState("Anonim");
  const [ownerEmail, setOwnerEmail] = useState(null);
  const [ownerPhone, setOwnerPhone] = useState(null);
  const [views, setViews] = useState(0);

  const [isUrgentBadge, setIsUrgentBadge] = useState(false);
  const [adDetails, setAdDetails] = useState({
    estimatedDuration: "",
    paymentType: "",
    experienceLevel: "",
    isUrgent: false,
  });
  const [accepted, setAccepted] = useState(false); // Local flag if offer accepted
  const [showTermsModal, setShowTermsModal] = useState(false);
  const navigate = useNavigate();

  // 1. Determine if this is a business announcement or a regular ad
  const isBusinessAd = Boolean(service.businessId);

  // Google Maps link (using service.location with fallback "Bucuresti")
  const effectiveLocation =
    service.location === "Bucuresti" && service.sector
      ? `${service.location} ${service.sector}`
      : service.location;

  const googleMapLink = `https://www.google.com/maps/embed/v1/place?key=AIzaSyAD8Tz9Ns6ipEIKk1eaI0RM1PRJUuiwnxA&q=${encodeURIComponent(
    effectiveLocation
  )}`;

  // VIEWS LOGIC

  useEffect(() => {
    if (!service?.id) return;

    const adRef = doc(firestore, "ads", service.id);

    // Increment view count by 1
    updateDoc(adRef, { views: increment(1) }).catch((error) =>
      console.error("Error incrementing views:", error)
    );

    // Real-time listener for view count
    const unsubscribe = onSnapshot(adRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setViews(data.views || 0);
      }
    });

    return () => unsubscribe();
  }, [service.id]);

  // Helper: format full name
  const formatName = (fullName) => {
    if (!fullName) return "Anonim";
    const parts = fullName.trim().split(" ");
    return parts.length > 1 ? `${parts[0]} ${parts[1][0]}.` : parts[0];
  };

  // 2. For non‑business ads, fetch the owner details from "users"
  const fetchOwnerDetails = async () => {
    if (isBusinessAd || !service.uid) return;
    try {
      const userDocRef = doc(firestore, "users", service.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const data = userDocSnap.data();
        setOwnerName(formatName(data.displayName));
        setOwnerEmail(data.email || null);
        setOwnerPhone(data.phoneNumber || null);
      } else {
        console.warn("Proprietarul anunțului nu a fost găsit.");
      }
    } catch (error) {
      console.error("Error fetching owner details:", error);
    }
  };

  // 3. For business announcements, the owner details are already in the service object
  useEffect(() => {
    if (isBusinessAd) {
      setOwnerName(formatName(service.fullName));
      setOwnerEmail(service.email || null);
      setOwnerPhone(service.phone || null);
    } else {
      fetchOwnerDetails();
    }
    // eslint-disable-next-line
  }, [service.uid, isBusinessAd]);

  // Determine if the current user is the owner
  const isOwner = isBusinessAd
    ? currentUserId === service.businessId
    : currentUserId === service.uid;

  // 4. Process acceptance: update DB (status becomes "inProgress") and add participants
  const processAcceptance = async () => {
    if (!currentUserId) {
      toast.error("Trebuie să fii autentificat pentru a accepta un anunț.");
      return;
    }
    if (!service || !service.id) {
      toast.error("Anunțul nu mai există sau are date incomplete.");
      return;
    }
    // Get current user details
    const userRef = doc(firestore, "users", currentUserId);
    const userSnap = await getDoc(userRef);
    if (!userSnap.exists()) {
      toast.error("Utilizatorul curent nu există.");
      return;
    }
    const taskerData = userSnap.data();

    // Business announcements: update in "announcements" collection
    if (isBusinessAd) {
      const targetStatus = "inProgress";
      const annRef = doc(firestore, "announcements", service.id);
      const annSnap = await getDoc(annRef);
      if (!annSnap.exists()) {
        toast.error(`Anunțul "${service.title}" nu mai există.`);
        return;
      }
      const annData = annSnap.data();
      if (annData.status === targetStatus) {
        toast.error("Anunțul a fost deja acceptat și este în curs.");
        return;
      }
      try {
        await updateDoc(annRef, {
          status: targetStatus,
          // Add participants: owner (service.businessId) and applicant (currentUserId)
          participants: [
            ...new Set([
              service.businessId,
              currentUserId,
              ...(annData.participants || []),
            ]),
          ],
          taskerUid: currentUserId,
          taskerName: taskerData.displayName || "Anonim",
          taskerPhone: taskerData.phoneNumber || "Necunoscut",
          legal: true,
        });
        await createNotification({
          recipientUid: service.businessId, // owner for business ad
          senderUid: currentUserId,
          adId: service.id,
          message: `${
            taskerData.displayName || "Anonim"
          } a acceptat oferta ta BUSINESS pentru "${service.title}".`,
          status: "acceptedOffer",
        });
        const emailTemplateParams = {
          to_email: ownerEmail,
          owner_name: ownerName || "Owner",
          task_title: service.title,
          accepted_by: taskerData.displayName || "Anonim",
          profile_link: `https://aidly.ro/profile/${service.businessId}`,
        };
        emailjs
          .send(
            "service_exj3u5d",
            "template_mailtoowner",
            emailTemplateParams,
            "8P20l58Dg2sQRxHiK"
          )
          .then(
            () => {
              toast.success("Proprietarul a fost notificat prin email.");
            },
            (error) => {
              toast.error("Eroare la trimiterea notificării prin email.");
            }
          );
        toast.success(`Oferta pentru "${service.title}" a fost acceptată!`);
        setAccepted(true);
      } catch (error) {
        toast.error("Eroare la acceptarea ofertei.");
      }
      return;
    }

    // Non-business ads: update in "ads" collection
    const targetStatus = "inProgress";
    const adRef = doc(firestore, "ads", service.id);
    const adSnap = await getDoc(adRef);
    if (!adSnap.exists()) {
      toast.error(`Anunțul "${service.title}" nu mai există.`);
      return;
    }
    const adData = adSnap.data();
    if (adData.status === targetStatus) {
      toast.error("Anunțul a fost deja acceptat și este în curs.");
      return;
    }
    try {
      await updateDoc(adRef, {
        status: targetStatus,
        taskerUid: currentUserId,
        taskerName: taskerData.displayName || "Anonim",
        taskerPhone: taskerData.phoneNumber || "Necunoscut",
        legal: true,
        participants: [
          ...new Set([
            service.uid,
            currentUserId,
            ...(adData.participants || []),
          ]),
        ],
      });
      await createNotification({
        recipientUid: service.uid,
        senderUid: currentUserId,
        adId: service.id,
        message: `${
          taskerData.displayName || "Anonim"
        } a acceptat oferta ta pentru "${service.title}".`,
        status: "acceptedOffer",
      });
      const emailTemplateParams = {
        to_email: ownerEmail,
        owner_name: ownerName || "Owner",
        task_title: service.title,
        accepted_by: taskerData.displayName || "Anonim",
        profile_link: `https://aidly.ro/profile/${service.uid}`,
      };
      emailjs
        .send(
          "service_exj3u5d",
          "template_mailtoowner",
          emailTemplateParams,
          "8P20l58Dg2sQRxHiK"
        )
        .then(
          () => {
            toast.success("Proprietarul a fost notificat prin email.");
          },
          (error) => {
            toast.error("Eroare la trimiterea notificării prin email.");
          }
        );
      toast.success(`Oferta pentru "${service.title}" a fost acceptată!`);
      setAccepted(true);
    } catch (error) {
      toast.error("Eroare la acceptarea ofertei.");
    }
  };

  const openTermsModal = () => setShowTermsModal(true);
  const closeTermsModal = () => setShowTermsModal(false);
  const confirmTerms = async () => {
    await processAcceptance();
    setShowTermsModal(false);
  };

  // 5. For non-business ads, fetch extra ad details from "ads"
  useEffect(() => {
    const fetchAdDetails = async () => {
      if (!service.id || isBusinessAd) return;
      try {
        const adRef = doc(firestore, "ads", service.id);
        const adSnap = await getDoc(adRef);
        if (adSnap.exists()) {
          setAdDetails({
            estimatedDuration: adSnap.data().estimatedDuration || "Necunoscut",
            paymentType: adSnap.data().paymentType || "Nespecificat",
            experienceLevel: adSnap.data().experienceLevel || "Nespecificat",
            isUrgent: adSnap.data().isUrgent || false,
          });
        } else {
          console.warn("Anunțul nu a fost găsit în baza de date.");
        }
      } catch (error) {
        console.error("Eroare la obținerea detaliilor anunțului:", error);
      }
    };
    fetchAdDetails();
  }, [service.id, isBusinessAd]);

  // 6. Navigate to the owner's profile
  const handleOwnerClick = () => {
    if (isBusinessAd) {
      navigate(`/user/${service.businessId}`);
    } else {
      navigate(`/user/${service.uid}`);
    }
  };

  // 7. Report ad link
  const reportAdLink = `mailto:contact@aidly.ro?subject=Anunt Raportat (ID: ${
    service.id
  })&body=${encodeURIComponent(
    `Bună ziua,\n\nVă raportez anunțul cu titlul: "${service.title}" și ID-ul: "${service.id}" pentru încălcarea următoarei reguli:\n\n(Descrieți motivul aici).`
  )}`;

  // 8. Share links for WhatsApp / Facebook
  const shareOnWhatsApp = `https://wa.me/?text=${encodeURIComponent(
    `Vezi acest anunț:\nTitlu: ${service.title}\nDescriere: ${
      service.description || "Fără descriere"
    }\nPreț: ${service.price} RON\nLocație: ${
      service.location
    }\nAccesează anunțul: https://aidly.ro/ad/${service.id}`
  )}`;
  const shareOnFacebook = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    `https://aidly.ro/ad?id=${service.id}`
  )}`;

  // 9. Calculate "URGENT" badge if the validity period is ≤ 3 days after posting
  useEffect(() => {
    if (service.timestamp && service.validity) {
      const postDate =
        service.timestamp.seconds * 1000 +
        service.timestamp.nanoseconds / 1000000;
      const validityDate =
        service.validity.seconds * 1000 +
        service.validity.nanoseconds / 1000000;
      const diffDays = (validityDate - postDate) / (1000 * 60 * 60 * 24);
      setIsUrgentBadge(diffDays <= 3);
    }
  }, [service.timestamp, service.validity]);

  // 10. Example function for uploading CV
  const handleUploadCV = () => {
    toast.info(
      "Aici poți implementa logica de încărcare CV (ex: Firebase Storage)."
    );
  };

  // 11. Listener (onSnapshot) to sync DB status with local accepted flag
  useEffect(() => {
    if (!service.id) return;
    const collectionName = isBusinessAd ? "announcements" : "ads";
    const docRef = doc(firestore, collectionName, service.id);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.status === "inProgress") {
          setAccepted(true);
        } else {
          setAccepted(false);
        }
      }
    });
    return () => unsubscribe();
  }, [service.id, isBusinessAd]);

  // Also update accepted flag if service.status changes externally
  useEffect(() => {
    if (service.status === "inProgress") {
      setAccepted(true);
    } else {
      setAccepted(false);
    }
  }, [service.status]);

  return (
    <div className="overlay-backdrop" onClick={onClose}>
      <div
        className={`overlay-window-modal-ad ${
          isBusinessAd ? "business-modal" : ""
        }`}
        onClick={(e) => e.stopPropagation()}>
        {/* Header */}
        <div className="modal-header-modal-ad">
          <h2 className="modal-title-modal-ad">{service.title}</h2>
          {isUrgentBadge && <span className="urgent-badge">URGENT</span>}
        </div>
        {/* Content */}
        <div className="modal-content-modal-ad">
          <div className="modal-left-column">
            <p>
              <strong>{t("modal.description")}:</strong>{" "}
              {service.description || t("modal.no_description")}
            </p>
            <p>
              <strong>Preț:</strong> {service.price} RON{" "}
              {service.priceType === "daily" ? "(zilnic)" : "(orar)"}
            </p>
            <p>
              <strong>Locație:</strong> {service.location || "Nespecificată"}
            </p>
            <p>
              <strong>Categorie:</strong> {service.category || "Nespecificată"}
            </p>
            <p>
              <strong>Valabil până la:</strong>{" "}
              {service.validity
                ? new Date(
                    service.validity.seconds * 1000 +
                      service.validity.nanoseconds / 1000000
                  ).toLocaleDateString("ro-RO")
                : "N/A"}
            </p>
            {/* For non-business ads */}
            {!isBusinessAd && (
              <>
                <p>
                  <strong>Durata estimată:</strong>{" "}
                  {adDetails.estimatedDuration ||
                    service.estimatedDuration ||
                    "N/A"}
                </p>
                <p>
                  <strong>Tip de plată:</strong>{" "}
                  {adDetails.paymentType ||
                    service.paymentType ||
                    "Nespecificat"}
                </p>

                <p>
                  <strong>Nivel de experiență:</strong>{" "}
                  {adDetails.experienceLevel ||
                    service.experienceLevel ||
                    "N/A"}
                </p>
                {adDetails.isUrgent && (
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    ⚠️ Anunț urgent (ads)!
                  </p>
                )}
              </>
            )}
            {/* For business announcements */}
            {isBusinessAd && (
              <div className="business-info-section">
                <h3>Detalii anunț Business</h3>
                <p>
                  <strong>Nivel experienta:</strong> {service.experienceLevel}
                </p>
                <p>
                  <strong>Tip plata:</strong> {service.priceType}
                </p>
                <p>
                  <strong>Buget pe:</strong> {service.pricePeriod}
                </p>
                <p>
                  <strong>Număr persoane căutate:</strong>{" "}
                  {service.numberOfPeople}
                </p>
                <p>
                  <strong>Interval de vârstă:</strong> {service.ageRange}
                </p>
                {service.cvRequired && (
                  <button className="upload-cv-btn" onClick={handleUploadCV}>
                    Încarcă CV
                  </button>
                )}
              </div>
            )}

            <p>
              <strong>Proprietar:</strong>{" "}
              {isBusinessAd ? (
                <Link
                  to={`/businessProfile/${service.businessId}`}
                  className="owner-link">
                  {service.fullName}
                </Link>
              ) : (
                <Link to={`/user/${service.uid}`} className="owner-link">
                  {ownerName}
                </Link>
              )}
            </p>
            <p>
              <strong>Vizualizări:</strong> {service.views || 0}
            </p>

            <div className="share-buttons">
              <a
                href={shareOnWhatsApp}
                className="action-btn share whatsapp"
                target="_blank"
                rel="noopener noreferrer">
                Distribuie pe WhatsApp
              </a>
              <a
                href={shareOnFacebook}
                className="action-btn share facebook"
                target="_blank"
                rel="noopener noreferrer">
                Distribuie pe Facebook
              </a>
            </div>
          </div>
          <div className="modal-map">
            <iframe
              title="Service Location"
              src={googleMapLink}
              allowFullScreen
              loading="lazy"></iframe>
          </div>
        </div>
        {/* Footer */}
        {!accepted ? (
          <div className="modal-footer-ad">
            <div style={{ flex: "1", textAlign: "left" }}>
              <a
                href={reportAdLink}
                className="action-btn report"
                style={{
                  backgroundColor: "#e74c3c",
                  color: "#fff",
                  textDecoration: "none",
                }}>
                Raportează Anunț
              </a>
            </div>
            <div className="footer-buttons-modal-accept">
              <button className="action-btn close" onClick={onClose}>
                {t("modal.close")}
              </button>
              {!isOwner && (
                <button
                  className="action-btn accept"
                  onClick={() => {
                    if (!currentUserId) {
                      // Show a popup alert or toast message indicating that the user must be logged in
                      toast.warning(
                        "Trebuie să fii autentificat pentru a aplica."
                      );
                      // Alternatively, if you prefer using toast notifications:
                      // toast.error("Trebuie să fii autentificat pentru a aplica.");
                      return;
                    }
                    openTermsModal();
                  }}>
                  {isBusinessAd ? "Aplica" : "Te ajut!"}
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="modal-footer-ad">
            <div className="contact-owner">
              <p>Contactează proprietarul anunțului:</p>
              <div className="contact-owner-buttons">
                {ownerPhone && (
                  <a
                    className="action-btn whatsapp"
                    href={`https://wa.me/${ownerPhone}?text=${encodeURIComponent(
                      "Salut, am acceptat anunțul: " + service.title
                    )}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    WhatsApp: {ownerPhone}
                  </a>
                )}
                {ownerEmail && (
                  <a
                    className="action-btn email"
                    href={`mailto:${ownerEmail}?subject=Interes pentru anunțul ${service.title}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    Email: {ownerEmail}
                  </a>
                )}
              </div>
            </div>
            <button className="action-btn close" onClick={onClose}>
              {t("modal.close")}
            </button>
          </div>
        )}
        {showTermsModal && (
          <TermsModal
            showModal={showTermsModal}
            closeModal={closeTermsModal}
            onAccept={confirmTerms}
            onReject={closeTermsModal}
          />
        )}
      </div>
    </div>
  );
};

export default Modal;
