import React, { useState } from "react";
import { updateDoc, doc } from "firebase/firestore";
import "../styling/UpdateInfoModal.css";

const UpdateInfoModal = ({ businessData, uid, onClose, db }) => {
  // Initialize the form with all business data fields.
  const [formData, setFormData] = useState(businessData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      await updateDoc(doc(db, "business_users", uid), {
        ...formData,
        updatedAt: new Date(),
      });
      onClose();
    } catch (err) {
      console.error("Error updating business info:", err);
      setError("A apărut o eroare. Încearcă din nou.");
    }
    setLoading(false);
  };

  return (
    <div className="modal-overlay-business">
      <div className="modal-content-business">
        <button className="close-button-modal" onClick={onClose}>
          x
        </button>
        <h2>Actualizează informațiile</h2>
        <form onSubmit={handleSubmit}>
          {/* Informații despre societate */}
          <h3>Informații despre societate</h3>
          <div className="form-group-modal-business">
            <label>Denumirea completă a societății</label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group-modal-business">
            <label>CUI</label>
            <input
              type="text"
              name="cui"
              value={formData.cui}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group-modal-business">
            <label>Numărul de înregistrare la Registrul Comerțului</label>
            <input
              type="text"
              name="registrationNumber"
              value={formData.registrationNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group-modal-business">
            <label>Sediul social</label>
            <textarea
              name="headquarters"
              value={formData.headquarters}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group-modal-business">
            <label>Website-ul companiei</label>
            <input
              type="url"
              name="website"
              value={formData.website}
              onChange={handleChange}
              pattern="https://.+"
              title="Website-ul trebuie să înceapă cu https://"
            />
          </div>

          {/* Date de contact */}
          <h3>Date de contact</h3>
          <div className="form-group-modal-business">
            <label>Persoana de contact</label>
            <input
              type="text"
              name="contactPerson"
              value={formData.contactPerson}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group-modal-business">
            <label>Funcția sau poziția</label>
            <select
              name="contactPosition"
              value={formData.contactPosition}
              onChange={handleChange}
              required>
              <option value="">Selectează poziția</option>
              <option value="CEO">CEO</option>
              <option value="CFO">CFO</option>
              <option value="COO">COO</option>
              <option value="CTO">CTO</option>
              <option value="CMO">CMO</option>
              <option value="Administrator (51%+ acțiuni)">
                Administrator (51%+ acțiuni)
              </option>
              <option value="Other">Altul</option>
            </select>
          </div>
          <div className="form-group-modal-business">
            <label>Adresa de e-mail</label>
            <input
              type="email"
              name="contactEmail"
              value={formData.contactEmail}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group-modal-business">
            <label>Numărul de telefon</label>
            <input
              type="tel"
              name="contactPhone"
              value={formData.contactPhone}
              onChange={handleChange}
              required
            />
          </div>

          {/* Informații despre activitatea companiei */}
          <h3>Informații despre activitatea companiei</h3>
          <div className="form-group-modal-business">
            <label>Descrierea activității</label>
            <textarea
              name="activityDescription"
              value={formData.activityDescription}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group-modal-business">
            <label>Domeniul de activitate</label>
            <select
              name="activityField"
              value={formData.activityField}
              onChange={handleChange}
              required>
              <option value="">Selectează domeniul</option>
              <option value="Agricultură și silvicultură">
                Agricultură și silvicultură
              </option>
              <option value="Comerț cu amănuntul">Comerț cu amănuntul</option>
              <option value="Comerț cu ridicata">Comerț cu ridicata</option>
              <option value="Construcții">Construcții</option>
              <option value="Energie și utilități">Energie și utilități</option>
              <option value="Finanțe și asigurări">Finanțe și asigurări</option>
              <option value="Industrie manufacturieră">
                Industrie manufacturieră
              </option>
              <option value="IT și telecomunicații">
                IT și telecomunicații
              </option>
              <option value="Medicină și sănătate">Medicină și sănătate</option>
              <option value="Servicii profesionale">
                Servicii profesionale
              </option>
              <option value="Transport și logistică">
                Transport și logistică
              </option>
              <option value="Turism și agrement">Turism și agrement</option>
            </select>
          </div>

          {/* Informații despre reprezentanții legali */}
          <h3>Informații despre reprezentanții legali</h3>
          <div className="form-group-modal-business">
            <label>Numele reprezentantului legal</label>
            <input
              type="text"
              name="legalRepresentativeName"
              value={formData.legalRepresentativeName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group-modal-business">
            <label>Funcția reprezentantului legal</label>
            <select
              name="legalRepresentativePosition"
              value={formData.legalRepresentativePosition}
              onChange={handleChange}
              required>
              <option value="">Selectează funcția</option>
              <option value="Administrator">Administrator</option>
              <option value="Director General">Director General</option>
              <option value="Consilier juridic">Consilier juridic</option>
              <option value="Avocat">Avocat</option>
              <option value="Manager">Manager</option>
              <option value="Other">Altul</option>
            </select>
          </div>
          <div className="form-group-modal-business">
            <label>
              Date de contact ale reprezentantului legal (ex.: email, telefon)
            </label>
            <input
              type="text"
              name="legalRepresentativeContact"
              value={formData.legalRepresentativeContact}
              onChange={handleChange}
              placeholder="ex.: legal@example.com, 0712345678"
              required
            />
          </div>

          {/* Informații suplimentare */}
          <h3>Informații suplimentare</h3>
          <div className="form-group-modal-business">
            <label>Feedback sau cerințe speciale</label>
            <textarea
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
            />
          </div>

          {error && <p className="error">{error}</p>}
          <div className="modal-buttons-business">
            <button
              type="button"
              onClick={onClose}
              className="cancel-button-business">
              Anulează
            </button>
            <button
              type="submit"
              disabled={loading}
              className="submit-button-business">
              {loading ? "Se salvează..." : "Salvează"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdateInfoModal;
