import React from "react";
import { useParams, useLocation } from "react-router-dom";
import OnboardingForm from "./OnboardingForm";
import BusinessDashboard from "./BusinessDashboard";

const BusinessPage = () => {
  const { uid } = useParams();
  const { state } = useLocation();
  // Dacă flag-ul onboarding este true, înseamnă că utilizatorul trebuie să completeze formularul.
  const onboarding = state?.onboarding;

  return (
    <div>
      {onboarding ? (
        <OnboardingForm uid={uid} />
      ) : (
        <BusinessDashboard uid={uid} />
      )}
    </div>
  );
};

export default BusinessPage;
