import React, { useState, useEffect } from "react";
import "../styling/TaskCarousel.css";
import categoriesData from "../data/categories.json"; // Import categories

import { firestore } from "../data/firebase"; // Import Firestore
import { doc, setDoc, deleteDoc, getDoc, collection } from "firebase/firestore";

const TaskCarousel = ({ services, onCardClick, currentUserId }) => {
  // Filter active services (exclude finalized ones)
  const activeServices = React.useMemo(
    () => services.filter((service) => service.status !== "finalized"),
    [services]
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [widgetSelection, setWidgetSelection] = useState("socialFeed");

  const [blogPosts, setBlogPosts] = useState([]);
  const [blogPostIndex, setBlogPostIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  // New state for social posts slideshow index
  const [socialPostIndex, setSocialPostIndex] = useState(0);

  // New state to track touch start position for swiping
  const [touchStart, setTouchStart] = useState(null);
  const swipeThreshold = 50; // Minimum swipe distance in pixels

  // Swipe event handlers
  const handleTouchStart = (e) => {
    setTouchStart(e.changedTouches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEnd = e.changedTouches[0].clientX;
    if (touchStart === null) return;
    const diff = touchStart - touchEnd;
    if (Math.abs(diff) > swipeThreshold) {
      if (diff > 0) {
        // Swiped left → next card
        handleNext();
      } else {
        // Swiped right → previous card
        handlePrev();
      }
    }
    setTouchStart(null);
  };

  // Static array for LinkedIn embed frames
  const linkedinFrames = [
    {
      id: "1",
      src: "https://www.linkedin.com/embed/feed/update/urn:li:share:7302919572993540097",
    },
    {
      id: "2",
      src: "https://www.linkedin.com/embed/feed/update/urn:li:share:7300432011943407616",
    },
    {
      id: "3",
      src: "https://www.linkedin.com/embed/feed/update/urn:li:share:7299805059872124928",
    },
  ];

  // Dummy blog posts for example
  const dummyBlogPosts = [
    {
      id: "1",
      title: "Ce este Aidly.ro?",
      excerpt:
        "Aidly.ro este o platformă inovatoare care conectează oamenii care au nevoie de ajutor pentru diverse sarcini zilnice cu cei care sunt dispuși să le ofere...",
      link: "https://aidly.ro/blog/despre-aidly/cum-functioneaza-aidly-ro",
    },
    {
      id: "2",
      title: "Cum să postezi o sarcină pe Aidly.ro – Ghid Pas cu Pas 🚀",
      excerpt:
        "Pentru a începe, accesează Aidly.ro și conectează-te la contul tău. Dacă nu ai încă un cont, va trebui să îți creezi unul – durează doar câteva minute!",
      link: "https://aidly.ro/blog/ghid-utilizare/cum-postezi-sarcina-aidly",
    },
    {
      id: "3",
      title:
        "Cum să Instalezi Aidly pe Telefon – Ca o Aplicație, Fără să descarci nimic!",
      excerpt:
        "Aidly funcționează ca o aplicație mobilă datorită tehnologiei PWA (Progressive Web App). Asta înseamnă că o poți instala direct pe telefon, fără să cauți în App Store sau Google Play. E rapid, simplu și util.",
      link: "https://aidly.ro/blog/aidly-utilizatori/instaleaza-aidly-telefon",
    },
  ];

  // Fetch blog posts when widgetSelection is "blogPosts"
  useEffect(() => {
    if (widgetSelection === "blogPosts") {
      setBlogPosts(dummyBlogPosts);
    }
  }, [widgetSelection]);

  // Auto-update blogPostIndex every 5 seconds if in mobile view and widget is blogPosts
  useEffect(() => {
    let intervalId;
    if (widgetSelection === "blogPosts" && isMobile && blogPosts.length > 1) {
      intervalId = setInterval(() => {
        setBlogPostIndex((prev) => (prev + 1) % blogPosts.length);
      }, 5000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [widgetSelection, isMobile, blogPosts]);

  // Auto-update socialPostIndex every 5 seconds if in mobile view and widget is socialFeed
  useEffect(() => {
    let intervalId;
    if (
      widgetSelection === "socialFeed" &&
      isMobile &&
      linkedinFrames.length > 1
    ) {
      intervalId = setInterval(() => {
        setSocialPostIndex((prev) => (prev + 1) % linkedinFrames.length);
      }, 5000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [widgetSelection, isMobile, linkedinFrames]);

  // Listen for window resize events to update isMobile state
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Reset index when activeServices changes
  useEffect(() => {
    setCurrentIndex(0);
  }, [activeServices]);

  const currentTask = activeServices[currentIndex];
  const nextTask =
    activeServices.length > 0
      ? activeServices[(currentIndex + 1) % activeServices.length]
      : null;
  const prevTask =
    activeServices.length > 0
      ? activeServices[
          (currentIndex - 1 + activeServices.length) % activeServices.length
        ]
      : null;

  const handleNext = () => {
    if (typeof window.gtag === "function") {
      window.gtag("event", "next_button_click", {
        event_category: "Carousel",
        event_label: "Next Button Clicked",
      });
    }
    setCurrentIndex((prev) => (prev + 1) % activeServices.length);
  };

  const handlePrev = () => {
    if (typeof window.gtag === "function") {
      window.gtag("event", "prev_button_click", {
        event_category: "Carousel",
        event_label: "Previous Button Clicked",
      });
    }
    setCurrentIndex(
      (prev) => (prev - 1 + activeServices.length) % activeServices.length
    );
  };

  // Get finalized tasks for the "completedTasks" widget
  useEffect(() => {
    const finalizedTasks = services.filter(
      (service) => service.status === "finalized"
    );
    const lastThree = finalizedTasks.slice(-3);
    setCompletedTasks(lastThree);
  }, [services]);

  return (
    <div className="task-carousel-container">
      {/* If there are no active services, render the NoAdsCard */}
      {activeServices.length === 0 ? (
        <NoAdsCard />
      ) : (
        <div className="main-carousel">
          <button className="nav-button prev" onClick={handlePrev}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path d="M15.293 3.293 6.586 12l8.707 8.707 1.414-1.414L9.414 12l7.293-7.293-1.414-1.414z" />
            </svg>
          </button>

          {/* Swipe events added on carousel-content */}
          <div
            className="carousel-content"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}>
            <div className="carousel-card prev-card">
              <CarouselCard
                task={prevTask}
                isActive={false}
                currentUserId={currentUserId}
                onCardClick={onCardClick}
              />
            </div>

            <div
              className="carousel-card current-card"
              onClick={() => onCardClick(currentTask)}>
              <CarouselCard
                task={currentTask}
                isActive={true}
                currentUserId={currentUserId}
                onCardClick={onCardClick}
              />
            </div>

            <div className="carousel-card next-card">
              <CarouselCard
                task={nextTask}
                isActive={false}
                currentUserId={currentUserId}
                onCardClick={onCardClick}
              />
            </div>
          </div>

          <button className="nav-button next" onClick={handleNext}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <path d="M7.293 4.707 14.586 12l-7.293 7.293 1.414 1.414L17.414 12 8.707 3.293 7.293 4.707z" />
            </svg>
          </button>
        </div>
      )}
      {/* Widget Container */}
      <div className="widget-container">
        <div className="widget-header">
          <h3>Alege ce vrei să vezi</h3>
          <select
            className="selector-widget"
            value={widgetSelection}
            onChange={(e) => setWidgetSelection(e.target.value)}>
            <option value="blogPosts">Ultimele 3 postări pe blog</option>
            <option value="completedTasks">Ultimele anunțuri completate</option>
            <option value="socialFeed">
              Cele mai importante postări pe LinkedIn
            </option>
          </select>
        </div>
        <div className="widget-content">
          {widgetSelection === "blogPosts" && (
            <>
              {isMobile ? (
                // Mobile: single card slideshow for blog posts
                <div className="widget-single">
                  {blogPosts.length > 0 ? (
                    <BlogPostCard post={blogPosts[blogPostIndex]} full={true} />
                  ) : (
                    <div className="widget-card">No blog posts available</div>
                  )}
                </div>
              ) : (
                // Desktop: grid of blog posts
                <div className="widget-grid">
                  {blogPosts.length > 0 ? (
                    blogPosts.map((post) => (
                      <BlogPostCard key={post.id} post={post} />
                    ))
                  ) : (
                    <div className="widget-card">No blog posts available</div>
                  )}
                </div>
              )}
            </>
          )}

          {widgetSelection === "completedTasks" && (
            <div className="widget-grid">
              {completedTasks.map((task) => (
                <CompletedTaskCard
                  key={task.id}
                  task={task}
                  onClick={() => onCardClick(task)}
                />
              ))}
            </div>
          )}

          {widgetSelection === "socialFeed" && (
            <>
              {isMobile ? (
                // Mobile: single card slideshow for LinkedIn posts
                <div className="widget-single">
                  <div className="widget-card linkedin-post-card">
                    <iframe
                      src={linkedinFrames[socialPostIndex].src}
                      style={{ width: "100%", height: "100%" }}
                      frameBorder="0"
                      allowFullScreen
                      title="Embedded LinkedIn post"></iframe>
                  </div>
                </div>
              ) : (
                // Desktop: grid of LinkedIn posts
                <div className="widget-grid">
                  {linkedinFrames.map((post) => (
                    <div
                      key={post.id}
                      className="widget-card linkedin-post-card">
                      <iframe
                        src={post.src}
                        style={{ width: "100%", height: "100%" }}
                        frameBorder="0"
                        allowFullScreen
                        title="Embedded LinkedIn post"></iframe>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const CarouselCard = ({ task, isActive, currentUserId, onCardClick }) => {
  if (!task) return null;

  // Find the category from the JSON
  const category = categoriesData.categories.find(
    (cat) => cat.name === task.category
  );
  if (!category) return null;

  const imageUrl = category.image;
  const isBusinessAnnouncement = Boolean(task.businessId);

  // State for handling favorite status
  const [isFavorite, setIsFavorite] = useState(false);

  const createNotification = async (notificationData) => {
    try {
      const notificationRef = doc(collection(firestore, "notifications"));
      await setDoc(notificationRef, {
        ...notificationData,
        createdAt: new Date(),
        read: false,
      });
    } catch (error) {
      console.error("Error creating notification:", error);
    }
  };

  useEffect(() => {
    if (currentUserId) {
      checkFavoriteStatus();
    }
  }, [currentUserId, task.id]);

  // Check if the ad is already in the favorites collection
  const checkFavoriteStatus = async () => {
    try {
      const favoriteDoc = await getDoc(
        doc(firestore, "favorites", `${currentUserId}_${task.id}`)
      );
      setIsFavorite(favoriteDoc.exists());
    } catch (error) {
      console.error("Error checking favorite status:", error);
    }
  };

  // Toggle favorite status (Add/Remove from Firestore)
  const toggleFavorite = async (event) => {
    event.stopPropagation();

    if (!currentUserId || task.businessId === currentUserId) return;

    const favoriteRef = doc(
      firestore,
      "favoritesNotifications",
      `${currentUserId}_${task.id}`
    );

    try {
      if (isFavorite) {
        await deleteDoc(favoriteRef);
        setIsFavorite(false);
      } else {
        const adRef = doc(firestore, "ads", task.id);
        const adSnap = await getDoc(adRef);

        if (!adSnap.exists()) {
          console.error("Ad not found in Firestore!");
          return;
        }

        const ownerId = adSnap.data().uid || "Unknown";

        let ownerName = "Unknown";
        if (ownerId !== "Unknown") {
          const userRef = doc(firestore, "users", ownerId);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            ownerName = userSnap.data().displayName || "Unknown";
          }
        }

        // Get the current user's name who liked the ad
        let likedByName = "Unknown";
        const currentUserRef = doc(firestore, "users", currentUserId);
        const currentUserSnap = await getDoc(currentUserRef);
        if (currentUserSnap.exists()) {
          likedByName = currentUserSnap.data().displayName || "Unknown";
        }

        await setDoc(favoriteRef, {
          userId: currentUserId,
          adId: task.id,
          title: task.title || "Untitled",
          price: task.price || 0,
          location: task.location || "Unknown",
          category: task.category || "Uncategorized",
          imageUrl: imageUrl || "",
          ownerId,
          ownerName,
          likedByName,
          createdAt: new Date(),
        });

        setIsFavorite(true);

        // ✅ Create Notification with the name of the person who liked the ad
        await createNotification({
          type: "favorite",
          adId: task.id,
          adTitle: task.title,
          adLocation: task.location,
          adCategory: task.category,
          adBudget: task.price,
          adOwnerId: ownerId,
          adOwnerName: ownerName,
          favoritedByUserId: currentUserId,
          favoritedByUserName: likedByName, // ← Store this clearly
        });
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  return (
    <div
      className={`carousel-card-content ${isActive ? "active" : ""} ${
        isBusinessAnnouncement ? "business-announcement" : ""
      }`}
      onClick={() => onCardClick(task)} // Opens details modal when clicking elsewhere
    >
      <img src={imageUrl} alt={task.title} />

      {isBusinessAnnouncement && (
        <div className="business-badge">Anunț Business</div>
      )}
      {currentUserId &&
        task.businessId &&
        task.businessId === currentUserId && (
          <div className="owner-badge-carousel">Anunțul Tău!</div>
        )}

      <div className="card-overlay">
        <h2 className="title-card-carousel">{task.title}</h2>
        <div className="card-details">
          <span className="price">{task.price} RON</span>
          <span className="location">{task.location}</span>
        </div>
        {isActive && (
          <div className="click-indicator">Click pentru detalii</div>
        )}
      </div>
      {/* Favorite Heart Icon - Only show if user is not the owner */}
      {currentUserId && task.businessId !== currentUserId && (
        <div className="favorite-icon" onClick={toggleFavorite}>
          {isFavorite ? "❤️" : "🤍"}
        </div>
      )}
    </div>
  );
};

const CompletedTaskCard = ({ task, onClick }) => {
  if (task.status !== "finalized") return null;

  // Find the category from JSON
  const category = categoriesData.categories.find(
    (cat) => cat.name === task.category
  );
  if (!category) return null;

  const imageUrl = category.image;

  // Helper function to capitalize the first letter
  const capitalizeFirstLetter = (str) =>
    str ? str.charAt(0).toUpperCase() + str.slice(1) : "";

  return (
    <div className="completed-task-card" onClick={onClick}>
      <img src={imageUrl} alt={task.title} />
      <div className="completed-card-content">
        <h4>{capitalizeFirstLetter(task.title)}</h4>
      </div>
    </div>
  );
};

const BlogPostCard = ({ post, full }) => {
  return (
    <div className="widget-card blog-post-card">
      <h4>{post.title}</h4>
      <p>{post.excerpt}</p>

      <a href={post.link} target="_blank" rel="noopener noreferrer">
        Citește mai mult
      </a>
    </div>
  );
};

const NoAdsCard = () => {
  return (
    <div className="carousel-card-content no-ads-card">
      <div className="card-overlay">
        <h2 className="title-no-ads">
          Nu exista anunțuri pentru filtrul aplicat.
        </h2>
      </div>
    </div>
  );
};

export default TaskCarousel;
