import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  collection,
  query,
  addDoc,
  doc,
  where,
  deleteDoc,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { firestore } from "../data/firebase.js";
import categoriesData from "../data/categories.json";
import Filters from "../components/Filters.jsx";
import Modal from "../components/Modal.jsx";
import { getAuth } from "firebase/auth";
import "../styling/services.css";
import { toast } from "react-toastify";
import TaskCarousel from "../pages/TaskCarousel.jsx"; // Import TaskCarousel
import ReviewModal from "../components/ReviewModal.jsx"; // Import ReviewModal

const Services = ({ currentUserId }) => {
  // State Variables
  const [ads, setAds] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [priceRange, setPriceRange] = useState([50, 1000]);
  const [priceLimits, setPriceLimits] = useState([50, 1000]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Adaugă această linie în partea de state din Services:
  const [isUrgent, setIsUrgent] = useState(false);

  const [selectedService, setSelectedService] = useState(null);
  const [favorites, setFavorites] = useState([]);
  // State for toggling filter visibility
  const [showOnlyAnnouncements, setShowOnlyAnnouncements] = useState(false);

  const [showFilters, setShowFilters] = useState(false);

  const auth = getAuth();
  const { category } = useParams();
  const servicesRef = useRef(null);

  // Normalize Strings for Search
  const normalizeString = (str) =>
    str
      ?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

  const fetchPriceRange = useCallback(async () => {
    try {
      const adsCollection = collection(firestore, "ads");
      const querySnapshot = await getDocs(adsCollection);

      let minPrice = Infinity;
      let maxPrice = -Infinity;

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (typeof data?.price === "number") {
          if (data.price < minPrice) minPrice = data.price;
          if (data.price > maxPrice) maxPrice = data.price;
        }
      });

      if (minPrice === Infinity || maxPrice === -Infinity) {
        return [50, 1000];
      }

      return [minPrice, maxPrice];
    } catch (error) {
      console.error("Error fetching price range:", error);
      return [50, 1000];
    }
  }, []);

  // Fetch All Ads (inițial fără filtre)
  const fetchAllAds = useCallback(async () => {
    try {
      const now = Timestamp.now();

      // 1. Query pentru `ads`
      let qAds = query(
        collection(firestore, "ads"),
        where("validity", ">=", now),
        where("status", "in", ["posted", "accepted", "finalized"])
      );
      const querySnapshotAds = await getDocs(qAds);

      const allAds = querySnapshotAds.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        source: "ads", // opțional, ca să știi de unde vine
      }));

      // 2. Query pentru `announcements` (anunțurile business)
      //    Ajustează dacă vrei să filtrezi după status, date etc.
      let qAnnouncements = query(
        collection(firestore, "announcements"),

        where("status", "in", ["posted", "accepted", "inProgress"])
      );
      const querySnapshotAnn = await getDocs(qAnnouncements);
      const allAnnouncements = querySnapshotAnn.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        source: "announcements", // opțional
        // Poți adăuga un status, ex: status: "posted" sau altceva
        // Poți adăuga și "price" dacă e relevant pentru filtrare
      }));

      // 3. Combină array-urile
      const combined = [...allAds, ...allAnnouncements];

      setAds(combined);
      setFilteredAds(combined);

      // 4. Setezi range-ul de preț
      const prices = combined
        .map((ad) => ad.price)
        .filter((price) => typeof price === "number");
      if (prices.length > 0) {
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        setPriceLimits([minPrice, maxPrice]);
        setPriceRange([minPrice, maxPrice]);
      } else {
        setPriceLimits([50, 1000]);
        setPriceRange([50, 1000]);
      }
    } catch (error) {
      console.error("Error fetching ads/announcements:", error);
      setPriceLimits([50, 1000]);
      setPriceRange([50, 1000]);
    }
  }, []);

  // Funcția pentru aplicarea filtrelor, actualizată:
  const handleApplyFilters = async (filters) => {
    setSearchTerm(filters.searchText || "");
    setSelectedCategory(filters.selectedCategory || "");
    setPriceRange(filters.priceRange || [priceLimits[0], priceLimits[1]]);
    setShowOnlyAnnouncements(filters.showOnlyAnnouncements || false);
    setIsUrgent(filters.isUrgent || false);

    try {
      const now = Timestamp.now();

      // Fetch both ads and announcements
      let qAds = query(
        collection(firestore, "ads"),
        where("validity", ">=", now),
        where("status", "in", ["posted", "accepted", "finalized"])
      );

      let qAnnouncements = query(
        collection(firestore, "announcements"),
        where("status", "in", ["posted", "inProgress"])
      );

      const [querySnapshotAds, querySnapshotAnn] = await Promise.all([
        getDocs(qAds),
        getDocs(qAnnouncements),
      ]);

      let allAds = querySnapshotAds.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        source: "ads",
      }));

      let allAnnouncements = querySnapshotAnn.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        source: "announcements",
      }));

      let combined = [...allAds, ...allAnnouncements];

      // 📌 Apply Filters to both ads and announcements

      if (filters.searchText) {
        const normalizedSearchText = filters.searchText.toLowerCase();
        combined = combined.filter(
          (item) =>
            (item.title || "").toLowerCase().includes(normalizedSearchText) ||
            (item.description || "")
              .toLowerCase()
              .includes(normalizedSearchText)
        );
      }

      if (filters.selectedCategory) {
        combined = combined.filter(
          (item) => item.category === filters.selectedCategory
        );
      }

      if (filters.priceRange && filters.priceRange.length === 2) {
        combined = combined.filter(
          (item) =>
            item.price >= filters.priceRange[0] &&
            item.price <= filters.priceRange[1]
        );
      }

      if (filters.location) {
        combined = combined.filter(
          (item) => item.location === filters.location
        );
      }

      // Only if location === "Bucuresti" and filters.sector is non-empty
      if (filters.location === "Bucuresti" && filters.sector) {
        combined = combined.filter((item) => item.sector === filters.sector);
      }

      if (filters.validity) {
        const selectedDate = new Date(filters.validity);
        combined = combined.filter((item) => {
          const itemDate = new Date(item.validity);
          return itemDate >= selectedDate;
        });
      }

      if (filters.estimatedDuration) {
        combined = combined.filter(
          (item) => item.estimatedDuration === filters.estimatedDuration
        );
      }

      if (filters.paymentType) {
        combined = combined.filter(
          (item) => item.paymentType === filters.paymentType
        );
      }

      if (filters.experienceLevel) {
        combined = combined.filter(
          (item) => item.experienceLevel === filters.experienceLevel
        );
      }

      if (filters.isUrgent) {
        console.log("Filtr urgent activat, anunțurile înainte:", combined);
        combined = combined.filter((item) => item.isUrgent === true);
        console.log("Anunțurile după filtrarea urgentă:", combined);
      }

      // Apply filters only for announcements
      if (filters.showOnlyAnnouncements) {
        combined = combined.filter((item) => item.source === "announcements");
      }

      if (filters.cvRequiredFilter) {
        combined = combined.filter((item) => item.cvRequired === true);
      }

      if (filters.numberOfPeople) {
        combined = combined.filter(
          (item) =>
            Number(item.numberOfPeople) >= Number(filters.numberOfPeople)
        );
      }

      setAds(combined);
      setFilteredAds(combined);
    } catch (error) {
      console.error("Error applying filters:", error);
    }
  };

  // Handle Favorites și restul codului rămân neschimbate...
  const handleFavoriteClick = async (service) => {
    if (!currentUserId) {
      toast.error(
        "Trebuie să fii autentificat pentru a putea adăuga la favorite."
      );
      return;
    }

    if (!service?.id) {
      console.error("Eroare: ID-ul anunțului este invalid sau inexistent.");
      toast.error("Eroare: ID-ul anunțului este invalid.");
      return;
    }

    try {
      const favoritesCollection = collection(firestore, "favorites");
      const favoriteQuery = query(
        favoritesCollection,
        where("userId", "==", currentUserId),
        where("adId", "==", service.id)
      );

      const snapshot = await getDocs(favoriteQuery);
      if (!snapshot.empty) {
        const favoriteDocId = snapshot.docs[0].id;
        const favoriteDocRef = doc(firestore, "favorites", favoriteDocId);
        await deleteDoc(favoriteDocRef);
        setFavorites((prevFavorites) =>
          prevFavorites.filter((id) => id !== service.id)
        );
        toast.info("Anunțul a fost eliminat din favorite.");

        if (window.gtag) {
          window.gtag("event", "remove_favorite", {
            event_category: "Favorites",
            event_label: `Ad ID: ${service.id}`,
          });
        }
      } else {
        const favoriteData = {
          userId: currentUserId,
          adId: service.id,
          ownerUid: service.uid || null,
          title: service.title || "Fără titlu",
          price: service.price || 0,
          timestamp: new Date(),
        };

        await addDoc(favoritesCollection, favoriteData);
        setFavorites((prevFavorites) => [...prevFavorites, service.id]);
        toast.success("Anunțul a fost adăugat la favorite.");

        if (window.gtag) {
          window.gtag("event", "add_favorite", {
            event_category: "Favorites",
            event_label: `Ad ID: ${service.id}`,
          });
        }
      }
    } catch (error) {
      console.error("Eroare la actualizarea favoritului:", error);
      toast.error("Eroare la actualizarea favoritului. Încearcă din nou.");
    }
  };

  useEffect(() => {
    fetchAllAds();
  }, [fetchAllAds]);

  useEffect(() => {
    setSelectedCategory(category || "");
  }, [category]);

  useEffect(() => {
    const fetchFavorites = async () => {
      if (!currentUserId) return;
      try {
        const favoritesCollection = collection(firestore, "favorites");
        const favoriteQuery = query(
          favoritesCollection,
          where("userId", "==", currentUserId)
        );
        const snapshot = await getDocs(favoriteQuery);
        const favoriteIds = snapshot.docs.map((doc) => doc.data().adId);
        setFavorites(favoriteIds);
      } catch (error) {
        console.error("Error fetching favorites:", error);
      }
    };

    fetchFavorites();
  }, [currentUserId]);

  return (
    <div id="services" ref={servicesRef} className="services-page">
      {/* Button to toggle filter visibility */}
      <div className="filters-header">
        {/* Button to toggle filter visibility */}
        <button
          className="toggle-filter-btn"
          onClick={() => setShowFilters(!showFilters)}>
          {showFilters ? "Ascunde" : "Filtre"}
        </button>
      </div>

      {/* Filters component, conditionally rendered */}
      {showFilters && (
        <div className="filters-container">
          <Filters
            categories={categoriesData.categories}
            onApplyFilters={handleApplyFilters}
            fetchPriceRange={fetchPriceRange}
            minPrice={priceLimits[0] || 50}
            maxPrice={priceLimits[1] || 1000}
          />
        </div>
      )}

      {/* TaskCarousel */}
      <TaskCarousel
        services={filteredAds}
        onCardClick={(service) => {
          setSelectedService(service);
          setIsModalOpen(true);
        }}
        currentUserId={auth.currentUser?.uid}
        favorites={favorites}
        handleFavoriteClick={handleFavoriteClick}
      />

      {/* Modal pentru detalii sau recenzii */}
      {isModalOpen &&
        selectedService &&
        (selectedService.status === "finalized" ? (
          <ReviewModal
            service={selectedService}
            onClose={() => setIsModalOpen(false)}
          />
        ) : (
          <Modal
            service={selectedService}
            onClose={() => setIsModalOpen(false)}
            currentUserId={auth.currentUser?.uid}
          />
        ))}
    </div>
  );
};

export default Services;
