import React, { useState, useEffect } from "react";
import locationsData from "../data/locations.json"; // JSON file with cities and sectors
import { FaSearch } from "react-icons/fa";
import "../styling/filters.css";

const Filters = ({
  categories,
  fetchPriceRange,
  onApplyFilters,
  initialFilters,
}) => {
  // State for general (non-business) filters
  const [priceRange, setPriceRange] = useState([
    initialFilters?.minPrice || 50,
    initialFilters?.maxPrice || 1000,
  ]);
  const [currentRange, setCurrentRange] = useState([
    initialFilters?.minPrice || 50,
    initialFilters?.maxPrice || 1000,
  ]);
  const [selectedCategory, setSelectedCategory] = useState(
    initialFilters?.selectedCategory || ""
  );
  const [searchText, setSearchText] = useState(
    initialFilters?.searchText || ""
  );
  const [estimatedDuration, setEstimatedDuration] = useState(
    initialFilters?.estimatedDuration || ""
  );
  const [sector, setSector] = useState(initialFilters?.sector || "");
  const [paymentType, setPaymentType] = useState(
    initialFilters?.paymentType || ""
  );
  const [experienceLevel, setExperienceLevel] = useState(
    initialFilters?.experienceLevel || ""
  );
  const [isUrgent, setIsUrgent] = useState(initialFilters?.isUrgent || false);
  const [location, setLocation] = useState(initialFilters?.location || "");
  const [taskDueDate, setTaskDueDate] = useState(
    initialFilters?.taskDueDate || ""
  );

  // State for business filters
  const [businessFilter, setBusinessFilter] = useState(
    initialFilters?.businessFilter || false
  );
  const [cvRequiredFilter, setCvRequiredFilter] = useState(
    initialFilters?.cvRequiredFilter || false
  );
  const [numberOfPeople, setNumberOfPeople] = useState(
    initialFilters?.numberOfPeople || ""
  );
  const [showOnlyAnnouncements, setShowOnlyAnnouncements] = useState(
    initialFilters?.showOnlyAnnouncements || false
  );

  // Get the price range from DB
  useEffect(() => {
    const getPriceRange = async () => {
      const [minPriceFromDB, maxPriceFromDB] = await fetchPriceRange();
      setPriceRange([minPriceFromDB, maxPriceFromDB]);
      setCurrentRange([minPriceFromDB, maxPriceFromDB]);
    };
    getPriceRange();
  }, [fetchPriceRange]);

  // Auto-set "isUrgent" if taskDueDate is in ≤ 3 days
  useEffect(() => {
    if (taskDueDate) {
      const selectedDate = new Date(taskDueDate);
      const today = new Date();
      const diffDays = (selectedDate - today) / (1000 * 60 * 60 * 24);
      setIsUrgent(diffDays <= 3);
      applyFilters({ taskDueDate, isUrgent: diffDays <= 3 });
    }
  }, [taskDueDate]);

  const handlePriceChange = (value, index) => {
    const updatedRange = [...currentRange];
    updatedRange[index] = Number(value);
    if (updatedRange[0] <= updatedRange[1]) {
      setCurrentRange(updatedRange);
      applyFilters({ priceRange: updatedRange });
    }
  };

  // Modified getEffectiveLocation that always returns an array
  // In this example, getEffectiveLocation just returns the selected city.
  const getEffectiveLocation = () => location;

  // Function to apply filters dynamically
  const applyFilters = (overrides = {}) => {
    const filters = {
      searchText,
      selectedCategory,
      priceRange: currentRange,
      estimatedDuration,
      paymentType,
      experienceLevel,
      isUrgent,
      location: getEffectiveLocation(),
      // Include "sector" only if the selected city is "Bucuresti" and a sector was chosen.
      ...(location === "Bucuresti" && sector ? { sector } : {}),
      taskDueDate,
      businessFilter,
      cvRequiredFilter,
      numberOfPeople,
      showOnlyAnnouncements,
      ...overrides,
    };
    onApplyFilters(filters);
  };

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    applyFilters({ selectedCategory: value });
  };

  // Define a constant for Bucharest sectors (since your JSON does not provide them)
  const bucharestSectors = [
    "Sector 1",
    "Sector 2",
    "Sector 3",
    "Sector 4",
    "Sector 5",
    "Sector 6",
  ];

  const resetFilters = () => {
    const defaultFilters = {
      searchText: "",
      selectedCategory: "",
      priceRange: [priceRange[0], priceRange[1]],
      estimatedDuration: "",
      paymentType: "",
      experienceLevel: "",
      isUrgent: false,
      location: "",
      sector: "",
      taskDueDate: "",
      businessFilter: false,
      cvRequiredFilter: false,
      numberOfPeople: "",
      showOnlyAnnouncements: false,
    };

    setSearchText(defaultFilters.searchText);
    setSelectedCategory(defaultFilters.selectedCategory);
    setCurrentRange(defaultFilters.priceRange);
    setEstimatedDuration(defaultFilters.estimatedDuration);
    setPaymentType(defaultFilters.paymentType);
    setExperienceLevel(defaultFilters.experienceLevel);
    setIsUrgent(defaultFilters.isUrgent);
    setLocation(defaultFilters.location);
    setSector(defaultFilters.sector);
    setTaskDueDate(defaultFilters.taskDueDate);
    setBusinessFilter(defaultFilters.businessFilter);
    setCvRequiredFilter(defaultFilters.cvRequiredFilter);
    setNumberOfPeople(defaultFilters.numberOfPeople);
    setShowOnlyAnnouncements(defaultFilters.showOnlyAnnouncements);
    onApplyFilters(defaultFilters);
  };

  // Sort categories alphabetically
  const sortedCategories = categories.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const handleShowOnlyAnnouncementsChange = (e) => {
    const newValue = e.target.checked;
    setShowOnlyAnnouncements(newValue);
    applyFilters({ showOnlyAnnouncements: newValue });
  };

  return (
    <div className="filters-search">
      {/* Toggle: Afișează doar anunțuri */}
      {/* <div className="filter-item checkbox-item">
        <label className="filter-label" htmlFor="showOnlyAnnouncements">
          Afișează doar anunțuri Business
        </label>
        <input
          id="showOnlyAnnouncements"
          type="checkbox"
          checked={showOnlyAnnouncements}
          onChange={handleShowOnlyAnnouncementsChange}
        />
      </div> */}

      <div className="topline-filter">
        {/* Search Bar */}
        <div className="filter-item search-bar">
          <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Caută în titlu"
            className="filter-input-search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              applyFilters({ searchText: e.target.value });
            }}
          />
        </div>

        {/* Category Selector */}
        <div className="filter-item-search">
          <select
            className="filter-select-search"
            value={selectedCategory}
            onChange={(e) => handleCategoryChange(e.target.value)}>
            <option value="">Categorii</option>
            {sortedCategories.map((category, index) => (
              <option key={index} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        {/* Price Range Selector */}
        <div className="price-range">
          <div className="price-input-container">
            <div className="price-input-group">
              <label className="input-label" htmlFor="min-price">
                Min RON
              </label>
              <input
                id="min-price"
                type="number"
                min={priceRange[0]}
                max={priceRange[1]}
                value={currentRange[0]}
                onChange={(e) => handlePriceChange(e.target.value, 0)}
                placeholder="Min Price"
                className="price-input"
              />
            </div>
            <div className="price-input-group">
              <label className="input-label" htmlFor="max-price">
                Max RON
              </label>
              <input
                id="max-price"
                type="number"
                min={priceRange[0]}
                max={priceRange[1]}
                value={currentRange[1]}
                onChange={(e) => handlePriceChange(e.target.value, 1)}
                placeholder="Max Price"
                className="price-input"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Additional Filters */}
      <div className="topline-filter">
        <div className="filter-item">
          <label className="filter-label" htmlFor="estimated-duration">
            Durata Estimată
          </label>
          <select
            id="estimated-duration"
            className="filter-select"
            value={estimatedDuration}
            onChange={(e) => {
              const value = e.target.value; // e.g. "Rapid"
              setEstimatedDuration(value);
              // Convert to lowercase for filtering (assuming DB stores in lowercase)
              applyFilters({
                estimatedDuration: value ? value.toLowerCase() : "",
              });
            }}>
            <option value="">Selectează</option>
            <option value="rapid">Rapid</option>
            <option value="mediu">Mediu</option>
            <option value="lung">Lung</option>
          </select>
        </div>

        {/* City Selector */}
        <div className="filter-item">
          <label className="filter-label" htmlFor="city">
            Oraș
          </label>
          <select
            id="city"
            className="filter-select"
            value={location}
            onChange={(e) => {
              setLocation(e.target.value);
              // Reset sector when the city changes
              setSector("");
              applyFilters({ location: e.target.value, sector: "" });
            }}>
            <option value="">Toate orașele</option>
            {Object.keys(locationsData).map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>

        {/* Sector Selector: show only if the selected city is "Bucuresti" */}
        {location === "Bucuresti" && (
          <div className="filter-item">
            <label className="filter-label" htmlFor="sector">
              Sector
            </label>
            <select
              id="sector"
              className="filter-select"
              value={sector}
              onChange={(e) => {
                setSector(e.target.value);
                applyFilters({ sector: e.target.value });
              }}>
              <option value="">Toate sectoarele</option>
              {bucharestSectors.map((sec) => (
                <option key={sec} value={sec}>
                  {sec}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="filter-item">
          <label className="filter-label" htmlFor="payment-type">
            Tip Plata
          </label>
          <select
            id="payment-type"
            className="filter-select"
            value={paymentType}
            onChange={(e) => {
              setPaymentType(e.target.value);
              applyFilters({ paymentType: e.target.value });
            }}>
            <option value="">Selectează</option>
            <option value="negociabil">Negociabil</option>
            <option value="fix">Fixa</option>
          </select>
        </div>
      </div>
      <div className="topline-filter">
        <div className="filter-item">
          <label className="filter-label" htmlFor="experience-level">
            Nivel Experiență
          </label>
          <select
            id="experience-level"
            className="filter-select"
            value={experienceLevel}
            onChange={(e) => {
              setExperienceLevel(e.target.value);
              applyFilters({ experienceLevel: e.target.value });
            }}>
            <option value="">Selectează</option>
            <option value="incepator">Începător</option>
            <option value="intermediar">Intermediar</option>
            <option value="avansat">Avansat</option>
          </select>
        </div>

        <div className="filter-item checkbox-item">
          <label className="filter-label" htmlFor="urgent">
            Urgent?
          </label>
          <input
            id="urgent"
            type="checkbox"
            checked={isUrgent}
            onChange={(e) => {
              setIsUrgent(e.target.checked);
              applyFilters({ isUrgent: e.target.checked });
            }}
          />
        </div>

        {/* Date Picker for Task Due Date */}
        <div className="filter-item filter-item-date">
          <label className="filter-label">Când?</label>
          <input
            type="date"
            className="filter-input"
            value={taskDueDate}
            onChange={(e) => {
              setTaskDueDate(e.target.value);
              applyFilters({ taskDueDate: e.target.value });
            }}
          />
        </div>
      </div>
      {/* --- Business filters (commented out, keep for later) --- */}
      {/*
      <div className="filter-item checkbox-item">
        <label className="filter-label" htmlFor="businessFilter">
          Filtre anunțuri Business
        </label>
        <input
          id="businessFilter"
          type="checkbox"
          checked={businessFilter}
          onChange={(e) => setBusinessFilter(e.target.checked)}
        />
      </div>
      {businessFilter && (
        <div className="business-filters-fields">
          <div className="filter-item">
            <label className="filter-label">Număr persoane căutate</label>
            <input
              type="number"
              className="filter-input-search"
              value={numberOfPeople}
              onChange={(e) => setNumberOfPeople(e.target.value)}
            />
          </div>
          <div className="filter-item checkbox-item">
            <label className="filter-label" htmlFor="cvRequiredFilter">
              Necesită CV?
            </label>
            <input
              id="cvRequiredFilter"
              type="checkbox"
              checked={cvRequiredFilter}
              onChange={(e) => setCvRequiredFilter(e.target.checked)}
            />
          </div>
        </div>
      )}
      */}

      {/* Reset Filters Button */}
      <div className="filter-buttons">
        <button className="reset-filters-btn" onClick={resetFilters}>
          Resetează filtrele
        </button>
      </div>
    </div>
  );
};

export default Filters;
