import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../data/firebase";
import "../../styling/Article.css";

const Article = () => {
  const { categoryName, title } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const snapshot = await getDocs(collection(firestore, "articles"));
        const articles = snapshot.docs.map((doc) => doc.data());
        const found = articles.find(
          (a) => a.category === categoryName && a.slug === title
        );
        setArticle(found || null);
        setLoading(false);
      } catch (error) {
        console.error("❌ Eroare la încărcarea articolului:", error);
        setLoading(false);
      }
    };

    fetchArticle();
  }, [categoryName, title]);

  if (loading) {
    return <div className="article-container">Se încarcă articolul...</div>;
  }

  if (!article) {
    return (
      <div className="article-container">
        <h1 className="article-not-found">Articolul nu a fost găsit</h1>
        <p className="not-found-message">
          Ne pare rău, dar nu am putut găsi articolul pe care îl căutați.
        </p>
      </div>
    );
  }

  return (
    <div className="article-container">
      <header className="article-header-blog">
        <h1 className="article-title-blog">{article.title}</h1>
        <div className="article-meta-blog">
          <Link to={`/blog/${categoryName}`} className="article-category-blog">
            {categoryName.replace("-", " ").toUpperCase()}
          </Link>
          <span className="article-date-blog">
            {" "}
            | Publicat pe: {article.date}
          </span>
        </div>
      </header>
      {article.sections.map((section, index) => (
        <section key={index} className="article-section-blog">
          <h2 className="section-title-blog">{section.title}</h2>

          {section.videoEmbed ? (
            <div
              className="video-embed"
              dangerouslySetInnerHTML={{ __html: section.videoEmbed }}
            />
          ) : (
            <p className="section-content-blog">{section.content}</p>
          )}

          {section.list && (
            <ul className="section-list-blog">
              {section.list.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          )}

          {section.tip && <p className="section-tip-blog">💡 {section.tip}</p>}

          {section.image && (
            <img
              src={section.image}
              alt={`Screenshot pentru ${section.title}`}
              className="section-image-blog"
            />
          )}
        </section>
      ))}

      <div className="article-footer">
        <button
          className="share-button"
          onClick={() =>
            window.open(
              `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
              "_blank"
            )
          }>
          Distribuie pe Facebook
        </button>
        <button
          className="share-button-whatsapp"
          onClick={() =>
            window.open(
              `https://api.whatsapp.com/send?text=${window.location.href}`,
              "_blank"
            )
          }>
          Trimite pe WhatsApp
        </button>
      </div>
    </div>
  );
};

export default Article;
