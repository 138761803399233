// ReviewModal.jsx
import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../data/firebase";
import "../styling/reviewModal.css";

const ReviewModal = ({ service, onClose }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      if (!service.id) {
        setLoading(false);
        return;
      }
      try {
        const reviewsCollection = collection(firestore, "reviews");
        const q = query(reviewsCollection, where("adId", "==", service.id));
        const querySnapshot = await getDocs(q);
        const reviewsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setReviews(reviewsData);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [service]);

  return (
    <div className="review-modal-overlay" onClick={onClose}>
      <div
        className="review-modal-content"
        onClick={(e) => e.stopPropagation()}>
        <h2>Recenzii pentru {service.title}</h2>

        {loading ? (
          <p>Se încarcă recenziile...</p>
        ) : reviews.length > 0 ? (
          <ul className="reviews-list">
            {reviews.map((review) => (
              <li key={review.id} className="review-item">
                <strong>{review.reviewerName || "Anonim"}</strong>:{" "}
                {review.review || "Fără comentariu"}
                {/* Afișăm stelele dacă există un rating */}
                {review.rating && (
                  <div className="star-rating">
                    {[...Array(5)].map((_, index) =>
                      index < review.rating ? (
                        <span key={index} className="star filled">
                          ★
                        </span>
                      ) : (
                        <span key={index} className="star">
                          ★
                        </span>
                      )
                    )}
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p>Nu există recenzii pentru acest anunț.</p>
        )}

        <button onClick={onClose}>Închide</button>
      </div>
    </div>
  );
};

export default ReviewModal;
