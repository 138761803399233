import React, { useState } from "react";
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "../styling/onBoardingForm.css";

const PrivacyPolicyModal = ({ onClose }) => {
  return (
    <div className="modal-overlay-privacy-onboarding">
      <div className="modal-content-privacy-onboarding">
        <h2>Politica de Confidențialitate</h2>
        <p>
          Această politică de confidențialitate explică modul în care colectăm,
          utilizăm și protejăm informațiile dumneavoastră personale conform
          legislației în vigoare din România.
        </p>
        <h3>1. Colectarea și utilizarea datelor</h3>
        <p>
          Datele colectate sunt utilizate exclusiv pentru verificarea acestora
          și pentru buna funcționare a platformei. Nu folosim datele în scopuri
          de marketing și nu le vindem către terți.
        </p>
        <h3>2. Stocarea și protecția datelor</h3>
        <p>
          Datele dumneavoastră sunt stocate în siguranță și sunt protejate
          împotriva accesului neautorizat. Ne angajăm să respectăm toate
          reglementările GDPR aplicabile în România.
        </p>
        <h3>3. Drepturile utilizatorului</h3>
        <p>
          Aveți dreptul să solicitați accesul, modificarea sau ștergerea datelor
          dumneavoastră personale. Pentru orice solicitare, ne puteți contacta
          la adresa de email specificată în platformă.
        </p>
        <button onClick={onClose} className="close-button-onboarding">
          Închide
        </button>
      </div>
    </div>
  );
};

const TermsConditionsModal = ({ onClose }) => {
  return (
    <div className="modal-overlay-privacy-onboarding">
      <div className="modal-content-privacy-onboarding">
        <h2>Termeni și Condiții</h2>
        <p>
          Acești termeni și condiții stabilesc regulile de utilizare a
          platformei noastre. Prin utilizarea acestei platforme, sunteți de
          acord cu acești termeni.
        </p>
        <h3>1. Utilizarea platformei</h3>
        <p>
          Platforma poate fi utilizată doar în scopuri legale și în conformitate
          cu reglementările aplicabile din România.
        </p>
        <h3>2. Responsabilitatea utilizatorului</h3>
        <p>
          Utilizatorii sunt responsabili pentru informațiile furnizate și se
          angajează să nu încalce drepturile altor utilizatori sau ale terților.
        </p>
        <h3>3. Modificări ale termenilor</h3>
        <p>
          Ne rezervăm dreptul de a modifica acești termeni și condiții fără
          notificare prealabilă. Continuarea utilizării platformei reprezintă
          acceptarea modificărilor.
        </p>
        <button onClick={onClose} className="close-button-onboarding">
          Închide
        </button>
      </div>
    </div>
  );
};

const OnboardingForm = ({ uid }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    // Societate details
    companyName: "",
    cui: "",
    registrationNumber: "",
    headquarters: "",
    website: "",
    // Contact details
    contactPerson: "",
    contactPosition: "",
    contactEmail: "",
    contactPhone: "",
    // Company activity details
    activityDescription: "",
    activityField: "",
    // Legal representative details
    legalRepresentativeName: "",
    legalRepresentativePosition: "",
    legalRepresentativeContact: "",
    // Agreements
    acceptPrivacyPolicy: false,
    acceptTerms: false,
    acceptOtherAgreements: false,
    // Additional information
    additionalInfo: "",
  });

  // State to track if the CUI is valid
  const [isCUIValid, setIsCUIValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [isTermsConditionsOpen, setTermsConditionsOpen] = useState(false);

  const navigate = useNavigate();
  const db = getFirestore();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Reset error and CUI flag when user updates the CUI field
    if (name === "cui") {
      setError("");
      setIsCUIValid(true);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Function called when leaving the CUI input field
  const handleCUIBlur = async (e) => {
    const cuiValue = e.target.value;
    if (cuiValue && cuiValue.trim() !== "") {
      try {
        const apiKey = "7e46ed97355668013e960cc04aca5a08eadea247"; // Consider moving this to an env variable
        const url = `https://infocui.ro/system/api/data?cui=${cuiValue}&key=${apiKey}`;
        const response = await fetch(url);
        if (response.ok) {
          const result = await response.json();
          if (result.status === 200 && result.data) {
            // Valid CUI found; auto-fill fields and mark as valid
            setIsCUIValid(true);
            setFormData((prevData) => ({
              ...prevData,
              companyName: result.data.nume || prevData.companyName,
              registrationNumber:
                result.data.cod_inmatriculare || prevData.registrationNumber,
              headquarters: result.data.adresa || prevData.headquarters,
            }));
          } else {
            // API did not return valid data: mark CUI as invalid
            setIsCUIValid(false);
            setError("CUI invalid. Te rugăm să introduci un CUI valid.");
          }
        } else {
          // API call failed: mark CUI as invalid
          setIsCUIValid(false);
          setError(
            "Nu am putut accesa serviciul de verificare. Te rugăm să introduci un CUI valid."
          );
        }
      } catch (error) {
        setIsCUIValid(false);
        setError("Eroare la preluarea datelor CUI: " + error.message);
      }
    }
  };

  // Prevent moving to the next step if CUI is invalid
  const handleNext = (e) => {
    e.preventDefault();
    if (!isCUIValid) {
      setError("Te rugăm să introduci un CUI valid înainte de a continua.");
      return;
    }
    setStep(2);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setStep(1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    if (!formData.acceptPrivacyPolicy || !formData.acceptTerms) {
      setError(
        "Trebuie să accepți politica de confidențialitate și termenii de utilizare."
      );
      setLoading(false);
      return;
    }

    try {
      // Save the form data in the "business_users" collection using uid as the document ID
      await setDoc(doc(db, "business_users", uid), {
        ...formData,
        userId: uid,
        createdAt: new Date(),
      });
      navigate(`/business/${uid}`);
    } catch (err) {
      console.error("Error saving business details:", err);
      setError("A apărut o eroare. Te rugăm încearcă din nou.");
    }
    setLoading(false);
  };

  return (
    <div className="page-container">
      <div className="onboarding-form-container">
        <div className="onboarding-intro">
          <h2>Ce este AidlyBusiness?</h2>
          <p>
            AidlyBusiness este o ramură a Aidly unde ne ocupăm de a ajuta
            afacerile să își găsească personal pe oră, pe zi sau pe săptămână,
            pentru perioade scurte. Cu ajutorul platformei noastre, companiile
            pot accesa rapid și eficient profesioniști potriviți pentru nevoile
            lor.
          </p>
        </div>
        <h2>Completează detaliile afacerii</h2>
        <p>
          Te rugăm să completezi următoarele informații pentru a configura
          contul tău AidlyBusiness.
        </p>
        <form onSubmit={step === 1 ? handleNext : handleSubmit}>
          {step === 1 && (
            <>
              {/* Step 1: Company and contact details */}
              <div className="inline-double-onboarding">
                <div className="form-group-onboarding">
                  <label>Codul Unic de Înregistrare (CUI)</label>
                  <input
                    type="text"
                    name="cui"
                    value={formData.cui}
                    onChange={handleChange}
                    onBlur={handleCUIBlur}
                    required
                  />
                </div>
                <div className="form-group-onboarding">
                  <label>Denumirea completă a societății</label>
                  <input
                    type="text"
                    name="companyName"
                    disabled
                    value={formData.companyName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="inline-double-onboarding">
                <div className="form-group-onboarding">
                  <label>Numărul de înregistrare la Registrul Comerțului</label>
                  <input
                    type="text"
                    name="registrationNumber"
                    disabled
                    value={formData.registrationNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group-onboarding">
                  <label>Persoana de contact</label>
                  <input
                    type="text"
                    name="contactPerson"
                    value={formData.contactPerson}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="inline-double-onboarding">
                <div className="form-group-onboarding">
                  <label>Funcția sau poziția</label>
                  <select
                    className="select-dropdown"
                    name="contactPosition"
                    value={formData.contactPosition}
                    onChange={handleChange}
                    required>
                    <option value="">Selectează poziția</option>
                    <option value="CEO">CEO</option>
                    <option value="CFO">CFO</option>
                    <option value="COO">COO</option>
                    <option value="CTO">CTO</option>
                    <option value="CMO">CMO</option>
                    <option value="Administrator (51%+ acțiuni)">
                      Administrator (51%+ acțiuni)
                    </option>
                  </select>
                </div>
                <div className="form-group-onboarding">
                  <label>Numărul de telefon</label>
                  <input
                    type="tel"
                    name="contactPhone"
                    value={formData.contactPhone}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="inline-double-onboarding">
                <div className="form-group-onboarding">
                  <label>Adresa de e-mail</label>
                  <input
                    type="email"
                    name="contactEmail"
                    value={formData.contactEmail}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group-onboarding">
                  <label>Website-ul companiei (dacă există)</label>
                  <input
                    type="url"
                    name="website"
                    value={formData.website}
                    onChange={handleChange}
                    pattern="https://.+"
                    title="Website-ul trebuie să înceapă cu https://"
                  />
                </div>
              </div>
              <div className="form-group-onboarding">
                <label>Sediul social</label>
                <textarea
                  name="headquarters"
                  disabled
                  value={formData.headquarters}
                  onChange={handleChange}
                  required
                />
              </div>
            </>
          )}

          {step === 2 && (
            <>
              {/* Step 2: Company activity and legal representative details */}
              <div className="form-group-onboarding">
                <label>Descrierea activității</label>
                <textarea
                  name="activityDescription"
                  value={formData.activityDescription}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="inline-double-onboarding">
                <div className="form-group-onboarding">
                  <label>Domeniul de activitate</label>
                  <select
                    className="select-dropdown"
                    name="activityField"
                    value={formData.activityField}
                    onChange={handleChange}
                    required>
                    <option value="">Selectează domeniul de activitate</option>
                    <option value="Agricultură și silvicultură">
                      Agricultură și silvicultură
                    </option>
                    <option value="Comerț cu amănuntul">
                      Comerț cu amănuntul
                    </option>
                    <option value="Comerț cu ridicata">
                      Comerț cu ridicata
                    </option>
                    <option value="Construcții">Construcții</option>
                    <option value="Energie și utilități">
                      Energie și utilități
                    </option>
                    <option value="Finanțe și asigurări">
                      Finanțe și asigurări
                    </option>
                    <option value="Industrie manufacturieră">
                      Industrie manufacturieră
                    </option>
                    <option value="IT și telecomunicații">
                      IT și telecomunicații
                    </option>
                    <option value="Medicină și sănătate">
                      Medicină și sănătate
                    </option>
                    <option value="Servicii profesionale">
                      Servicii profesionale
                    </option>
                    <option value="Transport și logistică">
                      Transport și logistică
                    </option>
                    <option value="Turism și agrement">
                      Turism și agrement
                    </option>
                  </select>
                </div>
                <div className="form-group-onboarding">
                  <label>Numele reprezentantului legal</label>
                  <input
                    type="text"
                    name="legalRepresentativeName"
                    value={formData.legalRepresentativeName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="inline-double-onboarding">
                <div className="form-group-onboarding">
                  <label>Funcția reprezentantului legal</label>
                  <select
                    className="select-dropdown"
                    name="legalRepresentativePosition"
                    value={formData.legalRepresentativePosition}
                    onChange={handleChange}
                    required>
                    <option value="">Selectează funcția</option>
                    <option value="Administrator">Administrator</option>
                    <option value="Director General">Director General</option>
                    <option value="Consilier juridic">Consilier juridic</option>
                    <option value="Avocat">Avocat</option>
                    <option value="Manager">Manager</option>
                    <option value="Other">Altul</option>
                  </select>
                </div>
                <div className="form-group-onboarding">
                  <label>Adresa email al reprezentantului legal</label>
                  <input
                    type="text"
                    name="legalRepresentativeContact"
                    value={formData.legalRepresentativeContact}
                    onChange={handleChange}
                    placeholder="ex.: legal@example.com"
                    required
                  />
                </div>
              </div>
              <div className="inline-double-onboarding">
                <div className="form-group-onboarding checkbox-group">
                  <label>
                    <input
                      type="checkbox"
                      name="acceptPrivacyPolicy"
                      checked={formData.acceptPrivacyPolicy}
                      onChange={handleChange}
                      required
                    />
                    Am citit și accept&nbsp;
                    <button
                      type="button"
                      onClick={() => setPrivacyPolicyOpen(true)}
                      className="clickable-link">
                      politica de confidențialitate
                    </button>
                    .
                  </label>
                </div>
                <div className="form-group-onboarding checkbox-group">
                  <label>
                    <input
                      type="checkbox"
                      name="acceptTerms"
                      checked={formData.acceptTerms}
                      onChange={handleChange}
                      required
                    />
                    Accept&nbsp;
                    <button
                      type="button"
                      onClick={() => setTermsConditionsOpen(true)}
                      className="clickable-link">
                      termenii și condițiile de utilizare
                    </button>
                    .
                  </label>
                </div>
              </div>
              <div className="form-group-onboarding">
                <label>Feedback sau cerințe speciale</label>
                <textarea
                  name="additionalInfo"
                  value={formData.additionalInfo}
                  onChange={handleChange}
                />
              </div>
            </>
          )}

          {error && <p className="error">{error}</p>}
          <div className="button-group">
            {step === 2 && (
              <button
                className="back-submit-onboarding"
                type="button"
                onClick={handleBack}>
                Înapoi
              </button>
            )}
            <button
              className="save-submit-onboarding"
              type="submit"
              disabled={loading}>
              {step === 1
                ? "Următorul 1/2"
                : loading
                ? "Salvează..."
                : "Salvează"}
            </button>
          </div>
        </form>
      </div>
      {isPrivacyPolicyOpen && (
        <PrivacyPolicyModal onClose={() => setPrivacyPolicyOpen(false)} />
      )}
      {isTermsConditionsOpen && (
        <TermsConditionsModal onClose={() => setTermsConditionsOpen(false)} />
      )}
    </div>
  );
};

export default OnboardingForm;
