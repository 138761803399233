import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { firestore } from "../data/firebase";
import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  Timestamp,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "../styling/adPage.css";

const AdPage = () => {
  const { id } = useParams(); // ID-ul anunțului din URL
  const navigate = useNavigate();
  const [ad, setAd] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [ownerName, setOwnerName] = useState("Anonim");
  const [ownerEmail, setOwnerEmail] = useState(null);

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchAd = async () => {
      try {
        const adRef = doc(firestore, "ads", id);
        const adSnap = await getDoc(adRef);

        if (adSnap.exists()) {
          const adData = { id: adSnap.id, ...adSnap.data() };
          setAd(adData);

          // Preia datele proprietarului
          const ownerRef = doc(firestore, "users", adData.uid);
          const ownerSnap = await getDoc(ownerRef);
          if (ownerSnap.exists()) {
            const ownerData = ownerSnap.data();
            setOwnerName(ownerData.displayName || "Anonim");
            setOwnerEmail(ownerData.email || null);
          }
        } else {
          setAd(null);
        }
      } catch (error) {
        console.error("Eroare la încărcarea anunțului:", error);
      }
      setLoading(false);
    };

    fetchAd();
  }, [id]);

  const handleAccept = async () => {
    if (!currentUser) {
      toast.error("Trebuie să fii autentificat pentru a accepta un anunț.");
      return;
    }

    if (!acceptTerms) {
      toast.error(
        "Trebuie să accepți termenii și condițiile pentru a continua."
      );
      return;
    }

    if (!ad || !ad.id) {
      toast.error("Anunțul nu mai există sau are date incomplete.");
      return;
    }

    if (ad.status === "inProgress") {
      toast.error("Anunțul a fost deja acceptat și este în curs.");
      return;
    }

    if (
      window.confirm(
        `Ești sigur că vrei să accepți această ofertă pentru "${ad.title}"?`
      )
    ) {
      try {
        const adRef = doc(firestore, "ads", ad.id);
        const adSnap = await getDoc(adRef);

        if (!adSnap.exists()) {
          toast.error(`Anunțul "${ad.title}" nu mai există.`);
          return;
        }

        const adData = adSnap.data();

        if (adData.status === "inProgress") {
          toast.error("Anunțul a fost deja acceptat și este în curs.");
          return;
        }

        const userRef = doc(firestore, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (!userSnap.exists()) {
          toast.error("Utilizatorul curent nu există.");
          return;
        }

        const taskerData = userSnap.data();

        await updateDoc(adRef, {
          status: "inProgress",
          taskerName: taskerData.displayName || "Anonim",
          taskerPhone: taskerData.phoneNumber || "Necunoscut",
          legal: true,
          taskerUid: currentUser.uid,
          participants: [...(adData.participants || []), currentUser.uid],
        });

        await addDoc(collection(firestore, "notifications"), {
          recipientUid: ad.uid,
          senderUid: currentUser.uid,
          adId: ad.id,
          message: `${
            taskerData.displayName || "Anonim"
          } a acceptat oferta ta pentru "${ad.title}".`,
          status: "acceptedOffer",
          timestamp: Timestamp.now(),
          isRead: false,
          isDeleted: false,
        });

        if (ownerEmail) {
          const emailTemplateParams = {
            to_email: ownerEmail,
            owner_name: ownerName,
            task_title: ad.title,
            accepted_by: taskerData.displayName || "Anonim",
            profile_link: `https://aidly.ro/profile/${ad.uid}`,
          };

          emailjs
            .send(
              "service_exj3u5d",
              "template_mailtoowner",
              emailTemplateParams,
              "8P20l58Dg2sQRxHiK"
            )
            .then(() =>
              toast.success("Proprietarul a fost notificat prin email.")
            )
            .catch((error) =>
              console.error("Eroare la trimiterea emailului:", error)
            );
        }

        toast.success(`Oferta pentru "${ad.title}" a fost acceptată!`);
        navigate("/");
      } catch (error) {
        console.error("Error accepting offer:", error);
        toast.error("Eroare la acceptarea ofertei.");
      }
    }
  };

  if (loading) {
    return <div className="loading">Se încarcă...</div>;
  }

  if (!ad) {
    return (
      <div className="error-page">
        <h2>Anunț inexistent</h2>
        <p>Acest anunț nu există sau a fost șters.</p>
        <button onClick={() => navigate("/")}>Înapoi la prima pagină</button>
      </div>
    );
  }

  return (
    <div className="overlay-backdrop">
      <div className="overlay-window">
        <div className="modal-header-page-ad">
          <h2>{ad.title}</h2>
        </div>

        <div className="modal-content-page-ad">
          <p>
            <strong>Descriere:</strong> {ad.description || "Fără descriere"}
          </p>
          <p>
            <strong>Buget:</strong> {ad.price} RON
          </p>
          <p>
            <strong>Locație:</strong> {ad.location}
          </p>
          <p>
            <strong>Categorie:</strong> {ad.category || "Nespecificată"}
          </p>
          <p>
            <strong>Proprietar:</strong> {ownerName}
          </p>

          <div className="terms-checkbox-container">
            <label className="custom-checkbox">
              <input
                type="checkbox"
                checked={acceptTerms}
                onChange={() => setAcceptTerms(!acceptTerms)}
              />
              <span className="checkmark"></span>
              Accept{" "}
              <a
                href="/termeni-si-conditii"
                target="_blank"
                rel="noopener noreferrer"
                className="terms-link">
                termenii și condițiile
              </a>
            </label>
          </div>

          <div className="action-buttons">
            <button
              className="accept-btn"
              onClick={handleAccept}
              disabled={!currentUser || !acceptTerms}>
              Acceptă Anunțul
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdPage;
