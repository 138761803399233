import React, { useRef, useEffect } from "react";
import "../styling/friendsCarousel.css";
import PartnerLogo1 from "../assets/partener1.webp";
import PartnerLogo2 from "../assets/partener2.webp";
import PartnerLogo3 from "../assets/creative.webp";
import PartnerLogo4 from "../assets/onyx.webp";

import { useTranslation } from "react-i18next";

const FriendsCarousel = () => {
  const logos = [
    { logo: PartnerLogo1, url: "https://digitalimage.agency/" },
    { logo: PartnerLogo2, url: "https://upvisionmedia.com" },
    { logo: PartnerLogo3, url: "https://www.instagram.com/creativecrate.ro/" },

    { logo: PartnerLogo4, url: "https://onyxmedia.ro/" },
  ];

  const { t } = useTranslation(); // Initialize translation

  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    let scrollPosition = 0;
    const scrollSpeed = 0.1;

    const scrollContinuously = () => {
      scrollPosition += scrollSpeed;
      if (scrollPosition >= slider.scrollWidth / 2) {
        scrollPosition = 0;
      }
      slider.scrollLeft = scrollPosition;
      requestAnimationFrame(scrollContinuously);
    };

    scrollContinuously();
  }, []);

  return (
    <div className="friends-carousel">
      <div className="carousel-left">
        <h2>{t("friendsCarousel.title")}</h2>
      </div>
      <div className="slider" ref={sliderRef}>
        <div className="slide-track">
          {[...logos, ...logos, ...logos, ...logos].map((item, index) => (
            <div className="slide" key={index}>
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                className="shortcut-link">
                <img
                  src={item.logo}
                  alt={t("friendsCarousel.logoAlt", { index: index + 1 })}
                  className="partner-logo"
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FriendsCarousel;
