import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../data/firebase";
import "../../styling/Category.css";

const Category = () => {
  const { categoryName } = useParams();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const snapshot = await getDocs(collection(firestore, "articles"));
        const data = snapshot.docs.map((doc) => doc.data());
        const filtered = data.filter(
          (article) => article.category === categoryName
        );
        setArticles(filtered);
        setLoading(false);
      } catch (error) {
        console.error("❌ Eroare la încărcarea articolelor:", error);
        setLoading(false);
      }
    };

    fetchArticles();
  }, [categoryName]);

  return (
    <div className="category-container">
      <header className="category-header">
        <h1 className="category-title">
          {categoryName.replace("-", " ").toUpperCase()}
        </h1>
        <p className="category-description">
          Descoperă articolele noastre, unde aducem inspirație, soluții practice
          și informații utile pentru a te ajuta să îți atingi obiectivele.
        </p>
      </header>

      {loading ? (
        <p style={{ textAlign: "center" }}>Se încarcă articolele...</p>
      ) : articles.length > 0 ? (
        <div className="category-articles">
          {articles.map((article, index) => (
            <Link
              to={`/blog/${categoryName}/${article.slug}`}
              key={index}
              className="article-card">
              <h3 className="article-title">{article.title}</h3>
              <p className="article-summary">
                Descoperă mai multe despre acest subiect.
              </p>
            </Link>
          ))}
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>
          Nu există articole în această categorie.
        </p>
      )}
    </div>
  );
};

export default Category;
