import { firestore, auth, messaging } from "../data/firebase"; // Import Firebase instances
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  setDoc,
  Timestamp,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import { getToken, onMessage } from "firebase/messaging";

import {
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { toast } from "react-toastify"; // For notification display

// Helper function to create a notification
export const createNotification = async (notificationData) => {
  const { recipientUid, senderUid, message, status, adId } = notificationData;

  if (!adId || !recipientUid || !senderUid || !message || !status) {
    console.error(
      "Notification creation error: Incomplete data",
      notificationData
    );
    throw new Error(
      "Incomplete notification data. Please ensure all fields are populated."
    );
  }

  try {
    // Check if a notification for the same adId and status already exists
    const existingNotificationsQuery = collection(firestore, "notifications");
    const querySnapshot = await getDocs(
      query(
        existingNotificationsQuery,
        where("adId", "==", adId),
        where("status", "==", status)
      )
    );

    if (!querySnapshot.empty) {
      console.warn(
        `Notification for adId "${adId}" and status "${status}" already exists.`
      );
      return; // Avoid creating a duplicate notification
    }

    // Add a new notification if no duplicate exists
    await addDoc(collection(firestore, "notifications"), {
      recipientUid,
      senderUid,
      adId,
      message,
      status,
      timestamp: Timestamp.now(),
      isRead: false,
      isDeleted: false,
    });
  } catch (error) {
    console.error("Error creating notification:", error.message);
    throw error;
  }
};

/**
 * Standardizează numărul de telefon pentru WhatsApp.
 * @param {string} phoneNumber - Numărul de telefon introdus.
 * @returns {string} - Numărul de telefon standardizat.
 */
export const standardizeWhatsAppNumber = (phoneNumber) => {
  if (!phoneNumber) {
    throw new Error("Numărul de telefon este necesar.");
  }

  // Elimină toate caracterele non-digit (spații, paranteze, liniuțe etc.)
  const cleanedNumber = phoneNumber.replace(/\D/g, "");

  // Verifică dacă numărul începe cu '07' (format românesc)
  if (cleanedNumber.startsWith("07") && cleanedNumber.length === 10) {
    return `+4${cleanedNumber.slice(1)}`; // Transformă '07...' în '+47...'
  }

  // Verifică dacă numărul nu începe cu '0' (de exemplu, '744497740')
  if (!cleanedNumber.startsWith("0") && cleanedNumber.length === 9) {
    return `+40${cleanedNumber}`; // Adaugă '+40' în față
  }

  // Dacă numărul este deja în formatul corect (de exemplu, '+40744497740'), îl returnăm direct
  if (cleanedNumber.startsWith("40") && cleanedNumber.length === 11) {
    return `+${cleanedNumber}`;
  }

  // Dacă numărul nu se potrivește cu niciun format cunoscut, aruncăm o eroare
  throw new Error("Format invalid al numărului de telefon.");
};

// Request notification permission and retrieve FCM token
export const requestNotificationToken = async () => {
  try {
    if (!("Notification" in window)) {
      console.warn("This browser does not support notifications.");
      return null;
    }

    if (Notification.permission === "granted") {
      return await getFCMToken();
    } else if (Notification.permission === "default") {
      const permissionResult = await Notification.requestPermission();
      return permissionResult === "granted" ? await getFCMToken() : null;
    } else {
      console.warn("Notification permission denied.");
      return null;
    }
  } catch (error) {
    console.error("Error requesting notification token:", error);
    return null;
  }
};

// Helper function to get FCM Token
const getFCMToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    });
    if (token) {
      return token;
    } else {
      console.warn("No FCM token available.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching FCM token:", error.message);
    return null;
  }
};

// Save FCM token to Firestore
export const saveTokenToFirestore = async (uid, token) => {
  try {
    const userRef = doc(firestore, "users", uid);
    await updateDoc(userRef, { notificationToken: token });
  } catch (error) {
    console.error(`Error saving token for user ${uid}:`, error.message);
    throw error;
  }
};

// Listen for incoming messages when the app is in the foreground
export const listenForNotifications = () => {
  onMessage(messaging, (payload) => {
    const { notification } = payload;
    if (notification) {
      toast.info(`${notification.title}: ${notification.body}`);
    } else {
      console.warn(
        "Notification payload missing 'notification' field:",
        payload
      );
    }
  });
};

// Add user to Firestore
export const addUserToFirestore = async (user) => {
  try {
    const adminEmails = process.env.REACT_APP_ADMIN_EMAILS
      ? process.env.REACT_APP_ADMIN_EMAILS.split(",")
      : [];

    const isAdmin = adminEmails.includes(user.email);

    const userRef = doc(firestore, "users", user.uid);

    const highResPhotoUrl = user.photoURL
      ? user.photoURL.replace(/=s96-c$/, "=s400-c")
      : null;

    const hasPhoto = !!highResPhotoUrl;
    const hasPhone = !!user.phoneNumber && user.phoneNumber !== "N/A";
    const hasEmail = !!user.email;

    const isVerified1 =
      hasPhoto && hasPhone && hasEmail && user.emailVerified === true;

    const userData = {
      email: user.email,
      displayName: user.displayName || "N/A",
      phoneNumber: user.phoneNumber || "N/A",
      role: isAdmin ? "admin" : "client",
      photoURL: highResPhotoUrl || "default-avatar-url",
      createdAt: new Date(),
      emailVerified: user.emailVerified || false,
      verified1: isVerified1, // true sau false în funcție de condiții
      bypassAuth: true,
    };

    await setDoc(userRef, userData, { merge: true });

    // Cleanup field
    try {
      await updateDoc(userRef, {
        bypassAuth: deleteField(),
      });
    } catch (error) {
      console.error("Error removing bypassAuth field:", error.message);
    }
  } catch (error) {
    console.error("Error adding/updating user in Firestore:", error.message);
    throw error;
  }
};


// Reset user password
export const handlePasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

export const checkPhoneNumberUnique = async (phoneNumber) => {
  try {
    const usersRef = collection(firestore, "users");
    const phoneQuery = query(usersRef, where("phoneNumber", "==", phoneNumber));
    const querySnapshot = await getDocs(phoneQuery);

    if (!querySnapshot.empty) {
      console.warn(`Phone number ${phoneNumber} already exists.`);
      return false; // Numărul de telefon există
    }
    return true; // Numărul de telefon este unic
  } catch (error) {
    console.error("Error checking phone number uniqueness:", error.message);
    throw error;
  }
};

// Create a new account
export const handleCreateAccount = async (
  email,
  password,
  fullName,
  phoneNumber
) => {
  try {
    // Creează utilizatorul în Firebase Auth
    const result = await createUserWithEmailAndPassword(auth, email, password);

    // Adaugă utilizatorul în Firestore
    await addUserToFirestore({
      ...result.user,
      displayName: fullName.trim(),
      phoneNumber: phoneNumber.trim(),
    });

    // Trimite email de verificare
    await sendEmailVerification(result.user)
      .then(() => {
        toast.success("Verification email sent successfully!");
      })
      .catch((error) => {
        console.error("Error sending verification email:", error.message);
        throw error;
      });

    // Afișează mesaj pentru utilizator
    alert(
      "Account created successfully! Please verify your email before logging in."
    );

    // Deconectează utilizatorul imediat după crearea contului
    await auth.signOut();

    return result.user;
  } catch (error) {
    console.error("Error during account creation:", error.message);
    throw error;
  }
};

export const updateEmailVerifiedStatus = async () => {
  const user = auth.currentUser;

  if (user) {
    try {
      // Reîncarcă informațiile utilizatorului
      await user.reload();

      if (user.emailVerified) {
        const userRef = doc(firestore, "users", user.uid);
        await updateDoc(userRef, { emailVerified: true });
      } else {
        toast.error("Emailul nu este încă verificat.");
      }
    } catch (error) {
      console.error("Eroare la actualizarea emailVerified:", error.message);
      throw error;
    }
  } else {
    console.warn("Niciun utilizator autentificat.");
  }
};

// Google login
export const handleGoogleLogin = async () => {
  try {
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({ prompt: "select_account" });
    const result = await signInWithPopup(auth, provider);

    const adminEmails = process.env.REACT_APP_ADMIN_EMAILS
      ? process.env.REACT_APP_ADMIN_EMAILS.split(",")
      : [];

    const isAdmin = adminEmails.includes(result.user.email);

    const userRef = doc(firestore, "users", result.user.uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      // Adaugă utilizatorul în Firestore dacă nu există
      await setDoc(userRef, {
        email: result.user.email,
        displayName: result.user.displayName || "N/A",
        phoneNumber: null,
        role: isAdmin ? "admin" : "client",
        photoURL: result.user.photoURL || "default-avatar-url",
        createdAt: new Date(),
        emailVerified: result.user.emailVerified || false,
      });
    }

    // Reîncarcă pagina după logare cu succes
    window.location.reload();
  } catch (error) {
    console.error("Eroare în timpul autentificării Google:", error.message);
    throw error;
  }
};

// ===================
// CHECK IF LOGGGED USER IS ADMIN
// ===================

const adminEmails = process.env.REACT_APP_ADMIN_EMAILS
  ? process.env.REACT_APP_ADMIN_EMAILS.split(",")
  : [];

onAuthStateChanged(auth, async (user) => {
  if (user) {
    try {
      // Verificăm dacă utilizatorul este admin pe baza email-ului
      const isAdmin = adminEmails.includes(user.email);

      const userRef = doc(firestore, "users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        // Actualizează câmpul rolului pe baza verificării admin
        const role = isAdmin ? "admin" : userDoc.data().role || "client";

        await updateDoc(userRef, { role });
      } else {
        // Dacă utilizatorul nu există în baza de date, adăugăm un document nou
        await addUserToFirestore({
          ...user,
          role: isAdmin ? "admin" : "client",
        });
      }
    } catch (error) {
      console.error("Eroare la gestionarea autentificării:", error.message);
    }
  } else {
    console.warn("Niciun utilizator autentificat.");
  }
});
// Email/password login
export const handleEmailPasswordLogin = async (email, password) => {
  try {
    // Autentificare utilizator
    const result = await signInWithEmailAndPassword(auth, email, password);

    // Reîmprospătează datele utilizatorului
    await result.user.reload();

    // Verifică dacă email-ul este confirmat
    if (!result.user.emailVerified) {
      await auth.signOut(); // Deconectează utilizatorul
      throw new Error(
        "Email-ul nu a fost confirmat. Te rugăm să îți verifici inbox-ul."
      );
    }

    // Verificăm dacă utilizatorul există în Firestore
    const userRef = doc(firestore, "users", result.user.uid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      throw new Error("Utilizatorul nu există în baza de date.");
    }

    const userData = userSnapshot.data();
    return { user: result.user, role: userData.role || "client" };
  } catch (error) {
    console.error("Eroare în procesul de autentificare:", error.message);
    throw error;
  }
};

// Get user role
export const getUserRole = async (uid) => {
  if (!uid) {
    throw new Error("UID lipsește.");
  }

  try {
    const userRef = doc(firestore, "users", uid);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error(`Utilizatorul cu UID ${uid} nu există.`);
    }

    return userDoc.data().role || "unknown";
  } catch (error) {
    console.error("Eroare la obținerea rolului utilizatorului:", error.message);
    throw new Error(
      "Permisiuni insuficiente pentru a citi rolul utilizatorului."
    );
  }
};

// Get user name from Firestore
export const getUserName = async (uid) => {
  try {
    const userRef = doc(firestore, "users", uid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      return userDoc.data().displayName || "No Display Name";
    } else {
      console.warn(`No user found with UID: ${uid}`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching user name for UID: ${uid}`, error);
    return null;
  }
};

// Function to update ad status in Firestore
export const updateAdStatus = async (adId, newStatus) => {
  try {
    const adRef = doc(firestore, "ads", adId);

    // Update the ad's status
    await updateDoc(adRef, { status: newStatus });
    return true;
  } catch (error) {
    console.error(`Error updating status for ad ${adId}:`, error.message);
    throw error;
  }
};

// Logout
export const handleLogout = async () => {
  try {
    await signOut(auth);

    // Reîncarcă pagina după logout
    window.location.reload();
  } catch (error) {
    console.error("Error during logout:", error);
    throw error;
  }
};

// Listen for auth state changes
export const onAuthChange = (callback) => {
  return onAuthStateChanged(auth, callback);
};
