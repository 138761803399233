import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../data/firebase.js";
import { getAuth } from "firebase/auth"; // For Firebase auth
import { handleLogin } from "../utils/authService"; // Login utility
import Modal from "./Modal";
import "../styling/CategoryServices.css";
import { useTranslation } from "react-i18next";

const categoryGradients = {
  agricultura: "#7FFF00",
  alimentatie: "#FFA07A",
  animale: "#D2B48C",
  autoandmoto: "#A9A9A9",
  casa: "#808000",
  familie: "#ADD8E6",
  curatenie: "#00c4c4",
  design: "#2F4F4F",
  divertisment: "#FFD700",
  educatie: "#4169E1",
  electronice: "#0000FF",
  eveniment: "#8A2BE2",
  finante: "#006400",
  fotovideo: "#808080",
  frumusete: "#FFC0CB",
  gaming: "#9400D3",
  hobby: "#40E0D0",
  imobiliare: "#4682B4",
  industrie: "#B0C4DE",
  juridice: "#000080",
  marketingads: "#FF4500",
  moda: "#000000",
  reparatii: "#708090",
  socialmedia: "#ADD8E6",
  sanatate: "#98FB98",
  seniori: "#F5DEB3",
  serviciiprofesionale: "#696969",
  sport: "#FF8C00",
  tehnologie: "#4682B4",
  transport: "#B22222",
  transportcargo: "#1E90FF",
  turism: "#FFD700",
};

const normalizeCategory = (category) =>
  category
    .trim()
    .toLowerCase()
    .replace(/\s+/g, "") // Remove spaces
    .replace(/&/g, "and"); // Replace '&' with 'and'

const CategoryServices = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const decodedCategory = decodeURIComponent(category);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedService, setSelectedService] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCategoryServices = async () => {
      try {
        const q = query(
          collection(firestore, "ads"),
          where("category", "==", decodedCategory),
          where("status", "==", "posted")
        );
        const querySnapshot = await getDocs(q);
        const fetchedServices = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setServices(fetchedServices);
      } catch (error) {
        console.error("Error fetching services:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (decodedCategory) fetchCategoryServices();
  }, [decodedCategory]);

  const handleAddAd = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser) {
      navigate(`/profile/${currentUser.uid}`);
    } else {
      await handleLogin(navigate); // Opens the login modal if not logged in
    }
  };

  const normalizedCategory = normalizeCategory(decodedCategory);
  const cardGradient =
    categoryGradients[normalizedCategory] ||
    "linear-gradient(135deg, #f9f9f9, #e3e3e3)";

  return (
    <div
      className="category-services-container"
      style={{ background: cardGradient }}>
      {/* Header Section */}
      <div className="header">
        <h1>{decodedCategory}</h1>
        <p>{t("category.explore", { category: decodedCategory })}</p>
      </div>

      {/* Services Grid */}
      {isLoading ? (
        <p>{t("loading")}</p>
      ) : services.length > 0 ? (
        <div className="services-grid">
          {services.map((service) => (
            <div
              className="service-card"
              key={service.id}
              onClick={() => setSelectedService(service)}>
              <div className="service-card-content">
                <h2 className="service-card-title">{service.title}</h2>
                <p className="service-card-price">
                  {t("price", { price: service.price })}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>{t("category.no_services")}</p>
      )}

      {/* CTA Section */}
      <div className="cta-section">
        <h3>
          {t("category.add_service_prompt", { category: decodedCategory })}
        </h3>
        <p>{t("category.add_service_description")}</p>
        <button className="cta-button" onClick={handleAddAd}>
          {t("category.add_ad_button")}
        </button>
      </div>
      {/* Modal */}
      {selectedService && (
        <Modal
          service={selectedService}
          onClose={() => setSelectedService(null)}
        />
      )}
    </div>
  );
};
export default CategoryServices;
