import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "../styling/home.css";
import LoginModal from "../utils/LoginModal"; // Import the same LoginModal
import { useTranslation } from "react-i18next";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../data/firebase";
const Home = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  // State and handlers for the login modal
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const handleOpenLoginModal = () => setIsLoginModalOpen(true);
  const handleCloseLoginModal = () => setIsLoginModalOpen(false);

  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);

  const [stats, setStats] = useState({
    usersCount: 0,
    activeTasks: 0,
    completedTasks: 0,
    views: 0,
    lastUpdated: "",
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, [auth]);

  const scrollToServices = () => {
    document.getElementById("services")?.scrollIntoView({ behavior: "smooth" });
  };

  const goToProfile = () => {
    if (currentUser) {
      navigate(`/profile/${currentUser.uid}`);
    } else {
      handleOpenLoginModal();
    }
  };

  const updateStatistics = async (forceUpdate = false) => {
    try {
      const statsRef = doc(firestore, "statistics", "pageViews");
      const statsSnap = await getDoc(statsRef);
      const now = Date.now();

      const lastUpdated = statsSnap.exists()
        ? statsSnap.data().lastUpdated?.seconds * 1000 || 0
        : 0;
      if (!forceUpdate && now - lastUpdated < 3 * 60 * 60 * 1000) {
        console.log("⏳ Prea devreme pentru actualizare.");
        return;
      }

      const usersSnap = await getDocs(collection(firestore, "users"));
      const postedAdsSnap = await getDocs(
        query(collection(firestore, "ads"), where("status", "==", "posted"))
      );
      const inProgressAdsSnap = await getDocs(
        query(collection(firestore, "ads"), where("status", "==", "inProgress"))
      );
      const completedAdsSnap = await getDocs(
        query(collection(firestore, "ads"), where("status", "==", "finalized"))
      );

      // ✅ Corectăm numărul de sarcini active: posted + inProgress
      const activeTasks = postedAdsSnap.size + inProgressAdsSnap.size;

      const updatedData = {
        usersCount: usersSnap.size,
        activeTasks, // ✅ Acum include posted + inProgress
        inProgressTasks: inProgressAdsSnap.size,
        completedTasks: completedAdsSnap.size,
        views: (statsSnap.exists() ? statsSnap.data().views || 0 : 0) + 1,
        lastUpdated:
          statsSnap.exists() && statsSnap.data().lastUpdated
            ? statsSnap.data().lastUpdated
            : new Date(),
      };

      // 🔥 Actualizăm **state-ul** pentru UI
      setStats(updatedData);

      if (statsSnap.exists()) {
        await updateDoc(statsRef, updatedData);
      } else {
        await setDoc(statsRef, updatedData);
      }
    } catch (error) {
      console.error("❌ Eroare la actualizarea statisticilor:", error);
    }
  };

  // Se apelează la încărcare și la fiecare 3 ore
  useEffect(() => {
    updateStatistics(true);
    const interval = setInterval(
      () => updateStatistics(false),
      3 * 60 * 60 * 1000
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <div id="home" className="home-container">
      <div className="left-side-home">
        <h1 className="home-title">{t("home.title")}</h1>
        <h2 className="home-subtitle">{t("home.subtitle")}</h2>
        <div className="buttons-homepage">
          <button className="btn btn--post" onClick={goToProfile}>
            {t("home.postAd")}
            <span className="badge-free">E GRATIS!</span>
          </button>
          <button className="btn btn--see" onClick={scrollToServices}>
            {t("home.seeAds")}
          </button>
        </div>

        {/* Statistics Bar */}
      </div>

      <div className="stats-update">
        <div className="stats-bar">
          <p>👥 {stats.usersCount}+ utilizatori</p>
          <p>📌 {stats.activeTasks}+ sarcini în curs</p>
          <p>✅ {stats.completedTasks}+ sarcini finalizate</p>
          <p>👀 {stats.views}+ vizite</p>
        </div>
        <p className="actualized">
          📅 Data actualizare:{" "}
          {stats.lastUpdated && stats.lastUpdated.seconds
            ? new Date(stats.lastUpdated.seconds * 1000).toLocaleString(
                "ro-RO",
                {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )
            : "N/A"}
        </p>
      </div>
      <div className="right-side-home">
        <img
          className="image-right"
          src="https://i.imgur.com/quXjDZS.jpeg"
          loading="lazy"
          alt="aidly.ro platforma online pentru task-uri rapide"
        />
        <div className="image-overlay"></div>
      </div>
      {/* Render the same LoginModal */}
      <LoginModal isOpen={isLoginModalOpen} onClose={handleCloseLoginModal} />
    </div>
  );
};

export default Home;
