import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../data/firebase";
import VerifiedBadge from "../assets/verified.png";
import "../styling/userProfile.css";
import DefaultImage from "../assets/defaultAvatar.svg";

const UserProfile = () => {
  const { uid } = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const [userAds, setUserAds] = useState([]);
  const [finalizedAds, setFinalizedAds] = useState([]); // Anunțuri finalizate
  const [averageRating, setAverageRating] = useState(0);
  const [taskerVerified, setTaskerVerified] = useState(false);
  const [verificationLevel, setVerificationLevel] = useState(null);

  const formatName = (fullName) => {
    if (!fullName) return "Anonim"; // Dacă nu există nume, returnăm "Anonim"

    const nameParts = fullName.trim().split(" ");
    if (nameParts.length > 1) {
      return `${nameParts[0]} ${nameParts[1][0]}.`; // Afișează "Prenume N."
    }
    return nameParts[0]; // Dacă e un singur nume, îl returnăm așa
  };

  const fetchUserInfo = async () => {
    if (!uid) {
      console.warn("UID is missing. Cannot fetch user info.");
      return;
    }

    try {
      const userRef = doc(firestore, "users", uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        setUserInfo({
          ...userData,
          displayName: formatName(userData.displayName),
          verifiedUser: userData.verifiedUser === true, // add this
        });
      } else {
        console.warn("User document not found.");
      }
    } catch (error) {
      console.error("Error fetching user info:", error);
    }
  };

const checkIfVerifiedTasker = async () => {
  try {
    let verified1 = false;
    let verified2 = false;
    let verified3 = false;

    // 🔍 1. Verifică în colecția "taskers"
    const taskersRef = collection(firestore, "taskers");
    const taskerQuery = query(taskersRef, where("uid", "==", uid));
    const taskerSnap = await getDocs(taskerQuery);

    if (!taskerSnap.empty) {
      const data = taskerSnap.docs[0].data();
      verified1 = data.verified1 || verified1;
      verified2 = data.verified2 || verified2;
      verified3 = data.verified3 || verified3;
    }

    // 🔍 2. Verifică în colecția "users"
    const userRef = doc(firestore, "users", uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();
      verified1 = userData.verified1 || verified1;
      verified2 = userData.verified2 || verified2;
      verified3 = userData.verified3 || verified3;
    }

    // ✅ Alege nivelul cel mai mare
    if (verified3) {
      setVerificationLevel(3);
    } else if (verified2) {
      setVerificationLevel(2);
    } else if (verified1) {
      setVerificationLevel(1);
    }
  } catch (error) {
    console.error("Eroare la verificarea nivelului de verificare:", error);
  }
};


  const fetchUserAds = async () => {
    if (!uid) {
      console.warn("UID is missing. Cannot fetch user ads.");
      return;
    }

    try {
      const adsQuery = query(
        collection(firestore, "ads"),
        where("uid", "==", uid)
      );
      const adsSnapshot = await getDocs(adsQuery);

      if (!adsSnapshot.empty) {
        const ads = adsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Separate finalized ads
        const finalized = ads.filter((ad) => ad.status === "finalized");
        const otherAds = ads.filter((ad) => ad.status !== "finalized");

        setUserAds(otherAds);
        setFinalizedAds(finalized);
      } else {
        console.warn("No ads found for this user.");
      }
    } catch (error) {
      console.error("Error fetching user ads:", error);
    }
  };

  useEffect(() => {
    if (uid) {
      fetchUserInfo();
      fetchUserAds();
      calculateAverageRating();
      checkIfVerifiedTasker();
    }
    // eslint-disable-next-line
  }, [uid]);

  // Calculate average rating
  const calculateAverageRating = async () => {
    try {
      const reviewsQuery = query(
        collection(firestore, "reviews"),
        where("forUid", "==", uid)
      );
      const reviewsSnapshot = await getDocs(reviewsQuery);

      if (!reviewsSnapshot.empty) {
        const ratings = reviewsSnapshot.docs.map(
          (doc) => doc.data().rating || 0
        );
        const avg =
          ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length;
        setAverageRating(avg.toFixed(1));
      } else {
        console.warn("No reviews found for this user.");
        setAverageRating(5); // Default to 5 stars if no reviews
      }
    } catch (error) {
      console.error("Eroare la calcularea rating-ului utilizatorului:", error);
      setAverageRating(5); // Default to 5 in case of error as well
    }
  };

  useEffect(() => {
    if (uid) {
      fetchUserInfo();
      fetchUserAds();
      calculateAverageRating();
    } else {
      console.warn("UID is missing, cannot fetch data.");
    }
    // eslint-disable-next-line
  }, [uid]);

  // Generate mailto link for reporting
  const reportUserLink = `mailto:contact@aidly.ro?subject=Raportează Utilizator (UID: ${uid})&body=${encodeURIComponent(
    `Bună ziua,

Doresc să raportez utilizatorul cu numele: "${
      userInfo?.displayName || "Anonim"
    }" și UID-ul: "${uid}" pentru următoarele motive:

(Descrieți motivul aici).`
  )}`;

  const highResPhotoUrl = userInfo?.photoURL
    ? userInfo.photoURL.replace(/=s96-c$/, "=s400-c") // Schimbă dimensiunea la 400x400 px
    : DefaultImage; // Fallback la o imagine implicită

  const StarRating = ({ rating }) => (
    <div className="user-rating">
      <strong>Recenzii ({rating}/5): </strong>
      <div className="stars-rating">
        {Array.from({ length: 5 }, (_, i) => (
          <span
            key={i}
            className={i < Math.round(rating) ? "star filled" : "star empty"}>
            ★
          </span>
        ))}
      </div>
    </div>
  );

  return (
    <div className="user-profile-page">
      {/* User Info Section */}
      <div className="user-info">
        {userInfo ? (
          <>
            <div className="avatar-container-userprofile">
              <img
                src={highResPhotoUrl}
                alt={`${userInfo.displayName || "Anonim"}'s avatar`}
                className="user-avatar"
                onError={(e) => (e.target.src = DefaultImage)}
              />

              {userInfo.verifiedUser && (
                <span className="badge-verified-userprofile">
                  <img
                    src={VerifiedBadge}
                    alt="Utilizator verificat"
                    title="Utilizator verificat"
                  />
                </span>
              )}
            </div>

            <div className="info-name-userprofile">
              {verificationLevel === 1 && (
                <div className="badge-verificare badge-nivel1">
                  ✅ Verificat - Nivel 1 Securitate
                </div>
              )}
              {verificationLevel === 2 && (
                <div className="badge-verificare badge-nivel2">
                  🛡️ Verificat - Nivel 2 Identitate
                </div>
              )}
              {verificationLevel === 3 && (
                <div className="badge-verificare badge-nivel3">
                  🔒 Verificat - Nivel 3 Documente
                </div>
              )}

              <h2 className="user-name">{userInfo.displayName || "Anonim"}</h2>

              <StarRating rating={averageRating} />

              <a href={reportUserLink} className="report-user-btn">
                Raportează Utilizator
              </a>
            </div>
          </>
        ) : (
          <p>Se încarcă informațiile utilizatorului...</p>
        )}
      </div>

      {/* User Ads Section */}
      <div className="user-ads">
        <h3>Anunțurile utilizatorului</h3>
        {userAds.length > 0 ? (
          <div className="ads-list-userprofile">
            {userAds.map((ad) => (
              <div key={ad.id} className="ad-card-userprofile">
                <h4 className="ad-title-userprofile">
                  {ad.title.charAt(0).toUpperCase() +
                    ad.title.slice(1).toLowerCase()}
                </h4>

                <p className="ad-price-userprofile">
                  <strong>Buget:</strong> {ad.price} RON
                </p>
                <p className="ad-category-userprofile">
                  <strong>Categorie:</strong> {ad.category}
                </p>
                <p className="ad-description-userprofile">{ad.description}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>Nu există anunțuri active postate de acest utilizator.</p>
        )}
      </div>

      {/* Finalized Ads Section */}
      <div className="user-finalized-ads">
        <h3>Anunțurile Finalizate</h3>
        {finalizedAds.length > 0 ? (
          <div className="ads-list-finalized">
            {finalizedAds.map((ad) => (
              <div key={ad.id} className="ad-card-userprofile finalized-ad">
                <h4 className="ad-title-userprofile">{ad.title}</h4>
                <p className="ad-price-userprofile">
                  <strong>Buget:</strong> {ad.price} RON
                </p>
                <p className="ad-category-userprofile">
                  <strong>Categorie:</strong> {ad.category}
                </p>
                <p className="ad-description-userprofile">{ad.description}</p>
                <span className="ad-badge-userprofile">Finalizat</span>
              </div>
            ))}
          </div>
        ) : (
          <p>Nu există anunțuri finalizate postate de acest utilizator.</p>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
