import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import "../styling/BusinessDashboard.css";
import UpdateInfoModal from "./UpdateInfoModal";

// import ImageUploadComponent from "./ImageUploadComponent";
import PostAnnouncementModal from "./PostAnnouncementModal";
import EditAnnouncementModal from "./EditAnnouncementModal";
import categoriesData from "../data/categories.json";

// Subcomponent pentru afișarea detaliilor participanților
const ParticipantsDetails = ({ participants, ownerUid, db }) => {
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      // Filtrăm pentru uid-urile care nu sunt proprietarul (ownerUid)
      const filteredUids = participants.filter((uid) => uid !== ownerUid);
      const promises = filteredUids.map(async (uid) => {
        const userDoc = await getDoc(doc(db, "users", uid));
        return userDoc.exists() ? { uid, ...userDoc.data() } : null;
      });
      const results = await Promise.all(promises);
      setUsersData(results.filter((u) => u !== null));
    };

    if (participants && participants.length > 0) {
      fetchUsers();
    }
  }, [participants, ownerUid, db]);

  return (
    <div className="ad-contact-details">
      <h4>Detalii Taskeri</h4>
      {usersData.length > 0 ? (
        usersData.map((user) => (
          <div key={user.uid} className="tasker-contact">
            <p>
              <strong>Nume:</strong> {user.displayName || "Nedefinit"}
            </p>
            <p>
              <strong>Telefon:</strong> {user.phoneNumber || "Nedefinit"}
            </p>
            <p>
              <strong>Email:</strong> {user.email || "Nedefinit"}
            </p>
          </div>
        ))
      ) : (
        <p>Nu există detalii de contact pentru taskeri.</p>
      )}
    </div>
  );
};

const BusinessDashboard = ({ uid }) => {
  const navigate = useNavigate();
  const db = getFirestore();

  // States pentru business și anunțuri
  const [businessData, setBusinessData] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  // Pentru rating la review:
  const [hoverRating, setHoverRating] = useState(0);

  // States pentru anunțurile în curs și logica modalelor
  const [inProgressAds, setInProgressAds] = useState([]);
  const [currentAd, setCurrentAd] = useState(null);
  const [isDoneModalOpen, setIsDoneModalOpen] = useState(false);
  const [isUndoneModalOpen, setIsUndoneModalOpen] = useState(false);
  const [reviewData, setReviewData] = useState({ rating: 0, review: "" });
  const [undoneReason, setUndoneReason] = useState("");
  // Noua stare pentru a afișa modalul de re-listare
  const [showRelistModal, setShowRelistModal] = useState(false);

  // Preluare date business
  useEffect(() => {
    const businessRef = collection(db, "business_users");
    const q = query(businessRef, where("userId", "==", uid));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (!snapshot.empty) {
        const data = snapshot.docs[0].data();
        setBusinessData(data);
      }
    });
    return () => unsubscribe();
  }, [db, uid]);

  // Preluare anunțuri pentru business
  useEffect(() => {
    const announcementsRef = collection(db, "announcements");
    const q = query(
      announcementsRef,
      where("businessId", "==", uid),
      where("status", "==", "posted")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const annList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAnnouncements(annList);
    });
    return () => unsubscribe();
  }, [db, uid]);

  // Preluare anunțuri în curs din colecția "announcements"
  // Se preiau documentele care au status "inProgress" sau "finalized" și participă owner-ul
  useEffect(() => {
    if (!uid) return;
    const announcementsRef = collection(db, "announcements");
    const q = query(
      announcementsRef,
      where("status", "in", ["inProgress", "finalized"]),
      where("participants", "array-contains", uid)
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const list = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setInProgressAds(list);
    });
    return () => unsubscribe();
  }, [db, uid]);

  const handleUpdateInfo = () => {
    setShowUpdateModal(true);
  };

  const handlePostAnnouncement = () => {
    setShowPostModal(true);
  };

  const handleEditAnnouncement = (announcement) => {
    setSelectedAnnouncement(announcement);
    setShowEditModal(true);
  };

  const handleDeleteAnnouncement = async (announcementId) => {
    if (!window.confirm("Sunteți sigur că doriți să ștergeți acest anunț?")) {
      return;
    }
    try {
      // Actualizează documentul din colecția "announcements" cu status: "deleted"
      await updateDoc(doc(db, "announcements", announcementId), {
        status: "deleted",
      });
      alert("Anunțul a fost marcat ca șters.");
    } catch (error) {
      console.error("Error deleting announcement:", error);
    }
  };

  // Funcție pentru marcarea unui ad ca finalizat (actualizează documentul din announcements)
  const handleMarkAsCompleted = async (ad) => {
    try {
      const adRef = doc(db, "announcements", ad.id);
      await updateDoc(adRef, { ownerCompleted: true, taskerCompleted: true });
      const updatedAdSnap = await getDoc(adRef);
      const updatedAd = updatedAdSnap.data();

      setCurrentAd(ad);
      setReviewData({ rating: 0, review: "" });
      setIsDoneModalOpen(true);

      if (updatedAd.ownerCompleted && updatedAd.taskerCompleted) {
        await updateDoc(adRef, { status: "finalized" });
        alert("Task-ul a fost finalizat complet!");
      } else {
        alert("Task-ul a fost marcat ca finalizat.");
      }
    } catch (error) {
      console.error("Eroare la marcarea ca finalizat:", error);
    }
  };

  // Funcție pentru trimiterea recenziei după finalizare
  const handleSubmitReview = async () => {
    alert("Recenzie trimisă: " + JSON.stringify(reviewData));
    setIsDoneModalOpen(false);
  };

  // Funcție pentru deschiderea modalului de task neterminat
  const handleOpenUndoneModal = (ad) => {
    setCurrentAd(ad);
    setUndoneReason("");
    setIsUndoneModalOpen(true);
  };

  // Funcție care deschide modalul de re-listare, după ce se primește motivul "undone"
  const handleSubmitUndone = async () => {
    // În loc să actualizăm imediat status-ul,
    // deschidem un nou modal care întreabă proprietarul:
    setIsUndoneModalOpen(false);
    setShowRelistModal(true);
  };

  // Funcția care se apelează atunci când proprietarul răspunde la întrebarea din modalul de re-listare
  const handleRelistResponse = async (relist) => {
    try {
      const adRef = doc(db, "announcements", currentAd.id);
      if (relist) {
        await updateDoc(adRef, { status: "posted" });
        alert("Ad-ul a fost re-listat (posted).");
      } else {
        await updateDoc(adRef, { status: "deleted" });
        alert("Ad-ul a fost șters (deleted).");
      }
    } catch (error) {
      console.error("Eroare la actualizarea status-ului:", error);
    } finally {
      setShowRelistModal(false);
    }
  };

  // ✅ Update business info when images are uploaded
  const updateBusinessImage = (type, newURL) => {
    setBusinessData((prev) => ({ ...prev, [type]: newURL }));
  };

  return (
    <div className="dashboard-container-business">
      <header className="dashboard-header">
        {/* ✅ Image Upload Components */}
        {/* <div className="upload-sections">
          <ImageUploadComponent
            uid={uid}
            imageType="coverURL"
            currentImage={businessData?.coverURL}
            onUpdate={(newURL) => updateBusinessImage("coverURL", newURL)}
          />
          <ImageUploadComponent
            uid={uid}
            imageType="logoURL"
            currentImage={businessData?.logoURL}
            onUpdate={(newURL) => updateBusinessImage("logoURL", newURL)}
          />
        </div> */}
        <div className="business-info">
          <h1>
            {businessData ? businessData.companyName : "Dashboard Business"}
          </h1>
          {businessData && (
            <div className="info-details">
              <p>{businessData.headquarters}</p>
              <p>Registrul Comerțului: {businessData.registrationNumber}</p>
            </div>
          )}
        </div>
        <div className="header-buttons">
          <button className="update-info-button" onClick={handleUpdateInfo}>
            Actualizează informațiile
          </button>
        </div>
        <button
          className="post-announcement-button"
          onClick={handlePostAnnouncement}>
          Postează un anunț
        </button>
      </header>

      {/* Secțiunea de anunțuri */}
      {announcements.length > 0 && (
        <section className="announcements-section">
          {announcements.length > 0 ? (
            announcements.map((announcement) => {
              const categoryData = categoriesData.categories.find(
                (cat) => cat.name === announcement.category
              );
              return (
                <div
                  key={announcement.id}
                  className="announcement-card-business"
                  style={{
                    backgroundImage: categoryData
                      ? `url(${categoryData.image})`
                      : "none",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                  }}>
                  <div className="card-overlay-business"></div>
                  <div className="card-content-business">
                    <h3>{announcement.title}</h3>
                    <p>{announcement.description}</p>
                    <p>
                      <strong>Buget:</strong> {announcement.budget}{" "}
                      {announcement.budgetType}
                    </p>
                    <p>
                      <strong>Nivel de experiență:</strong>{" "}
                      {announcement.experienceLevel}
                    </p>
                    <p>
                      <strong>Durata sarcinii:</strong>{" "}
                      {announcement.taskDuration}
                    </p>
                    <p>
                      <strong>Când este nevoie:</strong>{" "}
                      {announcement.neededDate}
                    </p>
                    <p>
                      <strong>Număr de persoane:</strong>{" "}
                      {announcement.numberOfPeople}
                    </p>
                    <p>
                      <strong>Interval de vârstă:</strong>{" "}
                      {announcement.ageRange}
                    </p>
                    <p>
                      <strong>Urgent:</strong>{" "}
                      {announcement.isUrgent ? "Da" : "Nu"}
                    </p>
                    <p>
                      <strong>CV necesar:</strong>{" "}
                      {announcement.cvRequired ? "Da" : "Nu"}
                    </p>
                    <div className="announcement-actions">
                      <button
                        onClick={() => handleEditAnnouncement(announcement)}
                        className="edit-button">
                        Editează
                      </button>
                      <button
                        onClick={() =>
                          handleDeleteAnnouncement(announcement.id)
                        }
                        className="delete-button">
                        Șterge
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="no-announcements">Nu există anunțuri încă.</p>
          )}
        </section>
      )}
      {inProgressAds.length > 0 && (
        <section className="in-progress-ads-section">
          <h2>Anunțuri în Curs</h2>
          {inProgressAds.map((ad) => (
            <div className="in-progress-ad-card" key={ad.id}>
              <div className="ad-details">
                <h3>{ad.title}</h3>
                <p>
                  <strong>Buget:</strong> {ad.budget} {ad.budgetType}
                </p>
                <p>
                  <strong>Categorie:</strong> {ad.category}
                </p>
              </div>
              <div className="ad-actions">
                {ad.status === "inProgress" && (
                  <>
                    <button onClick={() => handleMarkAsCompleted(ad)}>
                      Finalizat
                    </button>
                    <button onClick={() => handleOpenUndoneModal(ad)}>
                      Task neterminat
                    </button>
                  </>
                )}
                {ad.status === "finalized" && (
                  <div className="ad-finalized-actions">
                    <button disabled>Task Finalizat</button>
                    <button onClick={() => setIsDoneModalOpen(true)}>
                      Lasă Review
                    </button>
                  </div>
                )}
              </div>

              {ad.participants && ad.participants.length > 0 && (
                <ParticipantsDetails
                  participants={ad.participants}
                  ownerUid={ad.ownerUid}
                  db={db}
                />
              )}
            </div>
          ))}
        </section>
      )}

      {/* Modal pentru trimiterea review-ului după finalizare */}
      {isDoneModalOpen && (
        <>
          <div
            className="review-modal-overlay"
            onClick={() => setIsDoneModalOpen(false)}></div>
          <div className="review-modal-container">
            <h2>Review pentru finalizare</h2>
            <div className="review-modal-rating">
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  className={`star ${
                    star <= (hoverRating || reviewData.rating) ? "filled" : ""
                  }`}
                  onMouseEnter={() => setHoverRating(star)}
                  onMouseLeave={() => setHoverRating(0)}
                  onClick={() =>
                    setReviewData((prev) => ({ ...prev, rating: star }))
                  }>
                  &#9733;
                </span>
              ))}
            </div>
            <textarea
              className="review-modal-textarea"
              placeholder="Lasă un review..."
              value={reviewData.review}
              onChange={(e) =>
                setReviewData((prev) => ({ ...prev, review: e.target.value }))
              }></textarea>
            <div className="review-modal-buttons">
              <button
                className="review-modal-button"
                onClick={handleSubmitReview}>
                Trimite Review
              </button>
              <button
                className="review-modal-button"
                onClick={() => setIsDoneModalOpen(false)}>
                Închide
              </button>
            </div>
          </div>
        </>
      )}

      {/* Modal pentru task neterminat */}
      {isUndoneModalOpen && (
        <>
          <div
            className="undone-modal-overlay"
            onClick={() => setIsUndoneModalOpen(false)}></div>
          <div className="undone-modal-container">
            <h2>Task Nefinalizat</h2>
            <textarea
              className="undone-modal-textarea"
              placeholder="Explică motivul..."
              value={undoneReason}
              onChange={(e) => setUndoneReason(e.target.value)}></textarea>
            <div className="undone-modal-buttons">
              <button
                className="undone-modal-button"
                onClick={handleSubmitUndone}>
                Trimite Motiv
              </button>
              <button
                className="undone-modal-button"
                onClick={() => setIsUndoneModalOpen(false)}>
                Închide
              </button>
            </div>
          </div>
        </>
      )}

      {/* Modal de re-listare */}
      {showRelistModal && (
        <>
          <div
            className="relist-modal-overlay"
            onClick={() => setShowRelistModal(false)}></div>
          <div className="relist-modal-container">
            <h2>Re-listare Anunț</h2>
            <p>Vrei să re-listezi acest anunț?</p>
            <div className="relist-modal-buttons">
              <button
                className="relist-modal-button yes"
                onClick={() => handleRelistResponse(true)}>
                Da, re-listează
              </button>
              <button
                className="relist-modal-button no"
                onClick={() => handleRelistResponse(false)}>
                Nu, șterge
              </button>
            </div>
          </div>
        </>
      )}

      {showUpdateModal && businessData && (
        <UpdateInfoModal
          businessData={businessData}
          uid={uid}
          db={db}
          onClose={() => setShowUpdateModal(false)}
        />
      )}

      {showPostModal && (
        <PostAnnouncementModal
          uid={uid}
          db={db}
          onClose={() => setShowPostModal(false)}
        />
      )}

      {showEditModal && selectedAnnouncement && (
        <EditAnnouncementModal
          announcement={selectedAnnouncement}
          db={db}
          onClose={() => {
            setShowEditModal(false);
            setSelectedAnnouncement(null);
          }}
        />
      )}
    </div>
  );
};

export default BusinessDashboard;
